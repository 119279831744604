export const tenantTypes = {
  module: 'tenant',
  getters: {
    get getTenant() {
      return `${tenantTypes.module}/getTenant`;
    },
    get getMetaParam() {
      return `${tenantTypes.module}/getMetaParam`;
    }
  },
  actions: {
    getTenant: 'GET_TENANT',
    getMetaParam: 'GET_META_PARAM',
    setMetaParam: 'SET_META_PARAM',
    setMailingSyncCredentials: 'SET_MAILING_SYNC_CREDENTIALS',
    setMailingSyncCredential: 'SET_MAILING_SYNC_CREDENTIAL'
  },
  dispatchers: {
    get setMailingSyncCredential() {
      return `${tenantTypes.module}/${tenantTypes.actions.setMailingSyncCredential}`;
    },
    get setMailingSyncCredentials() {
      return `${tenantTypes.module}/${tenantTypes.actions.setMailingSyncCredentials}`;
    },
    get getTenant() {
      return `${tenantTypes.module}/${tenantTypes.actions.getTenant}`;
    },
    get getMetaParam() {
      return `${tenantTypes.module}/${tenantTypes.actions.getMetaParam}`;
    },
    get setMetaParam() {
      return `${tenantTypes.module}/${tenantTypes.actions.setMetaParam}`;
    }
  }
};
