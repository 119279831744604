export const getDefaultState = () => {
  return {
    campaignDetails: {},
    tenantSettings: {
      vat: {
        label: 'VAT: Product Value Added Tax (%)',
        type: 'number',
        value: 0
      },
      currency: {
        label: 'Currency',
        type: 'string',
        value: '$'
      }
    }
  };
};
