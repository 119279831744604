export const recommendationAnalyticsTypes = {
  module: 'recommendationAnalytics',
  getters: {
    get getBatches() {
      return `${recommendationAnalyticsTypes.module}/getBatches`;
    },
    get getRecommendationId() {
      return `${recommendationAnalyticsTypes.module}/getRecommendationId`;
    },
    get getRecommendationCoverage() {
      return `${recommendationAnalyticsTypes.module}/getRecommendationCoverage`;
    },
    get getBestSellingProducts() {
      return `${recommendationAnalyticsTypes.module}/getBestSellingProducts`;
    },
    get getTopRecommendedProducts() {
      return `${recommendationAnalyticsTypes.module}/getTopRecommendedProducts`;
    },
    get recommendationsCategories() {
      return `${recommendationAnalyticsTypes.module}/getRecommendationsCategories`;
    }
  },
  actions: {
    getBatches: 'GET_BATCHES',
    getRecommendationId: 'GET_RECOMMENDATION_ID',
    getRecommendationCoverage: 'GET_RECOMMENDATION_COVERAGE',
    getBestSellingProducts: 'GET_BEST_SELLING_PRODUCTS',
    getTopRecommendedProducts: 'GET_TOP_RECOMMENDED_PRODUCTS',
    getRecommendationsCategories: 'GET_RECOMMENDATIONS_CATEGORIES',

    setRecommendationId: 'SET_RECOMMENDATION_ID'
  },
  dispatchers: {
    get getBatches() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getBatches}`;
    },
    get getRecommendationId() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getRecommendationId}`;
    },
    get getRecommendationCoverage() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getRecommendationCoverage}`;
    },
    get getBestSellingProducts() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getBestSellingProducts}`;
    },
    get getTopRecommendedProducts() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getTopRecommendedProducts}`;
    },
    get getRecommendationsCategories() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.getRecommendationsCategories}`;
    },
    get setRecommendationId() {
      return `${recommendationAnalyticsTypes.module}/${recommendationAnalyticsTypes.actions.setRecommendationId}`;
    }
  }
};
