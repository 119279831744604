import { getDefaultState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';

export const products = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getProducts: state => state.products,
    getSold: state => state.sold,
    getCount: state => state.count,
    getFields: state => state.fields,
    getFilters: state => state.filters,
    getSortSelectOptions: state => state.sortSelectOptions,
    getSearch: state => state.search,
    getSearchByField: state => state.searchByField,
    getSortSelectOptionId: state => state.sortSelectOptionId,
    getPageCursor: state => state.pageCursor
  },
  actions,
  mutations
};
