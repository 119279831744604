import Vue from 'vue';

import { recommendationAnalyticsTypes } from '../../constants/types/recommendationAnalytics';

const {
  getBatches,
  getRecommendationCoverage,
  getBestSellingProducts,
  getTopRecommendedProducts,
  getRecommendationsCategories,
  setRecommendationId
} = recommendationAnalyticsTypes.actions;

export const mutations = {
  [getBatches]: (state, { response }) => {
    let batches = response.data.value;

    if (batches) {
      Vue.set(state, 'recommendationId', batches[0].id);
      Vue.set(state, 'batches', batches);
    }
  },
  [getRecommendationCoverage]: (state, { response }) => {
    let coverage = response.data.value;

    if (coverage) {
      Vue.set(state, 'coverage', coverage);
    }
  },
  [getBestSellingProducts]: (state, { response }) => {
    const bestSellers = response.data.value;

    if (bestSellers) {
      Vue.set(state, 'bestSellers', bestSellers);
    }
  },
  [getTopRecommendedProducts]: (state, { response }) => {
    const topRecommended = response.data.value;

    if (topRecommended) {
      Vue.set(state, 'topRecommended', topRecommended);
    }
  },
  [getRecommendationsCategories]: (state, { response }) => {
    const recommendationsCategories = response.data.value;

    if (recommendationsCategories) {
      Vue.set(state, 'recommendationsCategories', recommendationsCategories);
    }
  },

  [setRecommendationId]: (state, { recommendationId }) => {
    Vue.set(state, 'recommendationId', recommendationId);
  }
};
