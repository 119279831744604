export const getDefaultState = () => {
  return {
    status: {},
    products: [],
    count: 0,
    fields: [
      { value: 'name', label: 'Name' },
      { value: 'item_id', label: 'Product ID' },
      { value: 'sku', label: 'SKU' },
      { value: 'description', label: 'Description' },
      { value: 'brand', label: 'Brand' },
      { value: 'meta_category', label: 'Meta category' },
      { value: 'category', label: 'Category' },
      { value: 'sub_category', label: 'Sub category' },
      // { value: 'image_url', label: 'Image url' },
      // { value: 'item_url', label: 'Product url' },
      // { value: 'price', label: 'Price' },
      // { value: 'cost', label: 'Cost' },
      // { value: 'tenant_created_at', label: 'Added Datetime' },
      { value: 'channel_name', label: 'Channel Name' }
      // { value: 'status', label: 'Status' },
      // { value: 'stock_qty', label: 'Stock Qty' }
    ],
    sortSelectOptions: [
      {
        id: 1,
        name: 'price',
        order: 'asc',
        label: 'Price (Low to High)'
      },
      {
        id: 2,
        name: 'price',
        order: 'desc',
        label: 'Price (High to Low)'
      },
      {
        id: 3,
        name: 'created_at',
        order: 'desc',
        label: 'Newest'
      },
      {
        id: 4,
        name: 'created_at',
        order: 'asc',
        label: 'Oldest'
      }
    ],
    filters: {
      includedFilters: [],
      excludedFilters: []
    },
    search: '',
    searchByField: 'name',
    sortSelectOptionId: '',
    pageCursor: null
  };
};
