import { tenantTypes } from '../../constants/types/tenant';
import Vue from 'vue';

const { getTenant, getMetaParam, setMetaParam } = tenantTypes.actions;

export const mutations = {
  [getTenant]: (state, { response }) => {
    const tenant = response.data.value;

    if (tenant) {
      Vue.set(state, 'tenant', tenant);
    }
  },
  [getMetaParam]: (state, { response }) => {
    const metaParam = response.data.value;

    if (metaParam) {
      Vue.set(state, 'metaParam', metaParam);
    }
  },
  [setMetaParam]: (state, { params }) => {
    Vue.set(state, 'metaParam', {
      ...state.metaParam,
      ...params
    });
  }
};
