import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const recommendation = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getFbt: state => state.fbt,
    getRecommendations: state => state.recommendations,
    getBatchTimestamps: state => state.batchTimestamps,
    getTemplates: state => state.templates
  },
  actions,
  mutations
};
