var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vh-100 vw-100 auth-purple-glow-background"},[_c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center w-100 h-100"},[_c('ValidationObserver',{staticClass:"auth-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card-login card-white p-3"},[_c('template',{slot:"header"},[_c('h1',{staticClass:"card-title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.signUpComplete ? 'Verify Your Account' : 'Create Account')+" ")])]),(!_vm.signUpComplete)?_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","type":"email","placeholder":"Email","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('PasswordInput',{attrs:{"name":"password","label":"Password","placeholder":"Password","rules":"required|min:8"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('PasswordInput',{attrs:{"name":"confirmPassword","label":"Re-enter Password","placeholder":"Confirm Password","rules":"required|confirmed:password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1):_c('div',[_c('ValidationProvider',{attrs:{"name":"verificationCode","rules":"required|min:6|max:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"verificationCode"}},[_vm._v("Verification Code")]),_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Enter 6-digit code","type":"text","error":errors[0]},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}})]}}],null,true)})],1),_c('base-button',{staticClass:"auth-submit-button",attrs:{"native-type":"submit","size":"lg","block":"","disabled":_vm.isLoading}},[(!_vm.isLoading)?_c('span',[_vm._v(_vm._s(_vm.signUpComplete ? 'Verify' : 'Sign Up'))]):_c('i',{staticClass:"fa fa-spinner fa-spin"})]),(_vm.signUpComplete)?_c('base-button',{staticClass:"resend-button mt-2",attrs:{"size":"lg","block":"","disabled":_vm.isResending},on:{"click":_vm.resendVerificationCode}},[_vm._v(" "+_vm._s(_vm.isResending ? 'Resending...' : 'Resend Verification Code')+" ")]):_vm._e(),(!_vm.signUpComplete)?_c('ExternalIdentityProvider',{attrs:{"action":'signUp'}}):_vm._e(),_c('FormFooter',{attrs:{"leftSide":'Sign In',"rightSide":'Forgot Password'}})],2)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }