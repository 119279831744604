/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const settingsTypes = {
  module: 'settings',
  getters: {
    get getCampaignDetails() {
      return `${settingsTypes.module}/getCampaignDetails`;
    },
    get getTenantSettings() {
      return `${settingsTypes.module}/getTenantSettings`;
    }
  },
  actions: {
    setSettings: 'setSettings', // TODO: rename as default-campaign-settings
    updateCampaignDetails: 'updateCampaignDetails',
    getTenantSettings: 'GET_TENANT_SETTINGS',
    setTenantSettings: 'SET_TENANT_SETTINGS'
  },
  dispatchers: {
    get setSettings() {
      // TODO: rename as default-campaign-settings
      return `${settingsTypes.module}/${settingsTypes.actions.setSettings}`;
    },
    get updateCampaignDetails() {
      return `${settingsTypes.module}/${settingsTypes.actions.updateCampaignDetails}`;
    },
    // web/src/store/settings/actions.js:20
    get getTenantSettings() {
      return `${settingsTypes.module}/${settingsTypes.actions.getTenantSettings}`;
    },
    // web/src/store/settings/actions.js:32
    get setTenantSettings() {
      return `${settingsTypes.module}/${settingsTypes.actions.setTenantSettings}`;
    }
  }
};
