import Vue from 'vue';
import { recommendationTypes } from '../../constants/types';

import moment from 'moment';

const { setRecommendations, getBatchTimeStamps, setFbt, addTemplate, flushTemplates } = recommendationTypes.actions;

export const mutations = {
  [setFbt]: (state, { response }) => {
    state.fbt = response.data.value;
  },
  [setRecommendations]: (state, { response }) => {
    let recommendations = response.data.value;
    if (recommendations) {
      Vue.set(state, 'recommendations', recommendations);
    }
  },
  [getBatchTimeStamps]: (state, { response }) => {
    let dts = response.data.value;

    const formattedDts = dts.map(({ dt, id }) => {
      return {
        label: moment(dt).format('DD/MM/Y'),
        value: id
      };
    });

    if (formattedDts) {
      Vue.set(state, 'batchTimestamps', [{ id: 'latest', value: 'latest' }, ...formattedDts]);
    }
  },
  [addTemplate]: (state, { index, product }) => {
    Vue.set(state.templates, index, product);
  },
  [flushTemplates]: state => {
    Vue.set(state, 'templates', []);
  }
};
