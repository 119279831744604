/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (customerAnalyticsTypes.dispatchers.getCustomer, ...)
 */

export const customerAnalyticsTypes = {
  module: 'customerAnalytics',
  getters: {
    // Get a single customer
    get getCustomer() {
      return `${customerAnalyticsTypes.module}/getCustomer`;
    },
    get getAvgBasketPrice() {
      return `${customerAnalyticsTypes.module}/getAvgBasketPrice`;
    },
    get getAvgBasketPriceMonth() {
      return `${customerAnalyticsTypes.module}/getAvgBasketPriceMonth`;
    },
    get getVisits() {
      return `${customerAnalyticsTypes.module}/getVisits`;
    },
    get getYearSpending() {
      return `${customerAnalyticsTypes.module}/getYearSpending`;
    },
    get getFavShops() {
      return `${customerAnalyticsTypes.module}/getFavShops`;
    },
    get getCsp() {
      return `${customerAnalyticsTypes.module}/getCsp`;
    },
    get getEngagement() {
      return `${customerAnalyticsTypes.module}/getEngagement`;
    },
    get getSegments() {
      return `${customerAnalyticsTypes.module}/getSegments`;
    },
    get getTimeline() {
      return `${customerAnalyticsTypes.module}/getTimeline`;
    },
    get getRecommendations() {
      return `${customerAnalyticsTypes.module}/getRecommendations`;
    },

    get getFavShopsName() {
      return `${customerAnalyticsTypes.module}/getFavShopsName`;
    },
    get getCustomerDayVisits() {
      return `${customerAnalyticsTypes.module}/getCustomerDayVisits`;
    }
  },
  actions: {
    setTenantCustomer: 'SET_TENANT_CUSTOMER',
    setAvgBasketPrice: 'SET_AVERAGE_BASKET_PRICE',
    setAvgBasketPriceMonth: 'SET_AVERAGE_BASKET_PRICE_MONTH',
    setVisits: 'SET_VISITS',
    setYearSpending: 'SET_YEAR_SPENDING',
    setFavShops: 'SET_FAV_SHOPS',
    setCsp: 'SET_CSP',
    setSegments: 'SET_SEGMENTS',
    setEngagement: 'SET_ENGAGEMENT',
    setTimeline: 'SET_TIMELINE',
    setCustomerDayVisits: 'SET_CUSTOMER_DAY_VISITS',
    setRecommendations: 'SET_RECOMMENDATIONS',
    updateTenantCustomer: 'UPDATE_TENANT_CUSTOMER'
  },
  dispatchers: {
    get setRecommendations() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setRecommendations}`;
    },
    get setCustomerDayVisits() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setCustomerDayVisits}`;
    },
    get setTenantCustomer() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setTenantCustomer}`;
    },
    get setAvgBasketPrice() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setAvgBasketPrice}`;
    },
    get setAvgBasketPriceMonth() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setAvgBasketPriceMonth}`;
    },
    get setVisits() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setVisits}`;
    },
    get setYearSpending() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setYearSpending}`;
    },
    get setFavShops() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setFavShops}`;
    },
    get setCsp() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setCsp}`;
    },
    get setEngagement() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setEngagement}`;
    },
    get setTimeline() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setTimeline}`;
    },
    get setSegments() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.setSegments}`;
    },
    get updateTenantCustomer() {
      return `${customerAnalyticsTypes.module}/${customerAnalyticsTypes.actions.updateTenantCustomer}`;
    }
  }
};
