import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const filter = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getSqlWithHeaders: state => state.sql_query_with_parent,
    getSqlQuery: state => state.sql_query,
    getFilters: state => state.filters,
    getGroups: state => state.groups,
    getFields: state => groupId => state.groups[groupId].fields,
    getConditions: state => () => state.conditions,
    getCurrentlyEditing: state => state.currentlyEditing,
    getCurrentGroup: state => state.currentGroup,
    getCurrentTable: state => state.currentTable,
    getStatus: state => objectName => state.status[objectName].isLoading,
    getSelects: state => state.selects,
    getFilterInfo: state => {
      return {
        name: state.name,
        description: state.description
      };
    },
    getCount: state => state.count,
    getBestSellersWinning: state => state.bestSellersWinning,
    getBestSellersLosing: state => state.bestSellersLosing,
    getFrequencyCheck: state => state.frequencyCheck,
    getRecencyCheck: state => state.recencyCheck,
    getFiltersOperator: state => state.operator,
    getTempValue: state => state.tempValue
  },
  actions,
  mutations
};
