export const filterTypes = {
  module: 'filter',
  getters: {
    get getSqlQuery() {
      return `${filterTypes.module}/getSqlQuery`;
    },
    get getSqlWithHeaders() {
      return `${filterTypes.module}/getSqlWithHeaders`;
    },
    get getTempValue() {
      return `${filterTypes.module}/getTempValue`;
    },
    get getFrequencyCheck() {
      return `${filterTypes.module}/getFrequencyCheck`;
    },
    get getRecencyCheck() {
      return `${filterTypes.module}/getRecencyCheck`;
    },
    get getFilterOperator() {
      return `${filterTypes.module}/getFilterOperator`;
    },
    get getGroups() {
      return `${filterTypes.module}/getGroups`;
    },
    get getFields() {
      return `${filterTypes.module}/getFields`;
    },
    get getConditions() {
      return `${filterTypes.module}/getConditions`;
    },
    get getIsEditing() {
      return `${filterTypes.module}/getIsEditing`;
    },
    get getCurrentlyEditing() {
      return `${filterTypes.module}/getCurrentlyEditing`;
    },
    get getFilters() {
      return `${filterTypes.module}/getFilters`;
    },
    get getStatus() {
      return `${filterTypes.module}/getStatus`;
    },
    get getSelects() {
      return `${filterTypes.module}/getSelects`;
    },
    get getCurrentGroup() {
      return `${filterTypes.module}/getCurrentGroup`;
    },
    get getCurrentTable() {
      return `${filterTypes.module}/getCurrentTable`;
    },
    get getGeneralInformation() {
      return `${filterTypes.module}/getGeneralInformation`;
    },
    get getFilterName() {
      return `${filterTypes.module}/getFilterName`;
    },
    get getDescription() {
      return `${filterTypes.module}/getDescription`;
    },
    get getFilterInfo() {
      return `${filterTypes.module}/getFilterInfo`;
    },
    get getCount() {
      return `${filterTypes.module}/getCount`;
    },
    get getBestSellersWinning() {
      return `${filterTypes.module}/getBestSellersWinning`;
    },
    get getBestSellersLosing() {
      return `${filterTypes.module}/getBestSellersLosing`;
    }
  },
  actions: {
    setGroup: 'SET_GROUP',
    unsetGroup: 'UNSET_GROUP',
    setGroupOperator: 'SET_GROUP_OPERATOR',
    setField: 'SET_FIELD',
    unsetField: 'UNSET_FIELD',
    setFilterOperator: 'SET_FILTER_OPERATOR',
    setFilter: 'SET_FILTER',
    setNegation: 'SET_NEGATION',
    setGroupEditMode: 'SET_GROUP_EDIT_MODE',
    setGroupConditions: 'SET_GROUP_CONDITIONS',
    setCurrentlyEditing: 'TOGGLE_IS_EDITING',
    resetEditing: 'RESET_EDITING',
    loadFilter: 'LOAD_FILTER',
    populateFields: 'POPULATE_FIELDS',
    getFilters: 'GET_FILTERS',
    // deleteFilter: 'DELETE_FILTER',
    flushFilter: 'FLUSH_FILTER',
    getCampaignFilters: 'GET_CAMPAIGN_FILTERS',
    populateValues: 'POPULATE_VALUES',
    populateOtherFields: 'POPULATE_OTHER_FIELDS',
    setParams: 'SET_PARAMS',
    setMonth: 'SET_MONTH',
    setProductsCount: 'SET_CUSTOMERS_COUNT',
    setBestSellersWinning: 'SET_BEST_SELLERS_WINNING',
    setBestSellersLosing: 'SET_BEST_SELLERS_LOSING',
    setFrequencyCheck: 'SET_FREQUENCY_CHECK',
    setRecencyCheck: 'SET_RECENCY_CHECK',
    loadFormattedFilter: 'LOAD_FORMATTED_FILTER',
    setFilterChoice: 'SET_FILTER_CHOICE',
    // Filter analytics
    setUsersCount: 'SET_USERS_COUNT',
    setAbp: 'SET_ABP',
    setAsv: 'SET_ASV',
    setMostVisitedStores: 'SET_MOST_VISITED_STORES',
    setOtherFilters: 'SET_OTHER_FILTERS',
    setDemography: 'SET_DEMOGRAPHY',
    // end
    setTempValue: 'SET_TEMP_VALUE',
    setGroupValue: 'SET_GROUP_VALUE',
    setFilterStatus: 'SET_FILTER_STATUS',
    rePopulateFilter: 'RE_POPULATE_FILTER'
  },
  dispatchers: {
    get setGroupValue() {
      return `${filterTypes.module}/${filterTypes.actions.setGroupValue}`;
    },
    get loadFormattedFilter() {
      return `${filterTypes.module}/${filterTypes.actions.loadFormattedFilter}`;
    },
    get setFrequencyCheck() {
      return `${filterTypes.module}/${filterTypes.actions.setFrequencyCheck}`;
    },
    get setRecencyCheck() {
      return `${filterTypes.module}/${filterTypes.actions.setRecencyCheck}`;
    },
    get setParams() {
      return `${filterTypes.module}/${filterTypes.actions.setParams}`;
    },
    get setProductsCount() {
      return `${filterTypes.module}/${filterTypes.actions.setProductsCount}`;
    },
    get setBestSellersWinning() {
      return `${filterTypes.module}/${filterTypes.actions.setBestSellersWinning}`;
    },
    // get setBestSellersLosing() {
    //   return `${filterTypes.module}/${filterTypes.actions.setBestSellersLosing}`;
    // },
    get setMonth() {
      return `${filterTypes.module}/${filterTypes.actions.setMonth}`;
    },
    get setGroup() {
      return `${filterTypes.module}/${filterTypes.actions.setGroup}`;
    },
    get unsetGroup() {
      return `${filterTypes.module}/${filterTypes.actions.unsetGroup}`;
    },
    get setGroupOperator() {
      return `${filterTypes.module}/${filterTypes.actions.setGroupOperator}`;
    },
    get setField() {
      return `${filterTypes.module}/${filterTypes.actions.setField}`;
    },
    get unsetField() {
      return `${filterTypes.module}/${filterTypes.actions.unsetField}`;
    },
    get setFilterOperator() {
      return `${filterTypes.module}/${filterTypes.actions.setFilterOperator}`;
    },
    get setFilter() {
      return `${filterTypes.module}/${filterTypes.actions.setFilter}`;
    },
    get setNegation() {
      return `${filterTypes.module}/${filterTypes.actions.setNegation}`;
    },
    get setGroupEditMode() {
      return `${filterTypes.module}/${filterTypes.actions.setGroupEditMode}`;
    },
    get setGroupConditions() {
      return `${filterTypes.module}/${filterTypes.actions.setGroupConditions}`;
    },
    get setCurrentlyEditing() {
      return `${filterTypes.module}/${filterTypes.actions.setCurrentlyEditing}`;
    },
    get resetEditing() {
      return `${filterTypes.module}/${filterTypes.actions.resetEditing}`;
    },
    get loadFilter() {
      return `${filterTypes.module}/${filterTypes.actions.loadFilter}`;
    },
    get populateFields() {
      return `${filterTypes.module}/${filterTypes.actions.populateFields}`;
    },
    get getFilters() {
      return `${filterTypes.module}/${filterTypes.actions.getFilters}`;
    },
    // get deleteFilter() {
    //   return `${filterTypes.module}/${filterTypes.actions.deleteFilter}`;
    // },
    get flushFilter() {
      return `${filterTypes.module}/${filterTypes.actions.flushFilter}`;
    },
    get getCampaignFilters() {
      return `${filterTypes.module}/${filterTypes.actions.getCampaignFilters}`;
    },
    get populateValues() {
      return `${filterTypes.module}/${filterTypes.actions.populateValues}`;
    },
    get populateOtherFields() {
      return `${filterTypes.module}/${filterTypes.actions.populateOtherFields}`;
    },
    get setFilterChoice() {
      return `${filterTypes.module}/${filterTypes.actions.setFilterChoice}`;
    },
    get setUsersCount() {
      return `${filterTypes.module}/${filterTypes.actions.setUsersCount}`;
    },
    get setAbp() {
      return `${filterTypes.module}/${filterTypes.actions.setAbp}`;
    },
    get setAsv() {
      return `${filterTypes.module}/${filterTypes.actions.setAsv}`;
    },
    get setMostVisitedStores() {
      return `${filterTypes.module}/${filterTypes.actions.setMostVisitedStores}`;
    },
    get setOtherFilters() {
      return `${filterTypes.module}/${filterTypes.actions.setOtherFilters}`;
    },
    get setDemography() {
      return `${filterTypes.module}/${filterTypes.actions.setDemography}`;
    },
    get setTempValue() {
      return `${filterTypes.module}/${filterTypes.actions.setTempValue}`;
    },
    get setFilterStatus() {
      return `${filterTypes.module}/${filterTypes.actions.setFilterStatus}`;
    },
    get rePopulateFilter() {
      return `${filterTypes.module}/${filterTypes.actions.rePopulateFilter}`;
    }
  }
};
