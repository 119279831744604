/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (customerAnalyticsTypes.dispatchers.getCustomer, ...)
 */

export const productAnalyticsTypes = {
  module: 'productAnalytics',
  getters: {
    // Get a single product
    get getProduct() {
      return `${productAnalyticsTypes.module}/getProduct`;
    },
    get getAbpp() {
      return `${productAnalyticsTypes.module}/getAbpp`;
    },
    get getSegments() {
      return `${productAnalyticsTypes.module}/getSegments`;
    },
    get getTabulateSegmentUsers() {
      return `${productAnalyticsTypes.module}/getTabulateSegmentUsers`;
    }
  },
  actions: {
    setTenantProduct: 'SET_TENANTS_PRODUCT',
    setAbpp: 'SET_ABPP',
    setSegments: 'SET_SEGMENTS',
    setTabulateSegmentUsers: 'SET_TABULATE_SEGMENT_USERS',
    flush: 'FLUSH_PRODUCT_ANALYTICS',
    updateProduct: 'UPDATE_PRODUCT'
  },
  dispatchers: {
    get setTenantProduct() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.setTenantProduct}`;
    },
    get setAbpp() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.setAbpp}`;
    },
    get setSegments() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.setSegments}`;
    },
    get setTabulateSegmentUsers() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.setTabulateSegmentUsers}`;
    },
    get flush() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.flush}`;
    },
    // web/src/store/productAnalytics/actions.js:89
    get updateProduct() {
      return `${productAnalyticsTypes.module}/${productAnalyticsTypes.actions.updateProduct}`;
    }
  }
};
