/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const emailTypes = {
  module: 'email',
  getters: {
    get getRenderedHtml() {
      return `${emailTypes.module}/getRenderedHtml`;
    }
  },
  actions: {
    sendEmail: 'SEND_EMAIL',
    getEmailDetails: 'GET_EMAIL_DETAILS'
  },
  dispatchers: {
    get sendEmail() {
      return `${emailTypes.module}/${emailTypes.actions.sendEmail}`;
    },
    get getEmailDetails() {
      return `${emailTypes.module}/${emailTypes.actions.getEmailDetails}`;
    }
  }
};
