import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const tenant = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getTenant: state => {
      return state.tenant;
    },
    getMetaParam: state => {
      return state.metaParam;
    }
  },
  actions,
  mutations
};
