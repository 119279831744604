<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ passed, failed, errors }">
    <label :for="name">{{ label }}</label>
    <div class="auth-position-relative">
      <base-input
        required
        :id="name"
        v-model="innerValue"
        :placeholder="placeholder"
        :type="showPassword ? 'text' : 'password'"
        :error="errors[0]"
        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
      >
        <template v-slot:addonRight>
          <span class="input-group-append">
            <div class="input-group-text">
              <i
                :class="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye', 'auth-password-toggle', 'pl-2']"
                @click="togglePasswordVisibility"
              ></i>
            </div>
          </span>
        </template>
      </base-input>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'PasswordInput',
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPassword: false
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  }
};
</script>