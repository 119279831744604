import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const s3 = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getPresignedURL: state => {
      return state.presignedURL;
    },
    getFileS3ObjectUrl: state => {
      return state.fileS3ObjectUrl;
    }
  },
  actions,
  mutations
};
