import { emailTypes } from '../../constants/types';
import Vue from 'vue';

const { getEmailDetails } = emailTypes.actions;

export const mutations = {
  [getEmailDetails]: (state, { response }) => {
    const {
      id,
      user_email,
      from_email_address,
      replacement_template_data,
      rendered_html,
      success,
      created_at,
      updated_at,
      campaign_id,
      user_id,
      tenant_id
    } = response.data.value;

    Vue.set(state, 'id', id);
    Vue.set(state, 'user_email', user_email);
    Vue.set(state, 'from_email_address', from_email_address);
    Vue.set(state, 'replacement_template_data', replacement_template_data);
    Vue.set(state, 'rendered_html', rendered_html);
    Vue.set(state, 'success', success);
    Vue.set(state, 'created_at', created_at);
    Vue.set(state, 'updated_at', updated_at);
    Vue.set(state, 'campaign_id', campaign_id);
    Vue.set(state, 'user_id', user_id);
    Vue.set(state, 'tenant_id', tenant_id);
  }
};
