import { customerAnalyticsTypes } from '../../constants/types';
import Vue from 'vue';

const {
  setTenantCustomer,
  setAvgBasketPrice,
  setAvgBasketPriceMonth,
  setVisits,
  setYearSpending,
  setFavShops,
  setCsp,
  setEngagement,
  setTimeline,
  setSegments,
  setCustomerDayVisits,
  setRecommendations,
  updateTenantCustomer
} = customerAnalyticsTypes.actions;

export const mutations = {
  [setCustomerDayVisits]: (state, { response }) => {
    Vue.set(state, 'customerDayVisits', response.data.value);
  },

  [setRecommendations]: (state, { response }) => {
    Vue.set(state, 'recommendations', response.data.value);
  },

  [setTenantCustomer]: (state, { response }) => {
    Vue.set(state, 'customer', response.data.value);
  },

  [setAvgBasketPrice]: (state, { response }) => {
    Vue.set(state, 'avgBasketPrice', response.data.value);
  },

  [setAvgBasketPriceMonth]: (state, { response }) => {
    Vue.set(state, 'avgBasketPriceMonth', response.data.value);
  },

  [setVisits]: (state, { response }) => {
    Vue.set(state, 'visits', response.data.value);
  },

  [setYearSpending]: (state, { response }) => {
    Vue.set(state, 'yearSpending', response.data.value);
  },

  [setFavShops]: (state, { response }) => {
    Vue.set(state, 'favShops', response.data.value);
  },

  [setCsp]: (state, { response }) => {
    Vue.set(state, 'csp', response.data.value);
  },

  [setSegments]: (state, { response }) => {
    Vue.set(state, 'segments', response.data.value);
  },

  [setEngagement]: (state, { response }) => {
    Vue.set(state, 'engagement', response.data.value);
  },

  [setTimeline]: (state, { response }) => {
    Vue.set(state, 'timeline', response.data.value);
  },

  [updateTenantCustomer]: (state, { response }) => {
    Vue.set(state, 'customer', response.data.value);
  }
};
