<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href=""> DodoBird.ai </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=""> Privacy </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=""> Licenses </a>
        </li>
      </ul>
      <div class="copyright">
        © {{ year }} made with <i class="tim-icons icon-heart-2" /> by
        <a href="https://www.dodobird.ai" target="_blank" rel="noopener">Dodobird.ai Ltd</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
