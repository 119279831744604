<template>
  <div>
    <base-nav
      v-model="showMenu"
      type="white"
      :transparent="true"
      menu-classes="justify-content-end"
      class="auth-navbar fixed-top"
    >
      <div slot="brand" class="navbar-wrapper">
        <a class="navbar-brand" href="#" v-if="title">{{ errorText }}</a>
      </div>

      <ul class="navbar-nav">
        <router-link class="nav-item" tag="li" :to="`/${errorRedirectUrl}`">
          <a class="nav-link text-warning text-capitalize">
            <i class="tim-icons icon-minimal-left" /> Back to {{ errorRedirectUrl }}
          </a>
        </router-link>
      </ul>
    </base-nav>

    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <div class="centered">
              <h1>{{ errorTitle }}</h1>
              <img src="/favicon.ico" style="width: 115px" />
              <p>{{ errorDescription }}</p>
            </div>
            <router-view />
          </zoom-center-transition>
        </div>
        <content-footer />
      </div>
    </div>
  </div>
</template>
<script>
import BaseNav from '../../components/Navbar/BaseNav.vue';
import ContentFooter from '../Layout/ContentFooter.vue';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
    ContentFooter
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    },
    errorTitle: {
      type: String
    },
    errorDescription: {
      type: String
    },
    errorText: {
      type: String
    },
    errorRedirectUrl: {
      type: String,
      default: 'dashboard'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page'
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
  watch: {
    $route() {
      this.setPageClass();
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  mounted() {
    this.setPageClass();
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    }
  }
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
