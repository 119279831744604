export const getDefaultState = () => {
  return {
    id: '',
    user_email: '',
    from_email_address: '',
    replacement_template_data: {},
    rendered_html: '',
    success: false,
    created_at: '',
    updated_at: '',
    campaign_id: '',
    user_id: '',
    tenant_id: ''
  };
};
