import { customersTypes } from '../../constants/types/customers';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const { setCustomer, setCustomers, setOtherFields, getRandomCustomer } = customersTypes.actions;

export const actions = {
  [getRandomCustomer]: async ({ commit }) => {
    return query({
      commit,
      api: $api.customers.pickCustomer.get({}, '/pick-customer'),
      options: {
        promise: true,
        mutation: getRandomCustomer
      }
    });
  },

  [setOtherFields]: async ({ commit }) => {
    query({
      commit,
      api: $api.customers.setOtherFields.get({}),
      options: {
        mutation: setOtherFields
      }
    });
  },
  [setCustomer]: async ({ commit }, { customerId }) => {
    return query({
      commit,
      api: $api.analytics.customers.customer.get({ customerId }),
      options: {
        mutation: setCustomer,
        promise: true
      }
    });
  },
  [setCustomers]: async ({ commit }, { recent, search, category, sortOption, cursor, segmentOption }) => {
    return query({
      commit,
      api: $api.analytics.customers.customers.get({
        recent,
        search,
        category,
        sortOption,
        cursor,
        segmentOption
      }),
      options: {
        mutation: setCustomers
      }
    });
  }
};
