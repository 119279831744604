import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const settings = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getCampaignDetails: state => state.campaignDetails,
    getTenantSettings(state) {
      return state.tenantSettings;
    }
  },
  actions,
  mutations
};
