export const templateTypes = {
  module: 'template',
  getters: {
    get getTemplates() {
      return `${templateTypes.module}/getTemplates`;
    }
  },
  actions: {
    saveTemplate: 'SAVE_TEMPLATE',
    setTemplates: 'SET_TEMPLATES',
    setTemplate: 'SET_TEMPLATE',
    setImage: 'SET_IMAGE',
    deleteTemplate: 'DELETE_TEMPLATE',
    setMergeTags: 'SET_MERGE_TAGS',
    duplicateTemplate: 'DUPLICATE_TEMPLATE'
  },
  dispatchers: {
    get deleteTemplate() {
      return `${templateTypes.module}/${templateTypes.actions.deleteTemplate}`;
    },
    get saveTemplate() {
      return `${templateTypes.module}/${templateTypes.actions.saveTemplate}`;
    },
    get setTemplates() {
      return `${templateTypes.module}/${templateTypes.actions.setTemplates}`;
    },
    get setTemplate() {
      return `${templateTypes.module}/${templateTypes.actions.setTemplate}`;
    },
    get setImage() {
      return `${templateTypes.module}/${templateTypes.actions.setImage}`;
    },
    get setMergeTags() {
      return `${templateTypes.module}/${templateTypes.actions.setMergeTags}`;
    },
    get duplicateTemplate() {
      return `${templateTypes.module}/${templateTypes.actions.duplicateTemplate}`;
    }
  }
};
