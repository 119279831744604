import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class FilterApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(data = {}, path, formatted = false) {
    try {
      await this.setAuthHeaders();
      if (path) {
        return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}/${path}`, {
          params: {
            ...data
          }
        });
      }
      return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}`, {
        params: {
          ...data
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async delete(data = {}, path) {
    try {
      await this.setAuthHeaders();
      if (path) {
        return axios.delete(`${this.getUrl()}/${path}`, {
          data: { ...data }
        });
      }
      return axios.delete(`${this.getUrl()}`, {
        data: { ...data }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async update(data = {}, path) {
    try {
      await this.setAuthHeaders();
      if (path) {
        return axios.put(`${this.getUrl()}/${path}`, data, {
          headers: { 'Content-Type': 'application/json' }
        });
      }
      return axios.put(this.getUrl(), data, {
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async post(data = {}, url = null) {
    try {
      this.setAuthHeaders();
      return axios.post(`${this.getUrl()}${url ? `/${url}` : ''}`, data, {
        headers: { 'Content-Type': 'application/json' }
      }); //API.post("segmentsAPI", this.route, data);
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
