export const s3Types = {
  module: 's3',
  getters: {
    get getPresignedURL() {
      return `${s3Types.module}/getPresignedURL`;
    },
    get getFileS3ObjectUrl() {
      return `${s3Types.module}/getFileS3ObjectUrl`;
    }
  },
  actions: {
    getPresignedURL: 'GET_PRESIGNED_URL',
    putFileS3: 'PUT_FILE_S3',
    getFileS3ObjectUrl: 'GET_FILE_S3_OBJECT_URL'
  },
  dispatchers: {
    get getPresignedURL() {
      return `${s3Types.module}/${s3Types.actions.getPresignedURL}`;
    },
    get putFileS3() {
      return `${s3Types.module}/${s3Types.actions.putFileS3}`;
    },
    get getFileS3ObjectUrl() {
      return `${s3Types.module}/GET_FILE_S3_OBJECT_URL`;
    }
  }
};
