import { settingsTypes } from '../../constants/types';
import Vue from 'vue';

const { setSettings, setTenantSettings } = settingsTypes.actions;

export const mutations = {
  [setSettings](state, payload) {
    // TODO: rename as default-campaign-settings
    Vue.set(state, 'campaignDetails', payload.response.data.value.campaignDetails);
  },

  [setTenantSettings](state, payload) {
    const settings = payload.response.data.value;

    Object.entries(settings).forEach(([key, value]) => {
      Vue.set(state.tenantSettings[key], 'value', value);
    });
  }
};
