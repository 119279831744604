/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const campaignTypes = {
  module: 'campaign',
  getters: {
    get getCampaigns() {
      return `${campaignTypes.module}/getCampaigns`;
    },
    get getStatus() {
      return `${campaignTypes.module}/getStatus`;
    },
    get getCampaignDetails() {
      return `${campaignTypes.module}/getCampaignDetails`;
    },
    get getCampaignDetailsA() {
      return `${campaignTypes.module}/getCampaignDetailsA`;
    },
    get getCampaignDetailsB() {
      return `${campaignTypes.module}/getCampaignDetailsB`;
    },
    get getFile() {
      return `${campaignTypes.module}/getFile`;
    },
    get getCheckDefaultCampaignSettings() {
      return `${campaignTypes.module}/getCheckDefaultCampaignSettings`;
    }
  },
  actions: {
    setCampaignDetails: 'SET_CAMPAIGN_DETAILS',
    updateCampaignValue: 'UPDATE_CAMPAIGN_VALUE',
    updateCampaignValueA: 'UPDATE_CAMPAIGN_VALUE_A',
    updateCampaignValueB: 'UPDATE_CAMPAIGN_VALUE_B',
    resetCampaignDetails: 'RESET_CAMPAIGN_DETAILS',
    getAPIcampaigns: 'GET_API_CAMPAIGNS',
    deleteCampaign: 'DELETE_CAMPAIGN',
    loadCampaign: 'LOAD_CAMPAIGN',
    flushCampaign: 'FLUSH_CAMPAIGN',
    saveCSV: 'SAVE_CSV',
    updateSelectedDateAndTime: 'UPDATE_SELECTED_DATE_AND_TIME',
    setCheckDefaultCampaignSettings: 'SET_CHECK_DEFAULT_CAMPAIGN_SETTINGS'
  },
  dispatchers: {
    get setCampaignDetails() {
      return `${campaignTypes.module}/${campaignTypes.actions.setCampaignDetails}`;
    },
    get updateCampaignValue() {
      return `${campaignTypes.module}/${campaignTypes.actions.updateCampaignValue}`;
    },
    get updateCampaignValueA() {
      return `${campaignTypes.module}/${campaignTypes.actions.updateCampaignValueA}`;
    },
    get updateCampaignValueB() {
      return `${campaignTypes.module}/${campaignTypes.actions.updateCampaignValueB}`;
    },
    get resetCampaignDetails() {
      return `${campaignTypes.module}/${campaignTypes.actions.resetCampaignDetails}`;
    },
    get getAPIcampaigns() {
      return `${campaignTypes.module}/${campaignTypes.actions.getAPIcampaigns}`;
    },
    get deleteCampaign() {
      return `${campaignTypes.module}/${campaignTypes.actions.deleteCampaign}`;
    },
    get loadCampaign() {
      return `${campaignTypes.module}/${campaignTypes.actions.loadCampaign}`;
    },
    get flushCampaign() {
      return `${campaignTypes.module}/${campaignTypes.actions.flushCampaign}`;
    },
    get saveCSV() {
      return `${campaignTypes.module}/${campaignTypes.actions.saveCSV}`;
    },
    get updateSelectedDateAndTime() {
      return `${campaignTypes.module}/${campaignTypes.actions.updateSelectedDateAndTime}`;
    },
    get setCheckDefaultCampaignSettings() {
      return `${campaignTypes.module}/${campaignTypes.actions.setCheckDefaultCampaignSettings}`;
    }
  }
};
