import { databaseChoresTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const { ping } = databaseChoresTypes.actions;

export const actions = {
  [ping]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.database.ping.get({}),
      options: {
        mutation: ping,
        promise: true
      }
    });
  }
};
