import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class AnalyticsApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }
  async get(data = {}, path) {
    try {
      if (path) {
        await this.setAuthHeaders();
        return axios.get(`${this.getUrl()}/${path}`, {
          params: {
            ...data
          }
        });
      }
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}`, {
        params: {
          ...data
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async post(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.post(this.getUrl(), data); //API.post("segmentsAPI", this.route, data);
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async put(data = {}, path) {
    try {
      await this.setAuthHeaders();
      return axios.put(`${this.getUrl()}/${path}`, data);
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
