export const getDefaultState = () => {
  return {
    devSQL: '',
    name: '',
    operator: 'INTERSECT', //UNION for OR
    description: '',
    count: 0,
    bestSellersWinning: [],
    bestSellersLosing: [],
    sql_query: '',
    sql_query_with_parent: '',

    groups: {
      GP0: {
        operator: 'AND',
        filterChoice: null,
        fields: {},
        frequencyCheck: false,
        frequencyOperator: '=',
        frequencyValue: false
      }
    },

    otherFields: [],
    tempValue: null,

    recencyCheck: false,
    frequencyCheck: false,

    selects: {
      filter: '',
      recencyValue: {
        input: 1
      },
      frequencyValue: {
        input: 1
      },
      recencyType: {
        input: 'days',
        options: [
          { value: 'days', label: 'Days' },
          { value: 'months', label: 'Months' }
        ]
      },
      recencyOperator: {
        input: '<',
        options: [
          { value: '<', label: 'Later than' },
          { value: '>', label: 'earlier than' }
        ]
      },
      frequencyOperator: {
        input: '=',
        options: [
          { value: '=', label: '=' },
          { value: '>', label: '>' },
          { value: '<', label: '<' },
          { value: '<=', label: '<=' },
          { value: '>=', label: '>=' }
        ]
      },
      table: {
        input: '',
        options: [{ value: 'product', label: 'Product' }]
      },
      field: {
        input: '',
        type: 'local',
        options: {
          product: [
            { value: 'item_id', label: 'Product ID' },
            { value: 'name', label: 'Name' },
            { value: 'sku', label: 'SKU' },
            { value: 'description', label: 'Description' },
            { value: 'brand', label: 'Brand' },
            { value: 'meta_category', label: 'Meta category' },
            { value: 'category', label: 'Category' },
            { value: 'sub_category', label: 'Sub category' },
            { value: 'image_url', label: 'Image url' },
            { value: 'item_url', label: 'Product url' },
            { value: 'price', label: 'Price' },
            { value: 'cost', label: 'Cost' },
            { value: 'tenant_created_at', label: 'Added Datetime' },
            { value: 'channel_name', label: 'Channel Name' },
            { value: 'status', label: 'Status' },
            { value: 'stock_qty', label: 'Stock Qty' }
          ]
        }
      },
      operator: {
        input: '',
        options: {
          number: [
            { value: '=', label: '=' },
            { value: '>', label: '>' },
            { value: '<', label: '<' },
            { value: '>=', label: '>=' },
            { value: '<=', label: '<=' }
          ],
          string: [
            { value: '=', label: '=' },
            { value: '!=', label: '!=' },
            { value: 'LIKE', label: 'LIKE' },
            { value: 'NOT LIKE', label: 'NOT LIKE' }
          ]
        }
      },
      value: {
        input: '',
        type: 'string',
        options: []
      }
    },
    currentlyEditing: '',
    currentGroup: 'GP0',
    currentTable: '',

    filters: {
      sql: [],
      numeric: [],
      user_numeric: []
    },

    conditions: {
      table: null,
      field: null,
      operator: null,
      value: null
    }
  };
};
