/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const campaignAnalyticsTypes = {
  module: 'campaignAnalytics',
  getters: {
    get getLiveCustomerCount() {
      return `${campaignAnalyticsTypes.module}/getLiveCustomerCount`;
    },
    get getLiveItemCount() {
      return `${campaignAnalyticsTypes.module}/getLiveItemCount`;
    },
    get getDefaultDodoMailCampaignAnalytics() {
      return `${campaignAnalyticsTypes.module}/getDefaultDodoMailCampaignAnalytics`;
    },
    get getDefaultWebsiteCampaignAnalytics() {
      return `${campaignAnalyticsTypes.module}/getDefaultWebsiteCampaignAnalytics`;
    }
  },
  actions: {
    setLiveCustomerCount: 'SET_LIVE_CUSTOMER_COUNT',
    setLiveItemCount: 'SET_LIVE_ITEM_COUNT',
    loadDefaultCampaignAnalytics: 'LOAD_DEFAULT_CAMPAIGN_ANALYTICS'
  },
  dispatchers: {
    get setLiveCustomerCount() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setLiveCustomerCount}`;
    },
    get setLiveItemCount() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.setLiveItemCount}`;
    },
    get loadDefaultCampaignAnalytics() {
      return `${campaignAnalyticsTypes.module}/${campaignAnalyticsTypes.actions.loadDefaultCampaignAnalytics}`;
    }
  }
};
