import Vue from 'vue';

export default {
  inserted: function (el, binding) {
    applyAccessControl(el, binding.value);
  },
  update: function (el, binding) {
    if (binding.value !== binding.oldValue) {
      applyAccessControl(el, binding.value);
    }
  },
  componentUpdated: function (el, binding) {
    applyAccessControl(el, binding.value);
  },
  unbind: function (el) {
    removeAccessControl(el);
  }
};

function applyAccessControl(element, value, isParent = true) {
  if (value) {
    const { status, component } = value;
    if (status) {
      if (isParent) {
        // Apply visual styles only to the parent element
        element.style.cursor = 'not-allowed';
        element.style.opacity = '0.6';
      }

      // Disable click for all elements
      element.__disableClick__ = function (event) {
        event.stopImmediatePropagation();
        event.preventDefault();

        // Emit an event on the global event bus
        if (status === 'feature-unsubscribed') {
          Vue.prototype.$eventBus.$emit('showNotification', {
            type: 'danger',
            message: 'Subscription required. Please subscribe to continue using this feature.',
            icon: 'tim-icons icon-bell-55'
          });
        } else if (status === 'feature-quota-exceeded') {
          Vue.prototype.$eventBus.$emit('showNotification', {
            type: 'danger',
            message: 'Quota exceeded. Please upgrade your plan to continue using this feature.',
            icon: 'tim-icons icon-bell-55'
          });
        } else if (status === 'permission-unauthorized') {
          Vue.prototype.$eventBus.$emit('showNotification', {
            type: 'warning',
            message: `You are not authorized to access the feature: ${replaceAndOmit(component)}.`,
            icon: 'tim-icons icon-bell-55'
          });
        }
      };

      // Disable scroll for all elements
      // element.__disableScroll__ = function (event) {
      //   event.preventDefault();
      // };

      element.addEventListener('click', element.__disableClick__, true);
      // element.addEventListener('wheel', element.__disableScroll__, { passive: false });
      // element.addEventListener('touchmove', element.__disableScroll__, { passive: false });

      // Disable input for form elements and button
      if (
        element.tagName === 'INPUT' ||
        element.tagName === 'TEXTAREA' ||
        element.tagName === 'SELECT' ||
        element.tagName === 'BUTTON'
      ) {
        element.disabled = true;
      }
    } else {
      // If status is null or falsy, remove access control
      removeAccessControl(element);
    }

    // Recursively apply to child elements, but don't apply CSS to them
    Array.from(element.children).forEach(child => applyAccessControl(child, status, false));
  }
}

function removeAccessControl(element) {
  // Remove the event listeners and styles
  if (element.__disableClick__) {
    element.removeEventListener('click', element.__disableClick__, true);
    delete element.__disableClick__;
  }
  // if (element.__disableScroll__) {
  //   element.removeEventListener('wheel', element.__disableScroll__, { passive: false });
  //   element.removeEventListener('touchmove', element.__disableScroll__, { passive: false });
  //   delete element.__disableScroll__;
  // }

  // Remove styles from the element
  element.style.cursor = '';
  element.style.opacity = '';

  // Re-enable input for form elements and buttons
  if (
    element.tagName === 'INPUT' ||
    element.tagName === 'TEXTAREA' ||
    element.tagName === 'SELECT' ||
    element.tagName === 'BUTTON'
  ) {
    element.disabled = false;
  }

  // Recursively remove from child elements
  Array.from(element.children).forEach(removeAccessControl);
}

function replaceAndOmit(input) {
  // Split by both '.' and '_'
  let parts = input.split(/[._]/);

  // Remove the last part (VIEW, EDIT, SEND, etc.)
  parts.pop();

  // Join the remaining parts with spaces
  return parts.join(' ');
}
