import { CONSTANTS } from '../constants/globals';
import { format } from 'sql-formatter';
import moment from 'moment';

const mappedTables = {
  product: 'items',
  customer: 'users',
  segment: 'segments',
  sale: 'events'
};

const additionalTables = {
  product: 'custom',
  customer: 'custom',
  sale: 'custom'
};

const addOtherOptions = (field, temp_query) => {
  let isUser = true;
  if (field.table === 'product' || field.table === 'sale') {
    isUser = false;
  }
  const singleConditions = [temp_query];

  if (field.frequency.checked && !isUser) {
    singleConditions.push(`events.event_value ${field.frequency.operator} '${field.frequency.figure}'`);
  }
  if (field.recency.checked && !isUser) {
    singleConditions.push(
      `events.event_dt ${field.recency.operator} CURRENT_TIMESTAMP - INTERVAL '${field.recency.figure} ${field.recency.type}'`
    );
  }
  let condition_final = temp_query;
  if (singleConditions.length > 1) {
    condition_final = singleConditions.reduce((prev, next) => `${prev} AND ${next}`);
  }
  return `(${condition_final})`;
};

const formatSQL = value => {
  return `'${value.replace(/'/g, "''")}'`;
};

const generateSingleWhereQuery = (field, groupOperator) => {
  let query = '';
  const value =
    typeof field.value == 'number' || typeof field.value === 'object' ? field.value : field.value.replace(/'/g, "''");
  const table = mappedTables[field.table];
  let operator = field.operator;

  if (groupOperator == 'OR' && field.negation) operator = '!=';

  const formattedField = field.field.split('+')[0];

  if ((CONSTANTS[field.table] && CONSTANTS[field.table].includes(formattedField)) || field.table === 'segment') {
    if (typeof field.value === 'object') {
      const date1 = String(moment(value[0]).format('YYYY-MM-DD'));
      const date2 = String(moment(value[1]).format('YYYY-MM-DD'));

      query = `${table}.${formattedField} >= '${date1}' AND ${table}.${formattedField} <= '${date2}'`;
    } else {
      query = `${table}.${formattedField} ${operator} '${value}'`;
    }
  } else {
    query = `${table}.${additionalTables[field.table]}->>'${formattedField}' ${operator} '${value}'`;
  }

  return query;
};

const generateMultipleWhereQuery = (fields, groupOperator) => {
  const groupedField = [];
  let isSegment = false;

  const counter = {};

  fields.forEach(field => {
    if (
      !counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ]
    ) {
      counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] = 0;
    }
    counter[
      field.frequency.checked || field.recency.checked
        ? `${field.table}-${field.field}-advanced`
        : `${field.table}-${field.field}`
    ] += 1;
  });

  const composedFields = {};

  fields.forEach(field => {
    if (field.table === 'segment') {
      isSegment = true;
    }

    // if (field.table === 'product' || field.table === 'sale') {
    //   isUser = false;
    // }
    if (
      counter[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] > 1
    ) {
      if (
        !composedFields[
          field.frequency.checked || field.recency.checked
            ? `${field.table}-${field.field}-advanced`
            : `${field.table}-${field.field}`
        ]
      ) {
        composedFields[
          field.frequency.checked || field.recency.checked
            ? `${field.table}-${field.field}-advanced`
            : `${field.table}-${field.field}`
        ] = {
          field: field.field,
          table: field.table,
          values: [],
          negation: field.negation
        };
      }
      composedFields[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ].values.push(field.value);
    } else {
      composedFields[
        field.frequency.checked || field.recency.checked
          ? `${field.table}-${field.field}-advanced`
          : `${field.table}-${field.field}`
      ] = field;
    }
  });

  Object.keys(composedFields).forEach(cf => {
    const field = composedFields[cf];

    if (field.table === 'segment') {
      isSegment = true;
    }

    // if (field.table === 'item' || field.table === 'sale') {
    //   isUser = false;
    // }

    if (field.values) {
      let condition = '';

      if (CONSTANTS[field.table] && CONSTANTS[field.table].includes(field.field)) {
        if (field.field.toLowerCase().includes('_dt') || field.field.toLowerCase().includes('date')) {
          const formattedDateRange = field.values.map(dateRange => {
            const date1 = String(moment(dateRange[0]).format('YYYY-MM-DD'));
            const date2 = String(moment(dateRange[1]).format('YYYY-MM-DD'));
            return `SELECT events.user_id FROM events WHERE events.event_dt >= '${date1}' AND events.event_dt <= '${date2}'`;
          });

          condition = `${mappedTables[field.table]}.user_id ${
            field.negation ? 'NOT IN' : 'IN'
          } (${formattedDateRange.join(' UNION ')})`;
        } else {
          condition = `${mappedTables[field.table]}.${field.field} ${field.negation ? 'NOT IN' : 'IN'} (${field.values
            .map(f => formatSQL(f))
            .reduce((va, vb) => va + ', ' + vb)})`;
        }
      } else {
        condition = `${mappedTables[field.table]}.${additionalTables[field.table]}->>'${field.field}' ${
          field.negation ? 'NOT IN' : 'IN'
        } (${field.values.map(f => formatSQL(f)).reduce((va, vb) => va + ', ' + vb)})`;
      }

      groupedField.push(condition);
    } else {
      let condition = generateSingleWhereQuery(field, groupOperator);
      const condition_final = addOtherOptions(field, condition);
      groupedField.push(condition_final);
    }
  });

  const condition = groupedField.reduce((a, b) => `${a} OR ${b}`);
  return {
    condition,
    isSegment
  };
};

const addToSymbol = (field, symbolTable) => {
  const locationInSymbolTable = symbolTable[field.table];

  if (!locationInSymbolTable[field.field]) {
    locationInSymbolTable[field.field] = [];
  }
  locationInSymbolTable[field.field].push(field);
};

const createGroup = group => {
  if (Object.keys(group.fields).length === 0) return null;

  let isUser = true;
  if (Object.values(group.fields).some(f => f.table === 'product' || f.table === 'sale')) {
    isUser = false;
  }

  let isSale = false;
  if (Object.values(group.fields).some(f => f.table === 'sale')) {
    isSale = true;
  }

  // If any field refer to the items or table
  let isItem = false;
  if (Object.values(group.fields).some(f => f.field.includes('item'))) {
    isItem = true;
  }

  if (group.operator === 'OR') {
    const { condition, isSegment } = generateMultipleWhereQuery(Object.values(group.fields), group.operator);
    return {
      groupCondition: condition,
      havingCondition: null,
      isSegment,
      isUser,
      isSale,
      isItem
    };
  } else {
    let symbolNegativeTable = {
      product: {},
      customer: {},
      segment: {},
      sale: {}
    };
    let symbolTable = {
      product: {},
      customer: {},
      segment: {},
      sale: {}
    };
    let symbolCounter = {};

    let isSegment = false;

    // Group the fields together
    const fields = group.fields;

    Object.keys(fields).forEach(fieldKey => {
      const field = fields[fieldKey];

      if (field.table === 'segment') {
        isSegment = true;
      }

      if (field.negation) {
        addToSymbol(field, symbolNegativeTable);
      } else {
        if (field.field.toLowerCase().includes('_dt') || field.field.toLowerCase().includes('date')) {
          const position = Object.keys(symbolTable[field.table]).length;
          addToSymbol({ ...field, field: `${field.field}+${position}` }, symbolTable, symbolCounter);
        } else {
          addToSymbol(field, symbolTable, symbolCounter);
        }
      }
    });

    Object.keys(fields).forEach(fieldKey => {
      const field = fields[fieldKey];

      if (
        symbolTable[field.table][field.field] &&
        symbolTable[field.table][field.field].length > 1 &&
        !field.negation
      ) {
        if (CONSTANTS[field.table] && CONSTANTS[field.table].includes(field.field)) {
          symbolCounter[`COUNT(DISTINCT ${mappedTables[field.table]}.${field.field})`] =
            symbolTable[field.table][field.field].length;
        } else {
          symbolCounter[
            `COUNT(DISTINCT ${mappedTables[field.table]}.${additionalTables[field.table]}->>'${field.field}')`
          ] = symbolTable[field.table][field.field].length;
        }
      }
    });

    // Set the where conditions
    let conditions = [];
    Object.keys(symbolTable).forEach(table => {
      Object.keys(symbolTable[table]).forEach(fieldName => {
        const fields = symbolTable[table][fieldName];
        let inline_group_query = '';
        if (fields.length == 1) {
          const field = fields[0];
          const temp_query = generateSingleWhereQuery(field, group.operator);

          const condition_final = addOtherOptions(field, temp_query);
          inline_group_query = condition_final;
        } else {
          const { condition } = generateMultipleWhereQuery(fields, group.operator);
          inline_group_query = condition;
        }
        conditions.push(inline_group_query);
      });
    });

    // Where conditions
    let groupCondition = '';
    if (conditions.length == 1) {
      groupCondition = `(${conditions[0]})`;
    } else if (conditions.length > 1) {
      groupCondition = conditions.reduce((condition_a, condition_b) => {
        return `(${condition_a}) ${group.operator} (${condition_b})`;
      });
    }

    // Having conditions
    let havingCondition = '';
    if (Object.keys(symbolCounter).length == 1) {
      const condition = Object.keys(symbolCounter)[0];
      havingCondition = `${condition} = ${symbolCounter[condition]}`;
    } else if (Object.keys(symbolCounter).length > 1) {
      havingCondition = Object.keys(symbolCounter).reduce((condition_a, condition_b) => {
        return `${condition_a} = ${symbolCounter[condition_a]} AND ${condition_b} = ${symbolCounter[condition_b]}`;
      });
    }

    // Set the conditions for negations
    let negations = [];
    Object.keys(symbolNegativeTable).forEach(table => {
      if (Object.keys(symbolNegativeTable[table]).length >= 1) {
        Object.keys(symbolNegativeTable[table]).forEach(fieldName => {
          const fields = symbolNegativeTable[table][fieldName];
          let values = '';

          if (
            typeof fields[0] === 'object' &&
            (fields[0].field.toLowerCase().includes('_dt') || fields[0].field.toLowerCase().includes('date'))
          ) {
            if (fields.length > 0) {
              fields.forEach(field => {
                negations.push(
                  `COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} >= '${moment(field.value[0]).format(
                    'YYYY-MM-DD'
                  )}' AND ${mappedTables[table]}.${fieldName} <= '${moment(field.value[1]).format(
                    'YYYY-MM-DD'
                  )}' THEN 1 END) = 0`
                );
              });
            } else {
              negations.push(
                `COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} >= '${moment(fields[0].value[0]).format(
                  'YYYY-MM-DD'
                )}' AND ${mappedTables[table]}.${fieldName} <= '${moment(fields[0].value[1]).format(
                  'YYYY-MM-DD'
                )}' THEN 1 END) = 0`
              );
            }
          } else {
            if (fields.length == 1) {
              if (
                typeof fields[0] === 'object' &&
                (fields[0].field.toLowerCase().includes('_dt') || fields[0].field.toLowerCase().includes('date'))
              ) {
                values = `'${fields[0].value}'`;
              } else {
                values = `'${fields[0].value.replace(/'/g, "''")}'`;
              }
            } else if (fields.length > 1) {
              values = fields.reduce((prev, curr, i) => {
                return i === 0 ? formatSQL(curr.value) : prev + ', ' + formatSQL(curr.value);
              }, '');
            }

            if (CONSTANTS[table] && CONSTANTS[table].includes(fieldName)) {
              negations.push(`COUNT (CASE WHEN ${mappedTables[table]}.${fieldName} IN (${values}) THEN 1 END) = 0`);
            } else {
              negations.push(
                `COUNT (CASE WHEN ${mappedTables[table]}.${additionalTables[table]}->>'${fieldName}' IN (${values}) THEN 1 END) = 0`
              );
            }
          }
        });
      }
    });

    let negationCondition = '';
    if (negations.length == 1) {
      negationCondition = negations[0];
    } else if (negations.length > 1) {
      negationCondition = negations.reduce((a, b) => `${a} ${group.operator} ${b}`);
    }

    return {
      groupCondition,
      havingCondition,
      negationCondition,
      isSegment,
      isUser,
      isSale,
      isItem
    };
  }
};

// eslint-disable-next-line no-unused-vars
const generateBaseQuery = (isUser, isSegment, isItem, isSale) => {
  if (isUser) {
    return `
      SELECT DISTINCT users.user_id FROM users
      ${
        isSegment
          ? `
      INNER JOIN user_segments ON user_segments.user_id = users.user_id
      INNER JOIN segments ON segments.id = user_segments.segment_id
      `
          : ''
      }`;
  } else {
    // NOTE: I added isItem because sometimes the query never reference the product table.
    // e.g. on sales tables, get all users who bought
    return `
      SELECT DISTINCT events.user_id, COUNT(events.user_id) as c_count FROM events
      ${isItem ? 'INNER JOIN items ON items.item_id = events.item_id' : ''}
      INNER JOIN users ON users.user_id = events.user_id
      ${
        isSegment
          ? `
      INNER JOIN user_segments ON user_segments.user_id = events.user_id
      INNER JOIN segments ON segments.id = user_segments.segment_id
      `
          : ''
      }`;
  }
};

const HavingConditionNegationCondition = (
  groupCondition,
  havingCondition,
  negationCondition,
  groupOperator,
  isSegment,
  isUser = true,
  isItem,
  isSale
) => {
  const baseQuery = generateBaseQuery(isUser, isSegment, isItem, isSale);

  if (groupCondition) {
    if (groupOperator == 'AND') {
      return `
      SELECT a.user_id FROM (
        ${baseQuery}
        WHERE ${groupCondition}
        GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        HAVING ${havingCondition}
      ) a INNER JOIN (
        ${baseQuery}
        GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        HAVING ${negationCondition}
      ) b ON a.user_id = b.user_id
      `;
    } else {
      return `
      ${baseQuery}
      WHERE ${groupCondition}
      GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
      HAVING ${havingCondition}
      UNION
      ${baseQuery}
      GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
      HAVING ${negationCondition}
      `;
    }
  } else {
    return `
    ${baseQuery}
    GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
    HAVING ${negationCondition}
    `;
  }
};

const NotHavingConditionNegationCondition = (
  groupCondition,
  negationCondition,
  groupOperator,
  isSegment,
  isUser = true,
  isItem,
  isSale
) => {
  const baseQuery = generateBaseQuery(isUser, isSegment, isItem, isSale);

  if (groupCondition) {
    if (groupOperator == 'AND') {
      return `
      SELECT a.user_id FROM (
        ${baseQuery}
        WHERE ${groupCondition}
      ) a INNER JOIN (
        ${baseQuery}
        GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        HAVING ${negationCondition}
      ) b ON a.user_id = b.user_id
      `;
    } else {
      return `
      ${baseQuery}
      WHERE ${groupCondition}
      UNION
      ${baseQuery}
      GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
      HAVING ${negationCondition}
      `;
    }
  } else {
    return `
    ${baseQuery}
    GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
    HAVING ${negationCondition}
    `;
  }
};

export const getClauses = (groups, segmentOperator, parentTable = 'users') => {
  const groupKeys = Object.keys(groups);
  console.log(groupKeys);
  const groupConditions = groupKeys.map((groupKey, index) => {
    const foo = `foo${index}`;
    const group = groups[groupKey];

    // let eventType = null;
    // Object.values(group.fields).forEach(f => {
    //   if (f.field === 'event_type') {
    //     eventType = f.value;
    //   }
    // });

    const creation = createGroup(group);
    if (!creation) return null;

    const { groupCondition, havingCondition, negationCondition, isSegment, isUser, isItem, isSale } = creation;

    const groupFrequencyCondition = group.frequencyCheck
      ? `WHERE c_count ${group.frequencyOperator} ${group.frequencyValue}`
      : '';

    // const tableToUse = isUser ? 'users' : 'events';

    let salesEventTypeFilter = true;
    // If any of the field is event_type, salesEventTypeFilter must be set to False
    Object.values(group.fields).forEach(f => {
      console.log(f);
      if (f.field === 'event_type') {
        salesEventTypeFilter = false;
      }
    });

    if (havingCondition) {
      if (negationCondition) {
        return `
        SELECT user_id FROM (
          SELECT ${isUser ? 'users.user_id' : 'events.user_id'}, COUNT(${
          isUser ? 'users.user_id' : 'events.user_id'
        }) as c_count
          FROM ${isUser ? 'users' : 'events'}
          WHERE ${isUser ? 'users.user_id' : 'events.user_id'} IN (${HavingConditionNegationCondition(
          groupCondition,
          havingCondition,
          negationCondition,
          group.operator,
          isSegment,
          isUser
        )})
          GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        ) as ${foo} ${groupFrequencyCondition}`;
      } else {
        return `
        SELECT user_id FROM (
        ${generateBaseQuery(isUser, isSegment, isItem, isSale)}
        WHERE ${groupCondition}
        GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        HAVING ${havingCondition}
        ) as ${foo} ${groupFrequencyCondition}`;
      }
    } else {
      if (negationCondition) {
        return `
          SELECT user_id FROM (
          SELECT ${isUser ? 'users.user_id' : 'events.user_id'}, COUNT(${
          isUser ? 'users.user_id' : 'events.user_id'
        }) as c_count
          FROM ${isUser ? 'users' : 'events'}
          WHERE ${isUser ? 'users.user_id' : 'events.user_id'} IN (${NotHavingConditionNegationCondition(
          groupCondition,
          negationCondition,
          group.operator,
          isSegment,
          isUser
        )})
          GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
          ) as ${foo} ${groupFrequencyCondition}`;
      } else {
        return `
        SELECT user_id FROM (
        ${generateBaseQuery(isUser, isSegment, isItem, isSale)}
        WHERE ${groupCondition}
        ${isUser || !salesEventTypeFilter ? '' : "AND events.event_type = 'sale'"} 
        GROUP BY ${isUser ? 'users.user_id' : 'events.user_id'}
        ) as ${foo} ${groupFrequencyCondition}`;
      }
    }
  });

  let query = '';

  const conditions = groupConditions.filter(condition => condition != null);
  parentTable = 'users';
  if (conditions.length == 1) {
    query = `(${conditions[0]})`;
  } else if (conditions.length > 1) {
    query = conditions.reduce((group_a, group_b) => {
      return `(${group_a} ${segmentOperator} ${group_b})`;
    });
  }

  const sql = `${query}`;
  const sqlWithHeaders = `SELECT DISTINCT ${parentTable}.user_id as user_id FROM ${parentTable} WHERE ${parentTable}.user_id IN ${query}`;

  const formattedSQL = format(sqlWithHeaders, {
    language: 'postgresql'
  });

  console.log(formattedSQL);

  return {
    sql,
    sqlWithHeaders,
    formattedSQL,
    groups,
    conditions
  };
};
