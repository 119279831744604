import { s3Types } from '../../constants/types/s3';
import Vue from 'vue';

const { getPresignedURL, getFileS3ObjectUrl } = s3Types.actions;

export const mutations = {
  [getPresignedURL]: (state, { response }) => {
    const presignedURL = response.data.value;

    if (presignedURL) {
      Vue.set(state, 'presignedURL', presignedURL); //+ new Date().getTime() we cannot add stuff to a presigned url
    }
  },

  [getFileS3ObjectUrl]: (state, { response }) => {
    const fileS3ObjectUrl = response.data.value;

    if (fileS3ObjectUrl) {
      Vue.set(state, 'fileS3ObjectUrl', fileS3ObjectUrl);
    }
  }
};
