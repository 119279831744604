export const getDefaultState = () => {
  return {
    category: null,
    search: null,
    tabulatedSegmentUsers: [
      {
        hello: 'wdw'
      }
    ],
    product: {
      item_type: '',
      name: '',
      sku: '',
      barcode: '',
      description: '',
      brand: '',
      meta_category: '',
      category: '',
      sub_category: '',
      image_url: '',
      item_url: '',
      channel_name: null,
      price: 0,
      cost: 0,
      status: null,
      stock_qty: null,
      date_dim_id: 0,
      created_at: '',
      updated_at: '',
      // parent_items: [],
      // lineage: {},
      custom: {}
      // description_metadata: {}
    },
    abpp: [],
    segments: [
      {
        segmentName: '',
        affinity: ''
      }
    ]
  };
};
