import numeral from 'numeral';

/**
 * This method takes a number and converts it to a formatted version.
 *
 * @param {(string | number)} num - Number to format
 * @return {string} Formatted number eg. 120,000
 *
 * @example
 *     formatNumber(120000)
 */
export const formatNumber = num => {
  let x = String(Number(num));
  const format = x % 1 === 0 ? '0,0' : '0,0.00'; // Choose format based on whether the number is an integer or float
  return numeral(x).format(format);
};
