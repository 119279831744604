<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <sidebar-fixed-toggle-button />

    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template slot="links">
        <sidebar-item
          v-if="showItem('dashboard')"
          :link="{
            path: '/dashboard',
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            featureCodename: 'DASHBOARD',
            permissionCodename: 'DASHBOARD.VIEW'
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.products'),
            icon: 'tim-icons icon-bag-16'
          }"
        >
          <sidebar-item
            v-if="showItem('products')"
            :link="{
              path: '/products',
              name: $t('sidebar.allProducts'),
              featureCodename: 'PRODUCTS',
              permissionCodename: 'PRODUCTS.VIEW'
            }"
          />

          <sidebar-item
            v-if="showItem('filters')"
            :link="{
              path: '/filters',
              name: $t('sidebar.filters'),
              featureCodename: 'PRODUCTS_FILTERS',
              permissionCodename: 'PRODUCTS_FILTERS.VIEW'
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="showItem('customers')"
          :link="{
            path: '/customers',
            name: $t('sidebar.customers'),
            icon: 'tim-icons icon-user-run',
            featureCodename: 'CUSTOMERS',
            permissionCodename: 'CUSTOMERS.VIEW'
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.recommendations'),
            icon: 'tim-icons icon-atom'
          }"
        >
          <!-- <sidebar-item
            v-if="showItem('template-manager')"
            :link="{
              path: '/template-manager',
              name: $t('Template manager'),
            }"
          /> -->
          <sidebar-item
            v-if="showItem('recommendations')"
            :link="{
              path: '/recommendations',
              name: $t('sidebar.recommendations-email'),
              featureCodename: 'RECOMMENDATIONS.EMAIL',
              permissionCodename: 'RECOMMENDATIONS.EMAIL.VIEW'
            }"
          />
          <sidebar-item
            v-if="showItem('recommendations-analytics')"
            :link="{
              path: '/recommendations-analytics',
              name: $t('sidebar.recommendations-analytics'),
              featureCodename: 'RECOMMENDATIONS_ANALYTICS',
              permissionCodename: 'RECOMMENDATIONS_ANALYTICS.VIEW'
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.segments'),
            icon: 'tim-icons icon-components'
          }"
        >
          <sidebar-item
            v-if="showItem('segments')"
            :link="{
              path: '/segments',
              name: $t('sidebar.allSegments'),
              featureCodename: 'SEGMENTS',
              permissionCodename: 'SEGMENTS.VIEW'
            }"
          />
          <sidebar-item
            v-if="showItem('segment-create')"
            :link="{
              path: '/segment-create',
              name: $t('sidebar.createSegment'),
              featureCodename: 'SEGMENTS',
              permissionCodename: 'SEGMENTS.CREATE'
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="showItem('templates')"
          :link="{
            path: '/templates',
            name: $t('sidebar.templates'),
            icon: 'tim-icons icon-single-copy-04',
            featureCodename: 'TEMPLATES',
            permissionCodename: 'TEMPLATES.VIEW'
          }"
        />
        <sidebar-item
          :link="{
            name: $t('sidebar.marketingCampaigns'),
            icon: 'tim-icons icon-send'
          }"
        >
          <sidebar-item
            v-if="showItem('marketing-campaigns')"
            :link="{
              path: '/marketing-campaigns',
              name: $t('sidebar.allCampaigns'),
              featureCodename: 'CAMPAIGNS',
              permissionCodename: 'CAMPAIGNS.VIEW'
            }"
          />
          <sidebar-item
            v-if="showItem('new-campaign')"
            :link="{
              path: '/new-campaign',
              name: $t('sidebar.newCampaign'),
              featureCodename: 'CAMPAIGNS',
              permissionCodename: 'CAMPAIGNS.CREATE'
            }"
          />
          <sidebar-item
            v-if="showItem('marketing-automation')"
            :link="{
              path: '/marketing-automation',
              name: $t('sidebar.marketingAutomation'),
              featureCodename: 'CAMPAIGNS_AUTOMATION',
              permissionCodename: 'CAMPAIGNS_AUTOMATION.VIEW'
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.dataManager'),
            icon: 'tim-icons icon-upload'
          }"
        >
          <sidebar-item
            v-if="showItem('data-groups')"
            :link="{
              path: '/data-groups',
              name: $t('sidebar.datasetGroups'),
              featureCodename: 'DATASETS_GROUP',
              permissionCodename: 'DATASETS_GROUP.VIEW'
            }"
          />
          <sidebar-item
            v-if="showItem('data-upload')"
            :link="{
              path: '/data-upload',
              name: $t('sidebar.dataUpload'),
              icon: 'tim-icons icon-upload',
              featureCodename: 'DATA_UPLOAD',
              permissionCodename: 'DATA_UPLOAD.VIEW'
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: $t('sidebar.connectors'),
            icon: 'tim-icons icon-puzzle-10'
          }"
        >
          <sidebar-item
            v-if="showItem('connectors/shopify')"
            :link="{
              path: '/connectors/shopify',
              name: $t('sidebar.shopify'),
              featureCodename: 'CONNECTORS.SHOPIFY',
              permissionCodename: 'CONNECTORS.SHOPIFY.INSTALL'
            }"
          />
        </sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar />
      <router-view name="header" />

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { ZoomCenterTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';
import { tenantTypes } from '../../constants/types';
import { prevents } from '../../routes/router';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackground: 'orange' //vue|blue|orange|green|red|primary
    };
  },
  mounted() {
    this.initScrollbar();
  },
  computed: {
    ...mapGetters({
      metaParam: tenantTypes.getters.getMetaParam
    })
  },
  methods: {
    showItem: to => {
      const role = localStorage.getItem('role');
      if (prevents[role].includes(to)) {
        return false;
      } else {
        return true;
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }

  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
