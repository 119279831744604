import Vuex, { Store } from 'vuex';
import Vue from 'vue';
import { cloneDeep, forOwn } from 'lodash';
Vue.use(Vuex);

import { databaseChores } from './databaseChores';
import { segment } from './segment';
import { filter } from './filter';

import { customers } from './customers';
import { products } from './products';

import { tenant } from './tenant';
import { customerAnalytics } from './customerAnalytics';
import { productAnalytics } from './productAnalytics';
import { campaignAnalytics } from './campaignAnalytics';
import { dashboardAnalytics } from './dashboardAnalytics';
import { recommendation } from './recommendation';
import { recommendationAnalytics } from './recommendationAnalytics';
import { segmentAnalytics } from './segmentAnalytics';
import { template } from './template';
import { marketingAutomation } from './marketingAutomation';

import { email } from './email';

import { campaign } from './campaign';
import { dataset } from './dataset';
import { global } from './global';
import { settings } from './settings';

import { s3 } from './s3';

import storePlugins from '../plugins/storePlugins';

export const initialStoreModules = {
  databaseChores,
  segment,
  filter,
  email,
  campaign,
  dataset,
  products,
  tenant,
  customers,
  campaignAnalytics,
  customerAnalytics,
  productAnalytics,
  dashboardAnalytics,
  global,
  recommendation,
  recommendationAnalytics,
  segmentAnalytics,
  settings,
  s3,
  template,
  marketingAutomation
};

export default new Store({
  // this block of code was added in order to reset all vuex store if user change to another account
  plugins: [storePlugins],
  modules: cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetState(state) {
      forOwn(initialStoreModules, (value, key) => {
        state[key] = cloneDeep(value.state);
      });
    }
  }
});
