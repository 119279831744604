import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class StorageApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async getPresignedUrl(filename) {
    console.log(`Filename in the api service ${filename}`);
    try {
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}/presigned-upload-url?objectKey=${filename}`); // pass a param
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async putFileToS3(presignedS3Url, file, type) {
    try {
      return axios.put(presignedS3Url, file, {
        headers: {
          'Content-Type': type
        },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization;
            delete headers.common.Authorization;
            delete headers.put.Authorization;
            return data;
          }
        ]
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async getFileS3ObjectUrl(filename) {
    try {
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}/object-url?objectKey=${filename}`);
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
