export const getDefaultState = () => {
  return {
    usersCount: {
      population: 0,
      target: 0
    },
    customersDayVisits: [],
    segmentQuery: ''
  };
};
