import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const email = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getRenderedHtml: state => state.rendered_html
  },
  actions,
  mutations
};
