<template>
  <div></div>
</template>

<script>
import swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
export default {
  components: {},
  data() {
    return {
      title: 'Access denied',
      description: 'Please try again later or contact support'
    };
  },
  async mounted() {
    await swal.fire({
      title: this.title,
      text: this.description,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-danger btn-fill'
      },
      buttonsStyling: false
    });
    sessionStorage.removeItem('tenantId');
    await Auth.signOut();
    await this.$router.push('/login');
    window.location.reload();
  }
};
</script>
<style lang="scss">
.access-denied-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
