<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{
      active: isActive,
      disabled: accessControlStatus
    }"
    tag="li"
    v-if="shouldRender"
  >
    <a
      v-if="isMenu"
      class="sidebar-menu-item"
      :aria-expanded="!collapsed"
      data-toggle="collapse"
      @click.prevent="handleClick"
    >
      <template v-if="addLink">
        <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
        <span class="sidebar-normal"> {{ link.name }} <b class="caret" /> </span>
      </template>
      <template v-else>
        <i :class="link.icon" />
        <p>{{ link.name }} <b class="caret" /></p>
      </template>
    </a>

    <collapse-transition>
      <div v-if="$slots.default || isMenu" v-show="!collapsed" class="collapse show">
        <ul class="nav">
          <slot />
        </ul>
      </div>
    </collapse-transition>

    <slot v-if="children.length === 0 && !$slots.default && link.path" name="title">
      <component
        :is="elementType(link, false)"
        :to="link.path"
        :class="{ active: link.active }"
        :target="link.target"
        :href="link.path"
        @click.native.capture="handleClick"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <i :class="link.icon" />
          <p>{{ link.name }}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { globalTypes } from '../../constants/types';
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarItem',
  components: {
    CollapseTransition
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "If true, the item will be disabled and won't emit click events"
    },
    menu: {
      type: Boolean,
      default: false,
      description:
        "Whether the item is a menu. Most of the item it's not used and should be used only if you want to override the default behavior."
    },
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: []
        };
      },
      description: 'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
    }
  },
  data() {
    return {
      children: [],
      collapsed: true
    };
  },
  computed: {
    ...mapGetters({
      permissions: globalTypes.getters.getTenantUserPermissions,
      features: globalTypes.getters.getTenantFeatures
    }),
    shouldRender() {
      return (
        !this.features('DISABLED', this.link.featureCodename) &&
        !this.permissions('DISABLED', this.link.permissionCodename)
      );
    },
    accessControlStatus() {
      if (this.features('QUOTA_EXCEEDED', this.link.featureCodename)) return 'feature-quota-exceeded';
      if (this.features('UNSUBSCRIBED', this.link.featureCodename)) return 'feature-unsubscribed';
      if (this.permissions('UNAUTHORIZED', this.link.permissionCodename)) return 'permission-unauthorized';
      return null;
    },
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
    },
    linkPrefix() {
      if (this.link.name) {
        let words = this.link.name.split(' ');
        return words.map(word => word.substring(0, 1)).join('');
      } else {
        return null;
      }
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find(c => this.$route.path.startsWith(c.link.path));
        if (matchingRoute !== undefined) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  methods: {
    handleClick() {
      if (this.accessControlStatus) {
        // TODO: better implementation in the future using v-access-control
        event.preventDefault();
        event.stopPropagation();
        this.emitNotification(this.accessControlStatus);
      } else if (!this.accessControlStatus && this.isMenu) {
        this.collapseMenu();
      } else {
        this.linkClick();
      }
    },
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a';
      } else {
        return 'router-link';
      }
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join('');
    },
    linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    },
    emitNotification(status) {
      switch (status) {
        case 'feature-quota-exceeded':
          this.$notify({
            type: 'danger',
            message: 'Quota exceeded. Please upgrade your plan to continue using this feature.',
            icon: 'tim-icons icon-bell-55'
          });
          break;
        case 'feature-unsubscribed':
          this.$notify({
            type: 'danger',
            message: 'Subscription required. Please subscribe to continue using this feature.',
            icon: 'tim-icons icon-bell-55'
          });
          break;
        case 'permission-unauthorized':
          this.$notify({
            type: 'warning',
            message: 'You are not authorized to access this feature.',
            icon: 'tim-icons icon-bell-55'
          });
          break;
        default:
          this.$notify({
            type: 'danger',
            message: 'Unknown error. Please contact support.',
            icon: 'tim-icons icon-bell-55'
          });
      }
    }
  }
};
</script>
<style>
.sidebar-menu-item {
  cursor: pointer;
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
