/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (customerAnalyticsTypes.dispatchers.getCustomer, ...)
 */

export const dashboardAnalyticsTypes = {
  module: 'dashboardAnalytics',
  getters: {
    get getBusiness() {
      return `${dashboardAnalyticsTypes.module}/getBusiness`;
    },
    // get getSales() {
    //   return `${dashboardAnalyticsTypes.module}/getSales`
    // },
    get getOutlook() {
      return `${dashboardAnalyticsTypes.module}/getOutlook`;
    },
    get getAbp() {
      return `${dashboardAnalyticsTypes.module}/getAbp`;
    },
    get getAbpm() {
      return `${dashboardAnalyticsTypes.module}/getAbpm`;
    },

    // get getProfit() {
    //   return `${dashboardAnalyticsTypes.module}/getProfit`
    // },

    get getBestStores() {
      return `${dashboardAnalyticsTypes.module}/getBestStores`;
    },

    get getAvgVisits() {
      return `${dashboardAnalyticsTypes.module}/getAvgVisits`;
    },

    get getReturningCustomers() {
      return `${dashboardAnalyticsTypes.module}/getReturningCustomers`;
    },
    get getDayVisits() {
      return `${dashboardAnalyticsTypes.module}/getDayVisits`;
    },
    get getCampaigns() {
      return `${dashboardAnalyticsTypes.module}/getCampaigns`;
    }

    // get getChannels() {
    //   return `${dashboardAnalyticsTypes.module}/getChannels`
    // }
  },
  actions: {
    //Business
    setBusiness: 'SET_BUSINESS',

    //Outlook
    setOutlook: 'SET_OUTLOOK',

    //Charts
    //setSales: 'SET_SALES',

    //Numbers
    setAbp: 'SET_ABP',
    setAbpm: 'SET_ABPM',
    //setProfit: 'SET_PROFIT',
    setBestStores: 'SET_BEST_STORES',
    setAvgVisits: 'SET_AVG_VISITS',
    setReturningCustomers: 'SET_RETURNING_CUSTOMERS',
    setDayVisits: 'SET_DAY_VISITS',
    setCampaigns: 'SET_CAMPAIGNS'
    //setChannels: 'SET_CHANNELS'
  },
  dispatchers: {
    get setDayVisits() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setDayVisits}`;
    },
    get setBusiness() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setBusiness}`;
    },
    get setOutlook() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setOutlook}`;
    },
    get setAbp() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setAbp}`;
    },
    get setAbpm() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setAbpm}`;
    },
    // get setProfit() {
    //   return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setProfit}`
    // },
    get setBestStores() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setBestStores}`;
    },
    get setAvgVisits() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setAvgVisits}`;
    },
    get setReturningCustomers() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setReturningCustomers}`;
    },
    get setCampaigns() {
      return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setCampaigns}`;
    }
    // get setChannels() {
    //   return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setChannels}`
    // },
    // get setSales() {
    //   return `${dashboardAnalyticsTypes.module}/${dashboardAnalyticsTypes.actions.setSales}`
    // }
  }
};
