export const productsTypes = {
  module: 'products',
  getters: {
    get getProducts() {
      return `${productsTypes.module}/getProducts`;
    },
    get getSold() {
      return `${productsTypes.module}/getSold`;
    },
    get getCount() {
      return `${productsTypes.module}/getCount`;
    },
    get getFields() {
      return `${productsTypes.module}/getFields`;
    },
    get getFilters() {
      return `${productsTypes.module}/getFilters`;
    },
    get getSortSelectOptions() {
      return `${productsTypes.module}/getSortSelectOptions`;
    },
    get getSearch() {
      return `${productsTypes.module}/getSearch`;
    },
    get getSearchByField() {
      return `${productsTypes.module}/getSearchByField`;
    },
    get getSortSelectOptionId() {
      return `${productsTypes.module}/getSortSelectOptionId`;
    },
    get getPageCursor() {
      return `${productsTypes.module}/getPageCursor`;
    }
  },
  actions: {
    setProducts: 'SET_PRODUCTS',
    setCategory: 'SET_CATEGORY',
    setSearch: 'SET_SEARCH',
    setSearchByField: 'SET_SEARCH_BY_FIELD',
    setSortSelectOptionId: 'SET_SORT_SELECT_OPTION_ID',
    setPageCursor: 'SET_PAGE_CURSOR',
    flush: 'FLUSH',
    setOtherFields: 'SET_OTHER_FIELDS',
    updateFilterValue: 'UPDATE_FILTER_VALUE',
    getProductDetails: 'GET_PRODUCT_DETAILS',
    syncRouter: 'SYNC_ROUTER'
  },
  dispatchers: {
    get setProducts() {
      return `${productsTypes.module}/${productsTypes.actions.setProducts}`;
    },
    get setCategory() {
      return `${productsTypes.module}/${productsTypes.actions.setCategory}`;
    },
    get setSearch() {
      return `${productsTypes.module}/${productsTypes.actions.setSearch}`;
    },
    get setSearchByField() {
      return `${productsTypes.module}/${productsTypes.actions.setSearchByField}`;
    },
    get setSortSelectOptionId() {
      return `${productsTypes.module}/${productsTypes.actions.setSortSelectOptionId}`;
    },
    get setPageCursor() {
      return `${productsTypes.module}/${productsTypes.actions.setPageCursor}`;
    },
    get flush() {
      return `${productsTypes.module}/${productsTypes.actions.flush}`;
    },
    get setOtherFields() {
      return `${productsTypes.module}/${productsTypes.actions.setOtherFields}`;
    },
    get updateFilterValue() {
      return `${productsTypes.module}/${productsTypes.actions.updateFilterValue}`;
    },
    get getProductDetails() {
      return `${productsTypes.module}/${productsTypes.actions.getProductDetails}`;
    },
    get syncRouter() {
      return `${productsTypes.module}/${productsTypes.actions.syncRouter}`;
    }
  }
};
