import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

const monthNamesShort = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const customerAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getCustomer: state => state.customer,

    getCustomerDayVisits: state => state.customerDayVisits,

    getAvgBasketPrice: state => parseFloat(state.avgBasketPrice[0].average_basket_price).toFixed(1),

    getAvgBasketPriceMonth: state => {
      let data_label = [];
      let datasets = [];

      let data = state.avgBasketPriceMonth;

      for (let i of data) {
        i.month = monthNamesShort[parseInt(i.month)];
        data_label.push(`${i.month}-${i.year}`);
        datasets.push(parseInt(i.average_basket_price_month));
      }
      //let reformatted = data.map(function(data){data.month = monthNamesShort[parseInt(data.month)];return data})
      return [data_label, datasets];
    },

    getVisits: state => state.visits,

    getYearSpending: state => {
      if (state.yearSpending[0]) {
        return parseInt(state.yearSpending[0].spending_this_year);
      } else {
        return 0;
      }
    },

    getFavShops: state => state.favShops,

    getFavShopsName: state => state.favShops.map(el => el.location),

    getCsp: state => {
      return state.csp;
    },

    getSegments: state => state.segments,

    getEngagement: state => state.engagement,

    getRecommendations: state => state.recommendations,

    getTimeline: state => state.timeline
  },
  actions,
  mutations
};
