import { campaignAnalyticsTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const { setLiveCustomerCount, setLiveItemCount, loadDefaultCampaignAnalytics } = campaignAnalyticsTypes.actions;

export const actions = {
  [setLiveCustomerCount]: ({ commit }, { seg }) => {
    query({
      commit,
      api: $api.analytics.campaign.liveCustomerCount.get({
        segments: seg && seg.length > 0 ? `[${seg.join(',')}]` : null
      }),
      options: {
        mutation: setLiveCustomerCount
      }
    });
  },

  [setLiveItemCount]: ({ commit }, { includedFilters, excludedFilters }) => {
    query({
      commit,
      api: $api.analytics.campaign.liveItemCount.get({
        includedFilters: includedFilters && includedFilters.length > 0 ? `[${includedFilters.join(',')}]` : null,
        excludedFilters: excludedFilters && excludedFilters.length > 0 ? `[${excludedFilters.join(',')}]` : null
      }),
      options: {
        mutation: setLiveItemCount
      }
    });
  },

  [loadDefaultCampaignAnalytics]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.analytics.campaign.defaultCampaignAnalytics.get({}, id),
      options: {
        mutation: loadDefaultCampaignAnalytics
      }
    });
  }
};
