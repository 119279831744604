import { $api } from '../../services/api';
import { settingsTypes } from '../../constants/types';
import { query } from '../../utils/wrappers/query';

const { setSettings, updateCampaignDetails, getTenantSettings, setTenantSettings } = settingsTypes.actions;

export const actions = {
  [setSettings]: async ({ commit }) => {
    // TODO: rename as default-campaign-settings
    return query({
      commit,
      api: $api.settings.settings.get({ pathParams: {}, queryParams: {} }),
      options: {
        loader: setSettings,
        promise: false,
        mutation: setSettings
      }
    });
  }, // TODO: rename as default-campaign-settings
  [updateCampaignDetails]: async ({ commit }, { campaignDetails }) => {
    return query({
      commit,
      api: $api.settings.settings.post({
        campaignDetails
      }),
      options: {
        loader: updateCampaignDetails,
        promise: true
      }
    });
  },

  [getTenantSettings]: async ({ commit }) => {
    return query({
      commit,
      api: $api.settings.tenantSettings.get({ pathParams: {}, queryParams: {} }),
      options: {
        loader: setTenantSettings,
        promise: false,
        mutation: setTenantSettings
      }
    });
  },

  [setTenantSettings]: async ({ commit }, { payload }) => {
    return query({
      commit,
      api: $api.settings.tenantSettings.post(payload),
      options: {
        loader: setTenantSettings,
        promise: false
      }
    });
  }
};
