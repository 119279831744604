import { actions } from './actions';
import { mutations } from './mutations';

export const databaseChores = {
  namespaced: true,
  state: () => ({
    ping: null
  }),
  getters: {
    getPing: state => state.ping
  },
  actions,
  mutations
};
