export const getDefaultState = () => {
  return {
    status: {},
    // sales: [{}],
    outlook: {
      totalRecommendations: 0,
      users: 0,
      totalViewedProducts: 0
    },
    dayVisits: [],
    business: {
      bestSellerName: '',
      activeSegments: 0,
      activeCampaigns: 0,
      daysSinceDataUpload: 0
    },
    abp: 0,
    abpm: [{}],
    // profit: [
    //   {
    //     profit_margin: ''
    //   }
    // ],
    bestStores: [
      {
        best_store: '',
        visits: ''
      }
    ],
    avgVisits: [
      {
        avg: ''
      }
    ],
    returnings: [
      {
        avg: '',
        month: '',
        year: ''
      }
    ],
    campaigns: []
    // channels: [
    //   {
    //     zoho: '',
    //     mailchimp: '',
    //     website: ''
    //   }
    // ]
  };
};
