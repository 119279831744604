import { globalTypes } from '../../constants/types';
const { setError, setLoading } = globalTypes.actions;

// NOTES:
// If you want to return the promise in vue, set promise to true in options
// and return query({ ... }) in actions; don't forget to set loading status back
// to false. See example in Segments.vue

export const query = async query => {
  const { options, commit, api } = query;
  const { mutation, props = {}, promise = false } = options;

  commit(setLoading, { mutation, status: true }, { root: true });

  if (promise) {
    return api;
  }
  api
    .then(async response => {
      if (mutation) {
        await commit(mutation, { response, ...props });
      }
      commit(setLoading, { mutation, status: false }, { root: true });
    })
    .catch(async error => {
      if (error.response.status === 404) {
        window.location.href = '/not-found';
      }
      console.log('query_error', error);
      commit(setLoading, { mutation, status: false }, { root: true });

      await commit(setError, error.response, { root: true });
      console.log('Error at: ' + mutation + ' \n ' + error.message + ' ' + error.message);
    });
};
