export const segmentAnalyticsTypes = {
  module: 'segmentAnalytics',
  getters: {
    get getUsersCount() {
      return `${segmentAnalyticsTypes.module}/getUsersCount`;
    },
    get customersDayVisits() {
      return `${segmentAnalyticsTypes.module}/getCustomersDayVisits`;
    }
  },
  actions: {
    setSegmentQuery: 'SET_SEGMENT_QUERY',
    setSegmentOverview: 'SET_SEGMENT_OVERVIEW',
    setAbp: 'SET_ABP',
    setAsv: 'SET_ASV',
    setMostVisitedStores: 'SET_MOST_VISITED_STORES',
    setOtherSegments: 'SET_OTHER_SEGMENTS',
    setDemography: 'SET_DEMOGRAPHY',
    setCustomersDayVisits: 'SET_CUSTOMERS_DAY_VISITS'
  },
  dispatchers: {
    get setSegmentQuery() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setSegmentQuery}`;
    },
    get setCustomersDayVisits() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setCustomersDayVisits}`;
    },
    get setSegmentOverview() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setSegmentOverview}`;
    },
    get setAbp() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setAbp}`;
    },
    get setAsv() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setAsv}`;
    },
    get setMostVisitedStores() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setMostVisitedStores}`;
    },
    get setOtherSegments() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setOtherSegments}`;
    },
    get setDemography() {
      return `${segmentAnalyticsTypes.module}/${segmentAnalyticsTypes.actions.setDemography}`;
    }
  }
};
