import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class DatasetApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async getAll() {
    try {
      await this.setAuthHeaders();
      return axios.get(this.getUrl());
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async getDatasetMapping(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}/mapping`, { params: data });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async createDataset(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.post(this.getUrl(), data, {
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async delete() {}

  async getDatasetGroup() {
    try {
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}/group`);
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async createDatasetGroup(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.post(`${this.getUrl()}/group`, data, {
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async updateDatasetGroup(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.put(`${this.getUrl()}/group`, data, {
        headers: { 'Content-Type': 'application/json' }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
