import Vue from 'vue';
import { productsTypes } from '../../constants/types/products';
import { getDefaultState } from './state';

const {
  setProducts,
  setSearch,
  setSearchByField,
  setSortSelectOptionId,
  setPageCursor,
  setCategory,
  flush,
  setOtherFields,
  updateFilterValue
} = productsTypes.actions;

export const mutations = {
  [setCategory]: (state, { category }) => {
    Vue.set(state, 'category', category);
  },
  [setSearch]: (state, { search }) => {
    Vue.set(state, 'search', search);
  },
  [setSearchByField]: (state, { searchByField }) => {
    const fields = state.fields;
    const field = fields.find(field => field.value === searchByField);
    if (!field) {
      Vue.set(state, 'searchByField', state.fields[0].value);
    } else {
      Vue.set(state, 'searchByField', searchByField);
    }
  },
  [setSortSelectOptionId]: (state, { sortSelectOptionId }) => {
    Vue.set(state, 'sortSelectOptionId', sortSelectOptionId);
  },
  [setPageCursor]: (state, { cursor }) => {
    if (state.count == 0) Vue.set(state, 'pageCursor', 1);
    else Vue.set(state, 'pageCursor', cursor);
  },
  [flush]: state => {
    Object.assign(state, getDefaultState());
  },
  [setProducts]: (state, { response }) => {
    state.count = parseInt(`${response.data.value.count}0` / 12) || 0;
    Vue.set(state, 'products', []);
    Vue.set(state, 'products', response.data.value.products);
  },
  [setOtherFields]: (state, { response }) => {
    const otherFields = response.data.value;
    const defaultFields = state.fields;

    const otherFieldsFormatted = otherFields.map(field => {
      return { label: field, value: field };
    });

    const fields = [...defaultFields, ...otherFieldsFormatted];

    Vue.set(state, 'fields', fields);
  },
  [updateFilterValue]: (state, { field, value }) => {
    Vue.set(state.filters, field, value);
  }
};
