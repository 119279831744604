import { $api } from '../../services/api';
import { tenantTypes } from '../../constants/types/tenant';
import { query } from '../../utils/wrappers/query';

const { getTenant, getMetaParam, setMetaParam, setMailingSyncCredentials, setMailingSyncCredential } =
  tenantTypes.actions;

export const actions = {
  [setMailingSyncCredential]: async ({ commit }, { credentials }) => {
    return query({
      commit,
      api: $api.tenant.info.put({ credentials }, '/mailing-sync'),
      options: {
        mutation: setMailingSyncCredential,
        promise: true
      }
    });
  },

  [setMailingSyncCredentials]: async ({ commit }) => {
    return query({
      commit,
      api: $api.tenant.info.get({}, 'mailing-sync'),
      options: {
        mutation: setMailingSyncCredentials,
        promise: true
      }
    });
  },

  [getTenant]: async ({ commit }) => {
    return query({
      commit,
      api: $api.tenant.info.get(),
      options: {
        mutation: getTenant
      }
    });
  },

  [getMetaParam]: async ({ commit }, { type }) => {
    return query({
      commit,
      api: $api.tenant.meta.get({ type }),
      options: {
        mutation: getMetaParam
      }
    });
  },

  [setMetaParam]: async ({ commit }, { params }) => {
    commit(setMetaParam, { params }, { root: true });
  }
};
