import Vue from 'vue';
import { segmentAnalyticsTypes } from '../../constants/types';

const { setSegmentQuery, setCustomersDayVisits, setSegmentOverview } = segmentAnalyticsTypes.actions;

export const mutations = {
  [setSegmentQuery]: (state, { segmentQuery }) => {
    Vue.set(state, 'segmentQuery', segmentQuery);
  },

  [setSegmentOverview]: (state, { response }) => {
    const { data } = response;
    Vue.set(state, 'usersCount', {
      population: data.value.population ? Number(data.value.population.count) : 0,
      target: data.value.target ? Number(data.value.target.count) : 0
    });
  },

  [setCustomersDayVisits]: (state, { response }) => {
    Vue.set(state, 'customersDayVisits', response.data.value);
  }
};
