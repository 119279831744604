import { $api } from '../../services/api';

import { recommendationTypes } from '../../constants/types';

import { query } from '../../utils/wrappers/query';

const {
  setRecommendations,
  rateRecommendation,
  commentRecommendation,
  getBatchTimeStamps,
  setFbt,
  addTemplate,
  flushTemplates
} = recommendationTypes.actions;

export const actions = {
  [setFbt]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.recommendation.fbt.post({
        item_id: id,
        customer: null,
        month: null,
        year: null
      }),
      options: {
        mutation: setFbt
      }
    });
  },
  [setRecommendations]: async ({ commit }, { customerId, batchTimestamp, templateId }) => {
    return query({
      commit,
      api: $api.recommendation.document.get({ customerId, batchTimestamp, templateId }),
      options: {
        mutation: setRecommendations
      }
    });
  },
  [getBatchTimeStamps]: async ({ commit }, { customerId }) => {
    return query({
      commit,
      api: $api.recommendation.document.get({ customerId }, 'recommendation-batch-timestamps'),
      options: {
        mutation: getBatchTimeStamps
      }
    });
  },
  [rateRecommendation]: async ({ commit }, { customerId, rating }) => {
    return query({
      commit,
      api: $api.recommendation.rate.post({ customerId, rating }),
      options: {
        promise: true
      }
    });
  },
  [commentRecommendation]: async ({ commit }, { customerId, comment }) => {
    return query({
      commit,
      api: $api.recommendation.comment.post({ customerId, comment }),
      options: {
        promise: true
      }
    });
  },
  [addTemplate]: async ({ commit }, { index, product }) => {
    commit(addTemplate, { index, product });
  },
  [flushTemplates]: async ({ commit }) => {
    commit(flushTemplates);
  }
};
