import { productsTypes } from '../../constants/types/products';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const {
  flush,
  setProducts,
  setSearch,
  setSearchByField,
  setSortSelectOptionId,
  setPageCursor,
  setCategory,
  setOtherFields,
  updateFilterValue,
  getProductDetails,
  syncRouter
} = productsTypes.actions;

export const actions = {
  [flush]: async ({ commit }) => commit(flush),
  [setSearch]: async ({ commit }, props) => commit(setSearch, { search: props }),
  [setSearchByField]: async ({ commit }, props) => commit(setSearchByField, { searchByField: props }),
  [setSortSelectOptionId]: async ({ commit }, props) => commit(setSortSelectOptionId, { sortSelectOptionId: props }),
  [setPageCursor]: async ({ commit }, props) => commit(setPageCursor, { cursor: props }),
  [setCategory]: async ({ commit }, props) => commit(setCategory, props),
  [setOtherFields]: async ({ commit }) => {
    return query({
      commit,
      api: $api.products.setOtherFields.get({}),
      options: {
        mutation: setOtherFields
      }
    });
  },
  [setProducts]: async (
    { commit },
    { otherFilter, search, imageNotNull, includedFilterIds, excludedFilterIds, category, sortOption, cursor }
  ) => {
    return query({
      commit,
      api: $api.analytics.products.products.get({
        sold: otherFilter.sold,
        recommended: otherFilter.recommended,
        search,
        imageNotNull,
        includedFilterIds:
          includedFilterIds && includedFilterIds.length > 0 ? `[${includedFilterIds.join(',')}]` : null,
        excludedFilterIds:
          excludedFilterIds && excludedFilterIds.length > 0 ? `[${excludedFilterIds.join(',')}]` : null,
        category,
        sortOption,
        cursor
      }),
      options: {
        mutation: setProducts
      }
    });
  },
  [updateFilterValue]: async ({ commit }, { field, value }) => commit(updateFilterValue, { field, value }),
  [getProductDetails]: async ({ commit }, { id }) => {
    const config = {
      body: {
        item_id: id,
        customer: null,
        month: null,
        year: null
      },

      headers: {
        'Content-Type': 'application/json'
      }
    };
    return query({
      commit,
      api: $api.analytics.product.info.post(config),
      options: {
        mutation: getProductDetails,
        promise: true
      }
    });
  },
  // TODO: REFACTOR make params and state agnostic
  [syncRouter]: async ({ commit }, { queryParams }) => {
    commit(setSearch, { search: queryParams.search || '' });
    commit(setSearchByField, { searchByField: queryParams.searchBy || 'name' });
    commit(setSortSelectOptionId, { sortSelectOptionId: queryParams.sortSelectOption || '' });
    commit(updateFilterValue, {
      field: 'includedFilters',
      value: queryParams.includedFilters ? queryParams.includedFilters.split(',').map(Number) : []
    });
    commit(updateFilterValue, {
      field: 'excludedFilters',
      value: queryParams.excludedFilters ? queryParams.excludedFilters.split(',').map(Number) : []
    });
    commit(setPageCursor, { cursor: queryParams.page || 1 });
  }
};
