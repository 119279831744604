import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const campaign = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getCampaignDetails(state) {
      return state.campaignDetails;
    },
    getCampaignDetailsA(state) {
      return state.groupA;
    },
    getCampaignDetailsB(state) {
      return state.groupB;
    },
    getCampaigns: state => state.campaigns,
    getStatus: state => objectName => state.status[objectName].isLoading,
    getFile: state => state.file,
    getCheckDefaultCampaignSettings: state => state.checkDefaultCampaignSettings
  },
  actions,
  mutations
};
