import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';
import { globalTypes } from '../../constants/types';

const { setTenantFeatures, setTenantUserPermissions, setTenantOnboardingStatus } = globalTypes.actions;

export const actions = {
  // saveError: {
  //   root: true,
  //   handler({ commit }, type, message, location) {
  //     commit(setError, type, message, location, {
  //       root: true
  //     });
  //   }
  // }
  [setTenantFeatures]: async ({ commit }) => {
    return query({
      commit,
      api: $api.tenant.features.get(),
      options: {
        loader: setTenantFeatures,
        promise: true,
        mutation: setTenantFeatures
      }
    });
  },

  [setTenantUserPermissions]: async ({ commit }) => {
    return query({
      commit,
      api: $api.tenantUser.permissions.get(),
      options: {
        loader: setTenantUserPermissions,
        promise: true,
        mutation: setTenantUserPermissions
      }
    });
  },
  [setTenantOnboardingStatus]: async ({ commit }) => {
    const response = await query({
      commit,
      api: $api.onboarding.get('/status'),
      options: {
        loader: setTenantOnboardingStatus,
        promise: true,
        mutation: setTenantOnboardingStatus
      }
    });
    commit(setTenantOnboardingStatus, { response });
    return response;
  }
};
