export const getDefaultState = () => {
  return {
    status: {},
    customers: [],
    count: 0,
    fields: [
      { value: 'full_name', label: 'Full Name' },
      { value: 'name', label: 'Name' },
      { value: 'surname', label: 'Surname' },
      { value: 'user_id', label: 'Customer ID' },
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Phone' },
      { value: 'zipcode', label: 'Zipcode' },
      { value: 'address', label: 'Address' },
      { value: 'gender', label: 'Gender' },
      { value: 'birthdate', label: 'Birthdate' }
    ]
  };
};
