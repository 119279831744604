import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class OnboardingApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(pathName = '/', params = {}) {
    try {
      const { pathParams = {}, queryParams = {} } = params;

      let path = '';
      if (pathParams) {
        Object.keys(pathParams).forEach(key => {
          path += `/${pathParams[key]}`;
        });
      }

      await this.setAuthHeaders();
      return axios.get(this.getUrl() + pathName + path, {
        params: queryParams
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
