import { $api } from '../../services/api';

import { recommendationAnalyticsTypes } from '../../constants/types/recommendationAnalytics';

import { query } from '../../utils/wrappers/query';

const {
  getBatches,
  getRecommendationCoverage,
  getBestSellingProducts,
  getTopRecommendedProducts,
  getRecommendationsCategories
} = recommendationAnalyticsTypes.actions;

export const actions = {
  [getBatches]: async ({ commit }) => {
    return query({
      commit,
      api: $api.recommendationAnalytics.batches.get(),
      options: {
        mutation: getBatches
      }
    });
  },
  [getRecommendationCoverage]: async ({ commit }, { recommendationId }) => {
    return query({
      commit,
      api: $api.recommendationAnalytics.document.get({ recommendationId }, 'coverage'),
      options: {
        mutation: getRecommendationCoverage
      }
    });
  },
  [getBestSellingProducts]: async ({ commit }) => {
    return query({
      commit,
      api: $api.recommendationAnalytics.document.get({}, 'best-sellers'),
      options: {
        mutation: getBestSellingProducts
      }
    });
  },
  [getTopRecommendedProducts]: async ({ commit }, { recommendationId }) => {
    return query({
      commit,
      api: $api.recommendationAnalytics.document.get({ recommendationId }, 'top-recommended'),
      options: {
        mutation: getTopRecommendedProducts
      }
    });
  },
  [getRecommendationsCategories]: async ({ commit }, { recommendationId }) => {
    return query({
      commit,
      api: $api.recommendationAnalytics.document.get({ recommendationId }, 'categories-count'),
      options: {
        mutation: getRecommendationsCategories
      }
    });
  }
};
