import { $api } from '../../services/api';

import { datasetTypes } from '../../constants/types/dataset';

import { query } from '../../utils/wrappers/query';

const {
  getDatasets,
  getDatasetMapping,
  createDataset,
  deleteDataset,
  getDatasetGroup,
  createDatasetGroup,
  updateDatasetGroup,
  clearNewDatasetGroup
} = datasetTypes.actions;

export const actions = {
  [getDatasets]: async ({ commit }) => {
    return query({
      commit,
      api: $api.dataset.document.getAll(),
      options: {
        mutation: getDatasets
      }
    });
  },
  [getDatasetMapping]: async ({ commit }, params) => {
    return query({
      commit,
      api: $api.dataset.document.getDatasetMapping(params),
      options: {
        promise: true
      }
    });
  },
  [createDataset]: async ({ commit }, params) => {
    console.log('createDataset params', params);
    return query({
      commit,
      api: $api.dataset.document.createDataset(params),
      options: {
        mutation: createDataset
      }
    });
  },
  [deleteDataset]: async ({ commit }) => {
    return query({
      commit,
      api: $api.dataset.document.delete()
    });
  },
  [getDatasetGroup]: async ({ commit }) => {
    return query({
      commit,
      api: $api.dataset.document.getDatasetGroup(),
      options: {
        mutation: getDatasetGroup
      }
    });
  },
  [createDatasetGroup]: async ({ commit }, params) => {
    return query({
      commit,
      api: $api.dataset.document.createDatasetGroup(params),
      options: {
        mutation: createDatasetGroup
      }
    });
  },
  [updateDatasetGroup]: async ({ commit }, params) => {
    return query({
      commit,
      api: $api.dataset.document.updateDatasetGroup(params),
      options: {
        promise: true
      }
    });
  },
  [clearNewDatasetGroup]: async ({ commit }) => {
    commit(clearNewDatasetGroup);
  }
};
