import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class RecommendationAnalyticsAPIService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(data = {}, path, formatted = false) {
    try {
      await this.setAuthHeaders();
      if (path) {
        return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}/${path}`, {
          // responseType: 'blob',
          params: {
            ...data
          }
        });
      }

      return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}`, {
        params: {
          ...data
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
