import { marketingAutomationTypes } from '../../constants/types';
import Vue from 'vue';

const { getAPIMarketingAutomationsDetails } = marketingAutomationTypes.actions;

export const mutations = {
  [getAPIMarketingAutomationsDetails]: (state, { response }) => {
    const marketingAutomations = [];
    for (const marketingAutomation of response.data.value) {
      marketingAutomations.push({
        id: marketingAutomation.id,
        title: marketingAutomation.title,
        description: marketingAutomation.description,
        active: marketingAutomation.active.toString()
      });
    }
    Vue.set(state, 'marketingAutomations', marketingAutomations);
  }
};
