export const segmentTypes = {
  module: 'segment',
  getters: {
    get getSqlQuery() {
      return `${segmentTypes.module}/getSqlQuery`;
    },
    get getSqlWithHeaders() {
      return `${segmentTypes.module}/getSqlWithHeaders`;
    },
    get getTempValue() {
      return `${segmentTypes.module}/getTempValue`;
    },
    get getFrequencyCheck() {
      return `${segmentTypes.module}/getFrequencyCheck`;
    },
    get getRecencyCheck() {
      return `${segmentTypes.module}/getRecencyCheck`;
    },
    get getSegmentOperator() {
      return `${segmentTypes.module}/getSegmentOperator`;
    },
    get getGroups() {
      return `${segmentTypes.module}/getGroups`;
    },
    get getFields() {
      return `${segmentTypes.module}/getFields`;
    },
    get getConditions() {
      return `${segmentTypes.module}/getConditions`;
    },
    get getIsEditing() {
      return `${segmentTypes.module}/getIsEditing`;
    },
    get getCurrentlyEditing() {
      return `${segmentTypes.module}/getCurrentlyEditing`;
    },
    get getSegments() {
      return `${segmentTypes.module}/getSegments`;
    },
    get getStatus() {
      return `${segmentTypes.module}/getStatus`;
    },
    get getSelects() {
      return `${segmentTypes.module}/getSelects`;
    },
    get getCurrentGroup() {
      return `${segmentTypes.module}/getCurrentGroup`;
    },
    get getCurrentTable() {
      return `${segmentTypes.module}/getCurrentTable`;
    },
    get getGeneralInformation() {
      return `${segmentTypes.module}/getGeneralInformation`;
    },
    get getSegmentName() {
      return `${segmentTypes.module}/getSegmentName`;
    },
    get getDescription() {
      return `${segmentTypes.module}/getDescription`;
    },
    get getSegmentInfo() {
      return `${segmentTypes.module}/getSegmentInfo`;
    },
    get getCount() {
      return `${segmentTypes.module}/getCount`;
    },
    get getBestSellersWinning() {
      return `${segmentTypes.module}/getBestSellersWinning`;
    },
    get getBestSellersLosing() {
      return `${segmentTypes.module}/getBestSellersLosing`;
    }
  },
  actions: {
    setGroup: 'SET_GROUP',
    unsetGroup: 'UNSET_GROUP',
    setGroupOperator: 'SET_GROUP_OPERATOR',
    setField: 'SET_FIELD',
    unsetField: 'UNSET_FIELD',
    setSegmentOperator: 'SET_SEGMENT_OPERATOR',
    setSegment: 'SET_SEGMENT',
    setNegation: 'SET_NEGATION',
    setGroupEditMode: 'SET_GROUP_EDIT_MODE',
    setGroupConditions: 'SET_GROUP_CONDITIONS',
    setCurrentlyEditing: 'TOGGLE_IS_EDITING',
    resetEditing: 'RESET_EDITING',
    loadSegment: 'LOAD_SEGMENT',
    populateFields: 'POPULATE_FIELDS',
    getSegments: 'GET_SEGMENTS',
    // deleteSegment: 'DELETE_SEGMENT',
    flushSegment: 'FLUSH_SEGMENT',
    getCampaignSegments: 'GET_CAMPAIGN_SEGMENTS',
    populateValues: 'POPULATE_VALUES',
    populateOtherFields: 'POPULATE_OTHER_FIELDS',
    setParams: 'SET_PARAMS',
    setMonth: 'SET_MONTH',
    setCustomersCount: 'SET_CUSTOMERS_COUNT',
    setBestSellersWinning: 'SET_BEST_SELLERS_WINNING',
    setBestSellersLosing: 'SET_BEST_SELLERS_LOSING',
    setFrequencyCheck: 'SET_FREQUENCY_CHECK',
    setRecencyCheck: 'SET_RECENCY_CHECK',
    loadFormattedSegment: 'LOAD_FORMATTED_SEGMENT',
    setSegmentChoice: 'SET_SEGMENT_CHOICE',
    // Segment analytics
    setUsersCount: 'SET_USERS_COUNT',
    setAbp: 'SET_ABP',
    setAsv: 'SET_ASV',
    setMostVisitedStores: 'SET_MOST_VISITED_STORES',
    setOtherSegments: 'SET_OTHER_SEGMENTS',
    setDemography: 'SET_DEMOGRAPHY',
    // end
    setTempValue: 'SET_TEMP_VALUE',
    setGroupValue: 'SET_GROUP_VALUE',
    setSegmentStatus: 'SET_SEGMENT_STATUS',
    rePopulateSegment: 'RE_POPULATE_SEGMENT'
  },
  dispatchers: {
    get setGroupValue() {
      return `${segmentTypes.module}/${segmentTypes.actions.setGroupValue}`;
    },
    get loadFormattedSegment() {
      return `${segmentTypes.module}/${segmentTypes.actions.loadFormattedSegment}`;
    },
    get setFrequencyCheck() {
      return `${segmentTypes.module}/${segmentTypes.actions.setFrequencyCheck}`;
    },
    get setRecencyCheck() {
      return `${segmentTypes.module}/${segmentTypes.actions.setRecencyCheck}`;
    },
    get setParams() {
      return `${segmentTypes.module}/${segmentTypes.actions.setParams}`;
    },
    get setCustomersCount() {
      return `${segmentTypes.module}/${segmentTypes.actions.setCustomersCount}`;
    },
    get setBestSellersWinning() {
      return `${segmentTypes.module}/${segmentTypes.actions.setBestSellersWinning}`;
    },
    get setBestSellersLosing() {
      return `${segmentTypes.module}/${segmentTypes.actions.setBestSellersLosing}`;
    },
    get setMonth() {
      return `${segmentTypes.module}/${segmentTypes.actions.setMonth}`;
    },
    get setGroup() {
      return `${segmentTypes.module}/${segmentTypes.actions.setGroup}`;
    },
    get unsetGroup() {
      return `${segmentTypes.module}/${segmentTypes.actions.unsetGroup}`;
    },
    get setGroupOperator() {
      return `${segmentTypes.module}/${segmentTypes.actions.setGroupOperator}`;
    },
    get setField() {
      return `${segmentTypes.module}/${segmentTypes.actions.setField}`;
    },
    get unsetField() {
      return `${segmentTypes.module}/${segmentTypes.actions.unsetField}`;
    },
    get setSegmentOperator() {
      return `${segmentTypes.module}/${segmentTypes.actions.setSegmentOperator}`;
    },
    get setSegment() {
      return `${segmentTypes.module}/${segmentTypes.actions.setSegment}`;
    },
    get setNegation() {
      return `${segmentTypes.module}/${segmentTypes.actions.setNegation}`;
    },
    get setGroupEditMode() {
      return `${segmentTypes.module}/${segmentTypes.actions.setGroupEditMode}`;
    },
    get setGroupConditions() {
      return `${segmentTypes.module}/${segmentTypes.actions.setGroupConditions}`;
    },
    get setCurrentlyEditing() {
      return `${segmentTypes.module}/${segmentTypes.actions.setCurrentlyEditing}`;
    },
    get resetEditing() {
      return `${segmentTypes.module}/${segmentTypes.actions.resetEditing}`;
    },
    get loadSegment() {
      return `${segmentTypes.module}/${segmentTypes.actions.loadSegment}`;
    },
    get populateFields() {
      return `${segmentTypes.module}/${segmentTypes.actions.populateFields}`;
    },
    get getSegments() {
      return `${segmentTypes.module}/${segmentTypes.actions.getSegments}`;
    },
    // get deleteSegment() {
    //   return `${segmentTypes.module}/${segmentTypes.actions.deleteSegment}`;
    // },
    get flushSegment() {
      return `${segmentTypes.module}/${segmentTypes.actions.flushSegment}`;
    },
    get getCampaignSegments() {
      return `${segmentTypes.module}/${segmentTypes.actions.getCampaignSegments}`;
    },
    get populateValues() {
      return `${segmentTypes.module}/${segmentTypes.actions.populateValues}`;
    },
    get populateOtherFields() {
      return `${segmentTypes.module}/${segmentTypes.actions.populateOtherFields}`;
    },
    get setSegmentChoice() {
      return `${segmentTypes.module}/${segmentTypes.actions.setSegmentChoice}`;
    },
    get setUsersCount() {
      return `${segmentTypes.module}/${segmentTypes.actions.setUsersCount}`;
    },
    get setAbp() {
      return `${segmentTypes.module}/${segmentTypes.actions.setAbp}`;
    },
    get setAsv() {
      return `${segmentTypes.module}/${segmentTypes.actions.setAsv}`;
    },
    get setMostVisitedStores() {
      return `${segmentTypes.module}/${segmentTypes.actions.setMostVisitedStores}`;
    },
    get setOtherSegments() {
      return `${segmentTypes.module}/${segmentTypes.actions.setOtherSegments}`;
    },
    get setDemography() {
      return `${segmentTypes.module}/${segmentTypes.actions.setDemography}`;
    },
    get setTempValue() {
      return `${segmentTypes.module}/${segmentTypes.actions.setTempValue}`;
    },
    get setSegmentStatus() {
      return `${segmentTypes.module}/${segmentTypes.actions.setSegmentStatus}`;
    },
    get rePopulateSegment() {
      return `${segmentTypes.module}/${segmentTypes.actions.rePopulateSegment}`;
    }
  }
};
