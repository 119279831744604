/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const marketingAutomationTypes = {
  module: 'marketingAutomation',
  getters: {
    get getMarketingAutomationsDetails() {
      return `${marketingAutomationTypes.module}/getMarketingAutomationsDetails`;
    }
  },
  actions: {
    getAPIMarketingAutomationsDetails: 'GET_API_MARKETING_AUTOMATIONS',
    createMarketingAutomation: 'CREATE_MARKETING_AUTOMATION',
    deleteMarketingAutomation: 'DELETE_MARKETING_AUTOMATION',
    activateMarketingAutomation: 'ACTIVATE_MARKETING_AUTOMATION'
  },
  dispatchers: {
    get getAPIMarketingAutomationsDetails() {
      return `${marketingAutomationTypes.module}/${marketingAutomationTypes.actions.getAPIMarketingAutomationsDetails}`;
    },
    get createMarketingAutomation() {
      return `${marketingAutomationTypes.module}/${marketingAutomationTypes.actions.createMarketingAutomation}`;
    },
    get deleteMarketingAutomation() {
      return `${marketingAutomationTypes.module}/${marketingAutomationTypes.actions.deleteMarketingAutomation}`;
    },
    get activateMarketingAutomation() {
      return `${marketingAutomationTypes.module}/${marketingAutomationTypes.actions.activateMarketingAutomation}`;
    }
  }
};
