import Vue from 'vue';
import { segmentTypes } from '../../constants/types';
import { getClauses } from '../../utils/segment';
import { getDefaultState } from './state';
import { formatNumber } from '../../utils/formatters/formatNumber';

const constants = {
  customer: [
    [
      { value: 'user_type', label: 'Customer Type', type: 'local' },
      { value: 'user_id', label: 'Customer Id', type: 'local' },
      { value: 'tenant_created_at', label: 'Created At', type: 'local' },
      { value: 'surname', label: 'Surname', type: 'local' },
      { value: 'sub_category', label: 'Sub Category', type: 'local' },
      { value: 'status', label: 'Status', type: 'local' },
      { value: 'shipping_state', label: 'Shipping State', type: 'local' },
      { value: 'shipping_postcode', label: 'Shipping Postcode', type: 'local' },
      { value: 'shipping_country', label: 'Shipping Country', type: 'local' },
      { value: 'shipping_city', label: 'Shipping City', type: 'local' },
      { value: 'shipping_address_line_2', label: 'Shipping Address Line 2', type: 'local' },
      { value: 'shipping_address_line_1', label: 'Shipping Address Line 1', type: 'local' },
      { value: 'phone', label: 'Phone', type: 'local' },
      { value: 'name', label: 'Name', type: 'local' },
      { value: 'meta_category', label: 'Meta Category', type: 'local' },
      { value: 'job_title', label: 'Job Title', type: 'local' },
      { value: 'gender', label: 'Gender', type: 'local' },
      { value: 'email_consented', label: 'Email Consented', type: 'local' },
      { value: 'email', label: 'Email', type: 'local' },
      { value: 'category', label: 'Category', type: 'local' },
      { value: 'birthdate', label: 'Birthdate', type: 'local' },
      { value: 'billing_state', label: 'Billing State', type: 'local' },
      { value: 'billing_postcode', label: 'Billing Postcode', type: 'local' },
      { value: 'billing_country', label: 'Billing Country', type: 'local' },
      { value: 'billing_city', label: 'Billing City', type: 'local' },
      { value: 'billing_address_line_2', label: 'Billing Address Line 2', type: 'local' },
      { value: 'billing_address_line_1', label: 'Billing Address Line 1', type: 'local' }
    ]
  ],
  product: [
    { value: 'item_id', label: 'Product ID', type: 'local' },
    { value: 'name', label: 'Name', type: 'local' },
    { value: 'sku', label: 'SKU', type: 'local' },
    { value: 'description', label: 'Description', type: 'local' },
    { value: 'brand', label: 'Brand', type: 'local' },
    { value: 'meta_category', label: 'Meta category', type: 'local' },
    { value: 'category', label: 'Category', type: 'local' },
    { value: 'sub_category', label: 'Sub category', type: 'local' },
    { value: 'image_url', label: 'Image url', type: 'local' },
    { value: 'item_url', label: 'Product url', type: 'local' },
    { value: 'price', label: 'Price', type: 'local' },
    { value: 'cost', label: 'Cost', type: 'local' },
    { value: 'tenant_created_at', label: 'Added Datetime', type: 'local' },
    { value: 'channel_name', label: 'Channel Name', type: 'local' },
    { value: 'status', label: 'Status', type: 'local' },
    { value: 'stock_qty', label: 'Stock Qty', type: 'local' }
  ],
  sale: [
    { value: 'event_id', label: 'Event Id', type: 'local' },
    { value: 'event_type', label: 'Event Type', type: 'local' },
    // { value: 'device', label: 'device', type: 'local' },
    // { value: 'geo', label: 'geo', type: 'local' },
    { value: 'transaction_id', label: 'Transaction Id', type: 'local' },
    // { value: 'session_id', label: 'session_id', type: 'local' },
    { value: 'event_value', label: 'Event Value', type: 'local' },
    { value: 'origin', label: 'Origin', type: 'local' },
    { value: 'channel_type', label: 'Channel Type', type: 'local' },
    { value: 'channel_name', label: 'Channel Name', type: 'local' },
    { value: 'event_dt', label: 'Event Date', type: 'local' },
    { value: 'item_id', label: 'Item Id', type: 'local' },
    { value: 'user_id', label: 'User Id', type: 'local' },
    // { value: 'campaign_id', label: 'campaign_id', type: 'local' },
    // { value: 'recommendation_id', label: 'Recommendation Id', type: 'local' },
    { value: 'transaction_revenue', label: 'Transaction Revenue', type: 'local' }
  ]
};

const {
  setGroup,
  unsetGroup,
  setField,
  unsetField,
  setSegmentOperator,
  setNegation,
  setGroupOperator,
  setGroupEditMode,
  setGroupConditions,
  setCurrentlyEditing,
  resetEditing,
  loadSegment,
  populateFields,
  getSegments,
  setLoadingObject,
  flushSegment,
  populateValues,
  populateOtherFields,
  setParams,
  setMonth,
  setCustomersCount,
  setBestSellersWinning,
  setBestSellersLosing,
  setFrequencyCheck,
  setRecencyCheck,
  setSegmentChoice,
  setTempValue,
  setGroupValue
} = segmentTypes.actions;

const unsetter = (keyName, object, id) => {
  let newObj = {};

  let newCount = Object.keys(object).length - 1;
  let currentCount = 0;

  Object.keys(object).map(objectId => {
    if (objectId !== id && currentCount !== newCount) {
      newObj[`${keyName}${currentCount}`] = { ...object[objectId] };
      currentCount += 1;
    }
  });

  return newObj;
};

export const mutations = {
  [setTempValue]: (state, { value }) => {
    Vue.set(state, 'tempValue', value);
  },

  [setSegmentChoice]: (state, { value, groupId }) => {
    const groups = { ...state.groups };
    groups[groupId].segmentChoice = value;
    Vue.set(state, 'groups', groups);
  },

  [setFrequencyCheck]: state => {
    const frequencyCheck = state.frequencyCheck;
    Vue.set(state, 'frequencyCheck', !frequencyCheck);
  },

  [setRecencyCheck]: state => {
    const recencyCheck = state.recencyCheck;
    Vue.set(state, 'recencyCheck', !recencyCheck);
  },

  [setCustomersCount]: (state, { response }) => {
    if (!response.data) return;

    const value = response.data.value;
    Vue.set(state, 'count', value);
  },

  [setBestSellersWinning]: (state, { response }) => {
    if (!response.data) return;

    const value = response.data.value;
    Vue.set(state, 'bestSellersWinning', value);
  },

  [setBestSellersLosing]: (state, { response }) => {
    if (!response.data) return;

    const value = response.data.value;
    Vue.set(state, 'bestSellersLosing', value);
  },

  [setMonth]: (state, { month, groupId }) => {
    const groups = { ...state.groups };
    groups[groupId].params.month = month;

    Vue.set(state, 'groups', groups);
  },

  [setParams]: (state, { type, status, groupId, params }) => {
    const defaultState = {
      operator: null,
      value: null
    };

    const groups = { ...state.groups };
    const group = groups[groupId];
    group[type] = status;

    if (status == false) {
      group.params[type] = defaultState;
    } else {
      group.params[type].operator = params.operator;
      group.params[type].value = params.value;
    }

    Vue.set(state, 'groups', groups);
  },

  [populateValues]: (state, { response }) => {
    const { data } = response;

    if (data.value) {
      Vue.set(state.selects.value, 'type', data.value.datatype);
      Vue.set(state.selects.value, 'options', Object.freeze(data.value.values));
    }
  },

  [getSegments]: (state, { response }) => {
    const segments = response.data.value.map(segment => ({
      ...segment,
      count: formatNumber(segment.count)
    }));
    Vue.set(state, 'segments', segments || null);
  },

  [setLoadingObject]: (state, { loader, status }) => {
    Vue.set(state.status[loader], 'isLoading', status);
  },

  [flushSegment]: state => {
    Object.assign(state, getDefaultState());
  },

  [setNegation]: (state, { groupId, fieldId }) => {
    const groups = Object.assign({}, state.groups);
    const group = groups[groupId];
    const field = group.fields[fieldId];

    field.negation = !field.negation;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'events', false);
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [setGroupOperator]: (state, { groupId, newOperator }) => {
    const groups = { ...state.groups };
    groups[groupId].operator = newOperator;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses(groups, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
    Vue.set(state, 'groups', groups);
  },

  [setSegmentOperator]: (state, { newOperator }) => {
    Vue.set(state, 'operator', newOperator);

    const { sql, sqlWithHeaders, formattedSQL } = getClauses(state.groups, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
  },

  [setGroup]: state => {
    const groupId = `GP${Object.keys(state.groups).length}`;

    const group = {
      operator: 'AND',
      fields: {}
    };

    Vue.set(state.groups, groupId, group);
  },

  [setGroupValue]: (state, { groupId, params }) => {
    const groups = { ...state.groups };

    groups[groupId] = {
      ...groups[groupId],
      ...params
    };

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [setField]: (state, { groupId, save = false, params }) => {
    const groups = { ...state.groups };
    const group = groups[groupId];
    const fieldId = save ? params.fieldId : `FD${Object.keys(group.fields).length}`;

    const field = {
      negation: false,
      table: params.table,
      field: params.field,
      operator: params.operator,
      value: params.value,
      frequency: params.frequency,
      recency: params.recency
    };

    group.fields[fieldId] = field;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [unsetGroup]: (state, { groupId }) => {
    const groups = { ...state.groups };
    delete groups[groupId];

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [unsetField]: (state, { groupId, fieldId }) => {
    const groups = { ...state.groups };

    const newObj = unsetter('FD', groups[groupId].fields, fieldId);
    groups[groupId].fields = newObj;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'events');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
    Vue.set(state, 'currentlyEditing', '');
    Vue.set(state, 'currentGroup', '');
    Vue.set(state, 'groups', groups);
  },

  [setGroupEditMode]: (state, { groupId }) => {
    Vue.set(state, 'currentlyEditing', '');
    Vue.set(state, 'currentGroup', groupId == state.currentGroup ? null : groupId);
  },

  [setCurrentlyEditing]: (state, { fieldId, groupId, table }) => {
    Vue.set(state, 'currentGroup', groupId);
    Vue.set(state, 'currentTable', table);
    state.currentlyEditing = fieldId;
  },

  [populateFields]: (state, { response }) => {
    // console.log(response.data.value.values.length);
    if (response.data) {
      const formatted = response.data.value.values.filter(item => {
        if (String(item.value).length > 0) {
          return item;
        }
      });

      Vue.set(state.selects.value, 'options', formatted);
    } else {
      Vue.set(state.selects.value, 'options', []);
    }
  },

  [populateOtherFields]: (state, { table, response }) => {
    let newList = [];
    if (response.data) {
      if (!response.data.value || response.data.value.length === 0) return;
      let list = response.data.value;
      if (list.length > 0) {
        list.forEach(value => {
          newList.push({
            label: value,
            value: value,
            type: 'defined'
          });
        });
      }

      Vue.set(state.selects.field.options, table, [...constants[table], ...newList]);
    }
  },

  [setGroupConditions]: (state, conditions) => {
    const newConditions = {
      table: conditions.table,
      field: conditions.field,
      operator: conditions.operator,
      value: conditions.value,
      frequencyOperator: conditions.frequency.operator,
      recencyOperator: conditions.frequency.operator,
      frequencyValue: conditions.recency.figure,
      recencyValue: conditions.recency.figure
    };
    Vue.set(state, 'conditions', newConditions);
  },

  [resetEditing]: state => {
    Vue.set(state, 'currentGroup', null);
    Vue.set(state, 'currentlyEditing', '');
  },

  [loadSegment]: (state, { response }) => {
    let segment = response.data.value;

    if (!segment) {
      window.location.pathname = '/segments';
    }

    const { segment_external_id, description, name, properties, sql_query } = segment;

    Vue.set(state, 'name', name);
    Vue.set(state, 'segment_external_id', segment_external_id);
    Vue.set(state, 'description', description);
    Vue.set(state, 'sql_query', sql_query);
    Vue.set(state, 'sql_query_with_parent', sql_query);
    Vue.set(state, 'operator', properties.operator);
    Vue.set(state, 'groups', properties.groups);
  }
};
