var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[(_vm.showItem('dashboard'))?_c('sidebar-item',{attrs:{"link":{
          path: '/dashboard',
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
          featureCodename: 'DASHBOARD',
          permissionCodename: 'DASHBOARD.VIEW'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.products'),
          icon: 'tim-icons icon-bag-16'
        }}},[(_vm.showItem('products'))?_c('sidebar-item',{attrs:{"link":{
            path: '/products',
            name: _vm.$t('sidebar.allProducts'),
            featureCodename: 'PRODUCTS',
            permissionCodename: 'PRODUCTS.VIEW'
          }}}):_vm._e(),(_vm.showItem('filters'))?_c('sidebar-item',{attrs:{"link":{
            path: '/filters',
            name: _vm.$t('sidebar.filters'),
            featureCodename: 'PRODUCTS_FILTERS',
            permissionCodename: 'PRODUCTS_FILTERS.VIEW'
          }}}):_vm._e()],1),(_vm.showItem('customers'))?_c('sidebar-item',{attrs:{"link":{
          path: '/customers',
          name: _vm.$t('sidebar.customers'),
          icon: 'tim-icons icon-user-run',
          featureCodename: 'CUSTOMERS',
          permissionCodename: 'CUSTOMERS.VIEW'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.recommendations'),
          icon: 'tim-icons icon-atom'
        }}},[(_vm.showItem('recommendations'))?_c('sidebar-item',{attrs:{"link":{
            path: '/recommendations',
            name: _vm.$t('sidebar.recommendations-email'),
            featureCodename: 'RECOMMENDATIONS.EMAIL',
            permissionCodename: 'RECOMMENDATIONS.EMAIL.VIEW'
          }}}):_vm._e(),(_vm.showItem('recommendations-analytics'))?_c('sidebar-item',{attrs:{"link":{
            path: '/recommendations-analytics',
            name: _vm.$t('sidebar.recommendations-analytics'),
            featureCodename: 'RECOMMENDATIONS_ANALYTICS',
            permissionCodename: 'RECOMMENDATIONS_ANALYTICS.VIEW'
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.segments'),
          icon: 'tim-icons icon-components'
        }}},[(_vm.showItem('segments'))?_c('sidebar-item',{attrs:{"link":{
            path: '/segments',
            name: _vm.$t('sidebar.allSegments'),
            featureCodename: 'SEGMENTS',
            permissionCodename: 'SEGMENTS.VIEW'
          }}}):_vm._e(),(_vm.showItem('segment-create'))?_c('sidebar-item',{attrs:{"link":{
            path: '/segment-create',
            name: _vm.$t('sidebar.createSegment'),
            featureCodename: 'SEGMENTS',
            permissionCodename: 'SEGMENTS.CREATE'
          }}}):_vm._e()],1),(_vm.showItem('templates'))?_c('sidebar-item',{attrs:{"link":{
          path: '/templates',
          name: _vm.$t('sidebar.templates'),
          icon: 'tim-icons icon-single-copy-04',
          featureCodename: 'TEMPLATES',
          permissionCodename: 'TEMPLATES.VIEW'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.marketingCampaigns'),
          icon: 'tim-icons icon-send'
        }}},[(_vm.showItem('marketing-campaigns'))?_c('sidebar-item',{attrs:{"link":{
            path: '/marketing-campaigns',
            name: _vm.$t('sidebar.allCampaigns'),
            featureCodename: 'CAMPAIGNS',
            permissionCodename: 'CAMPAIGNS.VIEW'
          }}}):_vm._e(),(_vm.showItem('new-campaign'))?_c('sidebar-item',{attrs:{"link":{
            path: '/new-campaign',
            name: _vm.$t('sidebar.newCampaign'),
            featureCodename: 'CAMPAIGNS',
            permissionCodename: 'CAMPAIGNS.CREATE'
          }}}):_vm._e(),(_vm.showItem('marketing-automation'))?_c('sidebar-item',{attrs:{"link":{
            path: '/marketing-automation',
            name: _vm.$t('sidebar.marketingAutomation'),
            featureCodename: 'CAMPAIGNS_AUTOMATION',
            permissionCodename: 'CAMPAIGNS_AUTOMATION.VIEW'
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dataManager'),
          icon: 'tim-icons icon-upload'
        }}},[(_vm.showItem('data-groups'))?_c('sidebar-item',{attrs:{"link":{
            path: '/data-groups',
            name: _vm.$t('sidebar.datasetGroups'),
            featureCodename: 'DATASETS_GROUP',
            permissionCodename: 'DATASETS_GROUP.VIEW'
          }}}):_vm._e(),(_vm.showItem('data-upload'))?_c('sidebar-item',{attrs:{"link":{
            path: '/data-upload',
            name: _vm.$t('sidebar.dataUpload'),
            icon: 'tim-icons icon-upload',
            featureCodename: 'DATA_UPLOAD',
            permissionCodename: 'DATA_UPLOAD.VIEW'
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.connectors'),
          icon: 'tim-icons icon-puzzle-10'
        }}},[(_vm.showItem('connectors/shopify'))?_c('sidebar-item',{attrs:{"link":{
            path: '/connectors/shopify',
            name: _vm.$t('sidebar.shopify'),
            featureCodename: 'CONNECTORS.SHOPIFY',
            permissionCodename: 'CONNECTORS.SHOPIFY.INSTALL'
          }}}):_vm._e()],1)],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }