import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const dataset = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getNewDatasetGroup: state => {
      return state.newDatasetGroup;
    },
    getDatasets: state => {
      return state.datasets;
    },
    getDatasetMapping: state => {
      return state.datasetMapping;
    },
    getDatasetGroup: state => {
      return state.datasetGroup;
    },
    getDatasetGroups: state => {
      return state.datasetGroups;
    }
  },
  actions,
  mutations
};
