import { $api } from '../../services/api';
import { segmentTypes } from '../../constants/types';
import { query } from '../../utils/wrappers/query';
import { CONSTANTS } from '../../constants/globals';

const {
  setGroup,
  unsetGroup,
  setField,
  unsetField,
  setSegmentOperator,
  setSegment,
  setGroupOperator,
  setNegation,
  setGroupEditMode,
  setGroupConditions,
  setCurrentlyEditing,
  resetEditing,
  loadSegment,
  populateFields,
  getSegments,
  // deleteSegment,
  getCampaignSegments,
  populateValues,
  populateOtherFields,
  setCustomersCount,
  setBestSellersWinning,
  setBestSellersLosing,
  loadFormattedSegment,
  setSegmentChoice,
  setUsersCount,
  setAbp,
  setAsv,
  setMostVisitedStores,
  setOtherSegments,
  setDemography,
  setTempValue,
  setGroupValue,
  setSegmentStatus,
  rePopulateSegment
} = segmentTypes.actions;

export const actions = {
  [setTempValue]: async ({ commit }, { value }) => {
    commit(setTempValue, { value });
  },

  [loadFormattedSegment]: async ({ commit }) => {
    return query({
      commit,
      api: $api.segment.formattedSegments.get(),
      options: {
        promise: true,
        mutation: loadFormattedSegment
      }
    });
  },

  [getSegments]: async ({ commit }, { search = null }) => {
    return query({
      commit,
      api: $api.segment.document.get({ search }),
      options: {
        mutation: getSegments
      }
    });
  },

  [getCampaignSegments]: async ({ commit }) => {
    return query({
      commit,
      api: $api.segment.document.get({ search: null }, null, true),
      options: {
        loader: 'segments',
        promise: true
      }
    });
  },

  [setGroup]: ({ commit }) => commit(setGroup),

  [unsetGroup]: ({ commit }, groupProps) => commit(unsetGroup, groupProps),

  [setGroupOperator]: ({ commit }, { segmentId, groupId, newOperator }) =>
    commit(setGroupOperator, {
      segmentId,
      groupId,
      newOperator
    }),

  [setGroupValue]: ({ commit }, props) => commit(setGroupValue, props),

  [setField]: ({ commit }, fieldProps) => commit(setField, fieldProps),
  [unsetField]: ({ commit }, props) => commit(unsetField, props),
  [setSegmentOperator]: ({ commit }, props) => commit(setSegmentOperator, props),

  [setCustomersCount]: ({ state, commit }) => {
    if (!state.sql_query_with_parent || state.sql_query == '') {
      state.count = 0;
      return;
    }
    return query({
      commit,
      api: $api.segment.document.post(
        {
          sql: state.sql_query_with_parent
        },
        'count'
      ),
      options: {
        promise: false,
        mutation: setCustomersCount
      }
    });
  },

  /**
   * Get the best sellers winning
   * @param {type} "items.category" | "events.user_id"
   */
  [setBestSellersWinning]: ({ state, commit }, { type, sortOption, soldAtLeast }) => {
    if (!state.sql_query_with_parent) return null;

    return query({
      commit,
      api: $api.segment.document.post(
        {
          sql: state.sql_query_with_parent,
          type,
          sortOption,
          soldAtLeast
        },
        'best-sellers/winning'
      ),
      options: {
        promise: false,
        mutation: setBestSellersWinning
      }
    });
  },

  /**
   * Get the best sellers winning
   * @param {type} "items.category" | "events.user_id"
   */
  [setBestSellersLosing]: ({ state, commit }, { type, sortOption, soldAtLeast }) => {
    if (!state.sql_query_with_parent) return null;

    return query({
      commit,
      api: $api.segment.document.post(
        {
          sql: state.sql_query_with_parent,
          type,
          sortOption,
          soldAtLeast
        },
        'best-sellers/losing'
      ),
      options: {
        promise: false,
        mutation: setBestSellersLosing
      }
    });
  },

  [setSegment]: ({ state, commit }, { name, description, update = false, id = null, segment_external_id = null }) => {
    const config = {
      properties: {
        name,
        description,
        segment_external_id,
        groups: state.groups,
        operator: state.operator,
        sql: state.sql_query_with_parent
      }
    };

    return query({
      commit,
      api: update ? $api.segment.document.update(config, id) : $api.segment.document.post(config),
      options: {
        promise: true,
        mutation: setSegment
      }
    });
  },

  [setNegation]: ({ commit }, props) => commit(setNegation, props),
  [setGroupEditMode]: ({ commit }, props) => commit(setGroupEditMode, props),
  [setGroupConditions]: ({ commit }, props) => commit(setGroupConditions, props),

  [populateValues]: ({ commit }, { table, field, local, search = null }) => {
    if (table && field) {
      query({
        commit,
        api: $api.segment.populate.get({}, `${table}/${field}/${local}` + `${search ? `?search=${search}` : ''}`),
        options: {
          mutation: populateValues
        }
      });
    }
  },

  [populateOtherFields]: ({ commit }, { table }) => {
    if (!table) return;
    query({
      commit,
      api: $api.segment.populateOtherFields.get({}, table),
      options: {
        mutation: populateOtherFields,
        props: {
          table
        }
      }
    });
  },

  [setCurrentlyEditing]: ({ commit }, { conditions, fieldId, groupId }) => {
    if (!conditions.table && !conditions.field) return;

    let local = false;

    if (conditions.table !== 'segment') {
      // Check if field is not of type "other json"
      local = CONSTANTS[conditions.table].includes(conditions.field);
    } else {
      local = true;
    }

    query({
      commit,
      api: $api.segment.populate.get({}, `${conditions.table}/${conditions.field}/${local}`),
      options: {
        mutation: populateFields
      }
    });

    commit(setCurrentlyEditing, { fieldId, groupId, table: conditions.table });
  },

  [resetEditing]: ({ commit }) => commit(resetEditing),

  [loadSegment]: ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.segment.document.get({}, id),
      options: {
        promise: false,
        mutation: loadSegment
      }
    });
  },

  // [deleteSegment]: async ({ commit }, { id }) => {
  //   return query({
  //     commit,
  //     api: $api.segment.document.delete({}, `${id}`),
  //     options: {
  //       loader: deleteSegment,
  //       promise: true
  //     }
  //   });
  // },

  [setSegmentChoice]: ({ commit }, { value, groupId }) => commit(setSegmentChoice, { value, groupId }),

  /** Segment analytics */

  /**
   * Set the population and target size for the overview card
   * @param {string} segmentId
   * @returns {query}
   */
  [setUsersCount]: ({ state, commit }) => {
    return query({
      commit,
      api: $api.analytics.segment.overview.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setUsersCount
      }
    });
  },

  /**
   * Get the average basket price in a segment
   * @param {string} segmentId
   * @returns {query}
   */
  [setAbp]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.segment.abp.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setAbp
      }
    });
  },

  /**
   * Get the average store visits for a segment
   * @param {string} segmentId
   * @returns {query}
   */
  [setAsv]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.segment.asv.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setAsv
      }
    });
  },

  /**
   * Most visited stores for the past 12 months
   * @param {string} segmentId
   * @returns {query}
   */
  [setMostVisitedStores]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.segment.mostVisitedStores.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setMostVisitedStores
      }
    });
  },

  /**
   * Other segments with customers in it
   * @param {string} segmentId
   * @returns {query}
   */
  [setOtherSegments]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.segment.setOtherSegments.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setOtherSegments
      }
    });
  },

  /**
   * Demographic data
   * @param {string} segmentId
   * @returns {query}
   */
  [setDemography]: ({ commit, state }) => {
    return query({
      commit,
      api: $api.analytics.segment.demography.post({
        sql: state.sql_query_with_parent
      }),
      options: {
        promise: true,
        mutation: setDemography
      }
    });
  },

  [setSegmentStatus]: async ({ commit }, { id, action }) => {
    commit = () => {};
    return query({
      commit,
      api: $api.segment.document.update({ action }, `${id}/status`),
      options: {
        promise: true
      }
    });
  },

  [rePopulateSegment]: async ({ commit }, { id }) => {
    commit = () => {};
    return query({
      commit,
      api: $api.segment.document.update({}, `${id}/re-populate`),
      options: {
        promise: true
      }
    });
  }
};
