import { globalTypes } from '../../constants/types';
import Vue from 'vue';
import { AbilityBuilder, Ability } from '@casl/ability';

const abilityBuilder = new AbilityBuilder(Ability);

const { setError, setLoading, setTenantInfo, setTenantFeatures, setTenantUserPermissions, setTenantOnboardingStatus } =
  globalTypes.actions;

export const mutations = {
  [setLoading]: (state, { mutation, status }) => {
    Vue.set(state.loaders, mutation, status);
  },

  [setError]: (state, error) => {
    console.log(error);
    state.error = {
      message: error.data.message
    };
  },

  [setTenantInfo]: (state, payload) => {
    Vue.set(state, 'tenant', payload);
  },

  [setTenantFeatures]: (state, { response }) => {
    const features = response.data.value;
    if (features) {
      for (const feature of features) {
        abilityBuilder.can(feature.status, feature.feature_code_name);
      }
      Vue.set(state, 'features', abilityBuilder.build());
      // state.ability.update(abilityBuilder.build());
      // console.log(abilityBuilder.build().can('subscribed', 'CUSTOMERS_ANALYTICS'));
    }
  },

  [setTenantUserPermissions]: (state, { response }) => {
    const permissions = response.data.value;
    if (permissions) {
      for (const permission of permissions) {
        abilityBuilder.can(permission.status, permission.permission_code_name);
      }
      Vue.set(state, 'permissions', abilityBuilder.build());
    }
  },

  [setTenantOnboardingStatus]: (state, { response }) => {
    const onboardingStatus = response.data.value;
    if (onboardingStatus) {
      Vue.set(state.tenant, 'onboarding_status', onboardingStatus.onboarding_status);
    }
  }
};
