import { $api } from '../../services/api';
import { query } from '../../utils/wrappers';
import { emailTypes } from '../../constants/types';

const { sendEmail, getEmailDetails } = emailTypes.actions;

export const actions = {
  [sendEmail]: async ({ commit }, { email, subject, html }) => {
    return query({
      commit,
      api: $api.email.document.post({ email, subject, html }),
      options: {
        promise: true
      }
    });
  },
  [getEmailDetails]: async ({ commit }, { user_id, id }) => {
    return query({
      commit,
      api: $api.email.document.get({ user_id, id }),
      options: {
        loader: getEmailDetails,
        promise: false,
        mutation: getEmailDetails
      }
    });
  }
};
