import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

const monthNamesShort = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const dashboardAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getOutlook: state => state.outlook,
    getBusiness: state => state.business,

    getDayVisits: state => state.dayVisits,

    // getSales: state => {
    //   const length = state.sales.length
    //   let data_label = []
    //   let datasets = []
    //   let data = state.sales

    //   if (length === 12) {
    //     //Year
    //     for (let i of data) {
    //       i.month = monthNamesShort[parseInt(i.month)]
    //       data_label.push(i.month)
    //       datasets.push(parseInt(i.sales))
    //     }
    //   } else if (length === 31) {
    //     //Month
    //     for (let i of data) {
    //       data_label.push(i.day)
    //       datasets.push(parseInt(i.sales))
    //     }
    //   } else {
    //     for (let i of data) {
    //       //Week
    //       i.day = dayNamesShort[parseInt(i.day)]
    //       data_label.push(i.day)
    //       datasets.push(parseInt(i.sales))
    //     }
    //   }

    //   return [data_label, datasets]
    // },

    getAbp: state => parseFloat(state.abp).toFixed(1),
    getAbpm: state => {
      let data_label = [];
      let datasets = [];

      let data = state.abpm;

      for (let i of data) {
        i.month = monthNamesShort[parseInt(i.month)];
        data_label.push(`${i.month}-${i.year}`);
        datasets.push(parseInt(i.average_basket_price));
      }

      return [data_label, datasets];
    },
    //getProfit: state => parseFloat(state.profit[0].profit_margin).toFixed(2),

    getBestStores: state => state.bestStores,

    getAvgVisits: state => parseFloat(state.avgVisits[0].average_visits).toFixed(1),
    getReturningCustomers: state => {
      let data_label = [];
      let datasets = [];

      let data = state.returnings;

      for (let i of data) {
        i.month = monthNamesShort[parseInt(i.month)];
        data_label.push(`${i.month}-${i.year}`);
        datasets.push(Math.round(i.avg * 100) / 100);
      }

      return [data_label, datasets];
    },
    getCampaigns: state => state.campaigns

    // getChannels: state => {
    //   let data = state.channels[0]

    //   let data_label = Object.keys(data)
    //   let datasets = Object.values(data)

    //   return [data_label, datasets]
    // }
  },
  actions,
  mutations
};
