import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class TenantUserApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(data = {}, path, formatted = false) {
    try {
      await this.setAuthHeaders();
      if (path) {
        console.log(`${this.getUrl()}${formatted ? '/format' : ''}/${path}`);
        return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}/${path}`, {
          // responseType: 'blob',
          params: {
            ...data
          }
        });
      }

      return axios.get(`${this.getUrl()}${formatted ? '/format' : ''}`, {
        params: {
          ...data
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async put(data, path) {
    try {
      await this.setAuthHeaders();
      return axios.put(this.getUrl() + path, data);
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
