<template>
  <div class="card" :class="[type && `card-${type}`]">
    <div v-if="$slots.image" class="card-image">
      <slot name="image" />
    </div>
    <div v-if="$slots.header || title" class="card-header" :class="headerClasses">
      <slot name="header">
        <h4 class="card-title">
          {{ title }}
        </h4>
        <p v-if="subTitle" class="card-category">
          {{ subTitle }}
        </p>
      </slot>
    </div>
    <div v-if="$slots.default" class="card-body" :class="bodyClasses">
      <slot />
    </div>
    <div v-if="$slots['image-bottom']" class="card-image">
      <slot name="image-bottom" />
    </div>
    <slot name="raw-content" />
    <div class="card-footer" :class="footerClasses" v-if="$slots.footer">
      <hr v-if="showFooterLine" />
      <slot name="footer" />
    </div>
    <div v-if="comingSoon" class="coming-soon-overlay">
      <span>Coming Soon</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'card',
  props: {
    title: {
      type: String,
      description: 'Card title'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle'
    },
    type: {
      type: String,
      description: 'Card type (e.g primary/danger etc)'
    },
    showFooterLine: {
      type: Boolean,
      default: false
    },
    headerClasses: {
      type: [String, Object, Array],
      description: 'Card header css classes'
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: 'Card body css classes'
    },
    footerClasses: {
      type: [String, Object, Array],
      description: 'Card footer css classes'
    },
    comingSoon: {
      type: Boolean,
      default: false,
      description: 'Show coming soon badge'
    }
  }
};
</script>
<style></style>
