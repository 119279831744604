/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */
export const datasetTypes = {
  module: 'dataset',
  getters: {
    get getDatasets() {
      return `${datasetTypes.module}/getDatasets`;
    },
    get getDatasetMapping() {
      return `${datasetTypes.module}/getDatasetMapping`;
    },
    get getNewDataset() {
      return `${datasetTypes.module}/getNewDataset`;
    },
    get getDatasetGroup() {
      return `${datasetTypes.module}/getDatasetGroup`;
    },
    get getNewDatasetGroup() {
      return `${datasetTypes.module}/getNewDatasetGroup`;
    }
  },
  actions: {
    getDatasets: 'GET_DATASETS',
    getDatasetMapping: 'GET_DATASET_MAPPING',
    createDataset: 'CREATE_DATASET',
    deleteDataset: 'DELETE_DATASET',
    flushDataset: 'FLUSH_DATASET',
    getDatasetGroup: 'GET_DATASET_GROUP',
    createDatasetGroup: 'CREATE_DATASET_GROUP',
    updateDatasetGroup: 'UPDATE_DATASET_GROUP',
    clearNewDatasetGroup: 'CLEAR_NEW_DATASET_GROUP'
  },
  dispatchers: {
    get getDatasets() {
      return `${datasetTypes.module}/${datasetTypes.actions.getDatasets}`;
    },
    get getDatasetMapping() {
      return `${datasetTypes.module}/${datasetTypes.actions.getDatasetMapping}`;
    },
    get createDataset() {
      return `${datasetTypes.module}/${datasetTypes.actions.createDataset}`;
    },
    get deleteDatasets() {
      return `${datasetTypes.module}/${datasetTypes.actions.deleteDataset}`;
    },
    get flushDataset() {
      return `${datasetTypes.module}/${datasetTypes.actions.flushCampaign}`;
    },
    get getDatasetGroup() {
      return `${datasetTypes.module}/${datasetTypes.actions.getDatasetGroup}`;
    },
    get createDatasetGroup() {
      return `${datasetTypes.module}/${datasetTypes.actions.createDatasetGroup}`;
    },
    get updateDatasetGroup() {
      return `${datasetTypes.module}/${datasetTypes.actions.updateDatasetGroup}`;
    },
    get clearNewDatasetGroup() {
      return `${datasetTypes.module}/${datasetTypes.actions.clearNewDatasetGroup}`;
    }
  }
};
