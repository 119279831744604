import { customerAnalyticsTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const {
  setTenantCustomer,
  setAvgBasketPrice,
  setAvgBasketPriceMonth,
  setVisits,
  setYearSpending,
  setFavShops,
  setCsp,
  setEngagement,
  setTimeline,
  setCustomerDayVisits,
  setSegments,
  setRecommendations,
  updateTenantCustomer
} = customerAnalyticsTypes.actions;

export const actions = {
  [setRecommendations]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.analytics.customer.recommendations.get(null, id),
      options: {
        mutation: setRecommendations
      }
    });
  },

  [setCustomerDayVisits]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.analytics.customer.customerDayVisits.get(null, id),
      options: {
        mutation: setCustomerDayVisits
      }
    });
  },

  [setTenantCustomer]: async ({ commit }, { id }) => {
    const body = {
      query: 'get_customer_data',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.info.post(body),
      options: {
        mutation: setTenantCustomer
      }
    });
  },

  [setAvgBasketPrice]: async ({ commit }, { id }) => {
    const body = {
      query: 'average_basket_price_customer',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.abp.post(body),
      options: {
        mutation: setAvgBasketPrice
      }
    });
  },

  [setAvgBasketPriceMonth]: async ({ commit }, { id, year }) => {
    const body = {
      query: 'average_basket_price_customer_month',
      product: null,
      user_id: id,
      month: null,
      year: year
    };

    return query({
      commit,
      api: $api.analytics.customer.abpm.post(body),
      options: {
        mutation: setAvgBasketPriceMonth
      }
    });
  },

  [setVisits]: async ({ commit }, { id }) => {
    const body = {
      query: 'visits',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.visits.post(body),
      options: {
        mutation: setVisits
      }
    });
  },

  [setYearSpending]: async ({ commit }, { id, year }) => {
    const body = {
      query: 'spending_this_year',
      product: null,
      user_id: id,
      month: null,
      year: year
    };

    return query({
      commit,
      api: $api.analytics.customer.yearSpending.post(body),
      options: {
        mutation: setYearSpending
      }
    });
  },

  [setFavShops]: async ({ commit }, { id }) => {
    const body = {
      query: 'favorite_shop',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.favShops.post(body),
      options: {
        mutation: setFavShops
      }
    });
  },

  [setCsp]: async ({ commit }, { id }) => {
    const body = {
      query: 'csp',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.csp.post(body),
      options: {
        mutation: setCsp
      }
    });
  },

  [setSegments]: async ({ commit }, { id }) => {
    const body = {
      query: 'segments',
      user_id: id
    };

    return query({
      commit,
      api: $api.analytics.customer.segments.post(body),
      options: {
        mutation: setSegments
      }
    });
  },

  [setEngagement]: async ({ commit }, { id }) => {
    const body = {
      query: 'engagement',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.engagement.post(body),
      options: {
        mutation: setEngagement
      }
    });
  },

  [setTimeline]: async ({ commit }, { id }) => {
    const body = {
      query: 'purchase_history',
      product: null,
      user_id: id,
      month: null,
      year: null
    };

    return query({
      commit,
      api: $api.analytics.customer.timeline.post(body),
      options: {
        mutation: setTimeline
      }
    });
  },

  [updateTenantCustomer]: async ({ commit }, { userDetails }) => {
    return query({
      commit,
      api: $api.analytics.customer.updateInfo.put(userDetails, userDetails.user_id),
      options: {
        mutation: updateTenantCustomer,
        promise: false,
        loader: updateTenantCustomer
      }
    });
  }
};
