export const getDefaultState = () => {
  return {
    liveCustomerCount: '',
    liveItemCount: '',
    defaultDodoMailCampaignAnalytics: {
      sent: 0,
      bounce: 0,
      delivery: 0,
      open: 0,
      click: 0,
      sale: 0,
      unsubscribe: 0,
      delivery_rate: 0,
      open_rate: 0,
      click_to_open_rate: 0,
      bounce_rate: 0,
      click_through_rate: 0,
      conversion_rate: 0
    },
    defaultWebsiteCampaignAnalytics: {
      total_sales_revenue: 0,
      impressions: 0,
      reach: 0,
      engagement: 0,
      sessions: 0,
      clicks: 0,
      add_to_cart: 0,
      sales: 0,
      cart_abandonment: 0,
      engagement_rate: 0,
      cart_abandonment_rate: 0,
      bounce_rate: 0,
      click_through_rate: 0,
      conversion_rate: 0
    }
  };
};
