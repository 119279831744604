import { campaignTypes } from '../../constants/types';
import { $api } from '../../services/api';

const {
  setCampaignDetails,
  updateCampaignValue,
  getAPIcampaigns,
  deleteCampaign,
  loadCampaign,
  updateCampaignValueA,
  updateCampaignValueB,
  updateSelectedDateAndTime,
  setCheckDefaultCampaignSettings
} = campaignTypes.actions;
import { query } from '../../utils/wrappers';

export const actions = {
  [deleteCampaign]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.campaign.document.delete({}, `${id}`),
      options: {
        loader: 'campaignDeleting',
        promise: true
      }
    });
  },

  // Get campaigns
  [getAPIcampaigns]: ({ commit }, { search = null }) => {
    query({
      commit,
      api: $api.campaign.document.get({ search }),
      options: {
        mutation: getAPIcampaigns
      }
    });
  },

  // Set Campaign Details
  [setCampaignDetails]: ({ state, commit }, { update = false, id = null, createOnly }) => {
    const {
      name,
      segments,
      includedFilters,
      excludedFilters,
      tags,
      channel,
      type,
      numericFilters,
      recommendations_id,
      server_url,
      absolutePositioningProductIds,
      template,
      ab,
      ab_objective,
      ab_distribution,
      manualType,
      fromEmailAddress,
      subject,
      mailchimpSender,
      zohoSender
    } = state.campaignDetails;

    const scheduledDateAndTime = state.scheduledDateAndTime;

    const groupA = state.groupA;
    const groupB = state.groupB;

    let config = {
      name,
      segmentIds: segments,
      includedFilterIds: includedFilters,
      excludedFilterIds: excludedFilters,
      tags,
      channel,
      type,
      numericFilters,
      recommendationId: recommendations_id,
      manualType,
      serverUrl: server_url,
      absolutePositioningProductIds,
      templateId: template.id,
      fromEmailAddress: channel === 'zoho' ? zohoSender : channel === 'mailchimp' ? mailchimpSender : fromEmailAddress,
      subject,
      createOnly,
      scheduledDateAndTime
    };

    if (ab) {
      config = {
        ...config,
        ab: {
          groupA,
          groupB,
          distribution: ab_distribution,
          objective: ab_objective
        }
      };
    }

    // Determine if campaign will be kept updated ( live campaigns )
    config.recommendationUpdateSchedule =
      recommendations_id.value == 'latest' && channel == 'website' ? recommendations_id.value : 'fixed';

    return query({
      commit,
      api: update ? $api.campaign.document.update(config, id) : $api.campaign.document.post(config),
      options: {
        mutation: setCampaignDetails,
        promise: true
      }
    });
  },

  // Update any campaign fields
  [updateCampaignValue]: ({ commit }, { field, value }) => {
    commit(updateCampaignValue, { field, value });
  },

  [updateCampaignValueA]: ({ commit }, { field, value }) => {
    commit(updateCampaignValueA, { field, value });
  },

  [updateCampaignValueB]: ({ commit }, { field, value }) => {
    commit(updateCampaignValueB, { field, value });
  },

  [loadCampaign]: ({ commit }, { id }) => {
    query({
      commit,
      api: $api.campaign.document.get({}, id, false),
      options: {
        mutation: loadCampaign
      }
    });
  },

  [updateSelectedDateAndTime]: ({ commit }, { date }) => {
    commit(updateSelectedDateAndTime, { date });
  },
  [setCheckDefaultCampaignSettings]: ({ commit }, { checked }) => {
    commit(setCheckDefaultCampaignSettings, { checked });
  }
};
