/**
 * This file contains all the types for autocompletion in Vue.
 *
 *
 * CONVENTION: dispatch -> action -> mutate
 * If you want to change the state, add your methods in mutations;
 * API calls etc.. will be in file actions.
 * Normal calls, such as modifying state, shall follow the convention of actions -> mutations.
 *
 * @params getters
 * @params dispatchers
 *
 * @example
 *     (segmentTypes.dispatchers.setField, ...)
 */

export const customersTypes = {
  module: 'customers',
  getters: {
    // Get single customer
    get getCustomer() {
      return `${customersTypes.module}/getCustomer`;
    },
    // Get all customers
    get getCustomers() {
      return `${customersTypes.module}/getCustomers`;
    },
    get getCount() {
      return `${customersTypes.module}/getCount`;
    },
    get getFields() {
      return `${customersTypes.module}/getFields`;
    }
  },
  actions: {
    setCustomer: 'SET_CUSTOMER',
    setCustomers: 'SET_CUSTOMERS',
    setOtherFields: 'SET_OTHER_FIELDS',
    getRandomCustomer: 'GET_RANDOM_CUSTOMER'
  },
  dispatchers: {
    get setCustomer() {
      return `${customersTypes.module}/${customersTypes.actions.setCustomer}`;
    },
    get setCustomers() {
      return `${customersTypes.module}/${customersTypes.actions.setCustomers}`;
    },
    get setOtherFields() {
      return `${customersTypes.module}/${customersTypes.actions.setOtherFields}`;
    },
    get getRandomCustomer() {
      return `${customersTypes.module}/${customersTypes.actions.getRandomCustomer}`;
    }
  }
};
