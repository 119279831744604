import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const recommendationAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getBatches: state => state.batches,
    getRecommendationId: state => state.recommendationId,
    getRecommendationCoverage: state => state.coverage,
    getBestSellingProducts: state => state.bestSellers,
    getTopRecommendedProducts: state => state.topRecommended,
    getRecommendationsCategories: state => state.recommendationsCategories
  },
  actions,
  mutations
};
