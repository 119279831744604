// ! Duplicated of state.js -_-
export const CONSTANTS = {
  customer: [
    'user_type',
    'user_id',
    'tenant_created_at',
    'surname',
    'sub_category',
    'status',
    'shipping_state',
    'shipping_postcode',
    'shipping_country',
    'shipping_city',
    'shipping_address_line_2',
    'shipping_address_line_1',
    'phone',
    'name',
    'meta_category',
    'job_title',
    'gender',
    'email_consented',
    'email',
    'category',
    'birthdate',
    'billing_state',
    'billing_postcode',
    'billing_country',
    'billing_city',
    'billing_address_line_2',
    'billing_address_line_1'
  ],
  product: [
    'item_id',
    'name',
    'sku',
    'description',
    'brand',
    'meta_category',
    'category',
    'sub_category',
    'image_url',
    'item_url',
    'price',
    'cost',
    'tenant_created_at',
    'channel_name',
    'status',
    'stock_qty'
  ],
  segment: ['name', 'id'],
  sale: ['channel_type', 'channel_name', 'event_dt', 'event_type', 'item_id'],
  email: ['delivery', 'open', 'click']
};
