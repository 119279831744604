import Vue from 'vue';
import { filterTypes } from '../../constants/types';
import { getClauses } from '../../utils/filter';
import { getDefaultState } from './state';
import { formatNumber } from '../../utils/formatters/formatNumber';

const constants = {
  product: [
    { value: 'item_id', label: 'Product ID', type: 'local' },
    { value: 'name', label: 'Name', type: 'local' },
    { value: 'sku', label: 'SKU', type: 'local' },
    { value: 'description', label: 'Description', type: 'local' },
    { value: 'brand', label: 'Brand', type: 'local' },
    { value: 'meta_category', label: 'Meta category', type: 'local' },
    { value: 'category', label: 'Category', type: 'local' },
    { value: 'sub_category', label: 'Sub category', type: 'local' },
    { value: 'image_url', label: 'Image url', type: 'local' },
    { value: 'item_url', label: 'Product url', type: 'local' },
    { value: 'price', label: 'Price', type: 'local' },
    { value: 'cost', label: 'Cost', type: 'local' },
    { value: 'tenant_created_at', label: 'Added Datetime', type: 'local' },
    { value: 'channel_name', label: 'Channel Name', type: 'local' },
    { value: 'status', label: 'Status', type: 'local' },
    { value: 'stock_qty', label: 'Stock Qty', type: 'local' }
  ]
};

const {
  setGroup,
  unsetGroup,
  setField,
  unsetField,
  setFilterOperator,
  setNegation,
  setGroupOperator,
  setGroupEditMode,
  setGroupConditions,
  setCurrentlyEditing,
  resetEditing,
  loadFilters,
  populateFields,
  getFilters,
  setLoadingObject,
  flushFilter,
  populateValues,
  populateOtherFields,
  setParams,
  setMonth,
  setProductsCount,
  setBestSellersWinning,
  // setBestSellersLosing,
  setFrequencyCheck,
  setRecencyCheck,
  setFiltersChoice,
  setTempValue,
  loadFilter,
  setGroupValue
} = filterTypes.actions;

const unsetter = (keyName, object, id) => {
  let newObj = {};

  let newCount = Object.keys(object).length - 1;
  let currentCount = 0;

  Object.keys(object).map(objectId => {
    if (objectId !== id && currentCount !== newCount) {
      newObj[`${keyName}${currentCount}`] = { ...object[objectId] };
      currentCount += 1;
    }
  });

  return newObj;
};

export const mutations = {
  [setTempValue]: (state, { value }) => {
    Vue.set(state, 'tempValue', value);
  },

  [loadFilter]: (state, { response }) => {
    let filter = response.data.value;

    if (!filter) {
      window.location.pathname = '/filters';
    }

    const { description, name, properties, sql_query } = filter;

    Vue.set(state, 'name', name);
    Vue.set(state, 'description', description);
    Vue.set(state, 'sql_query', sql_query);
    Vue.set(state, 'operator', properties.operator);
    Vue.set(state, 'groups', properties.groups);
  },

  [setFiltersChoice]: (state, { value, groupId }) => {
    const groups = { ...state.groups };
    groups[groupId].filterChoice = value;
    Vue.set(state, 'groups', groups);
  },

  [setFrequencyCheck]: state => {
    const frequencyCheck = state.frequencyCheck;
    Vue.set(state, 'frequencyCheck', !frequencyCheck);
  },

  [setRecencyCheck]: state => {
    const recencyCheck = state.recencyCheck;
    Vue.set(state, 'recencyCheck', !recencyCheck);
  },

  [setProductsCount]: (state, { response }) => {
    if (!response.data) return;

    const value = response.data.value;
    Vue.set(state, 'count', value);
  },

  [setBestSellersWinning]: (state, { response }) => {
    if (!response.data) return;

    const value = response.data.value;
    Vue.set(state, 'bestSellersWinning', value);
  },

  // [setBestSellersLosing]: (state, { response }) => {
  //   if (!response.data) return;

  //   const value = response.data.value;
  //   Vue.set(state, 'bestSellersLosing', value);
  // },

  [setMonth]: (state, { month, groupId }) => {
    const groups = { ...state.groups };
    groups[groupId].params.month = month;

    Vue.set(state, 'groups', groups);
  },

  [setParams]: (state, { type, status, groupId, params }) => {
    const defaultState = {
      operator: null,
      value: null
    };

    const groups = { ...state.groups };
    const group = groups[groupId];
    group[type] = status;

    if (status == false) {
      group.params[type] = defaultState;
    } else {
      group.params[type].operator = params.operator;
      group.params[type].value = params.value;
    }

    Vue.set(state, 'groups', groups);
  },

  [populateValues]: (state, { response }) => {
    const { data } = response;

    if (data.value) {
      Vue.set(state.selects.value, 'type', data.value.datatype);
      Vue.set(state.selects.value, 'options', Object.freeze(data.value.values));
    }
  },

  [getFilters]: (state, { response }) => {
    const filters = response.data.value;

    for (const key in filters) {
      filters[key] = filters[key].map(item => ({
        ...item,
        count: formatNumber(item.count)
      }));
    }

    Vue.set(state, 'filters', filters || null);
  },

  [setLoadingObject]: (state, { loader, status }) => {
    Vue.set(state.status[loader], 'isLoading', status);
  },

  [flushFilter]: state => {
    Object.assign(state, getDefaultState());
  },

  [setNegation]: (state, { groupId, fieldId }) => {
    const groups = Object.assign({}, state.groups);
    const group = groups[groupId];
    const field = group.fields[fieldId];

    field.negation = !field.negation;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'items', false);
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [setGroupOperator]: (state, { groupId, newOperator }) => {
    const groups = { ...state.groups };
    groups[groupId].operator = newOperator;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses(groups, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
    Vue.set(state, 'groups', groups);
  },

  [setFilterOperator]: (state, { newOperator }) => {
    Vue.set(state, 'operator', newOperator);

    const { sql, sqlWithHeaders, formattedSQL } = getClauses(state.groups, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
  },

  [setGroup]: state => {
    const groupId = `GP${Object.keys(state.groups).length}`;

    const group = {
      operator: 'AND',
      fields: {}
    };

    Vue.set(state.groups, groupId, group);
  },

  [setGroupValue]: (state, { groupId, params }) => {
    const groups = { ...state.groups };

    groups[groupId] = {
      ...groups[groupId],
      ...params
    };

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [setField]: (state, { groupId, save = false, params }) => {
    const groups = { ...state.groups };
    const group = groups[groupId];
    const fieldId = save ? params.fieldId : `FD${Object.keys(group.fields).length}`;

    const field = {
      negation: false,
      table: params.table,
      field: params.field,
      operator: params.operator,
      value: params.value,
      frequency: params.frequency,
      recency: params.recency
    };

    group.fields[fieldId] = field;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [unsetGroup]: (state, { groupId }) => {
    const groups = { ...state.groups };
    delete groups[groupId];

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);

    Vue.set(state, 'groups', groups);
  },

  [unsetField]: (state, { groupId, fieldId }) => {
    const groups = { ...state.groups };

    const newObj = unsetter('FD', groups[groupId].fields, fieldId);
    groups[groupId].fields = newObj;

    const { sql, sqlWithHeaders, formattedSQL } = getClauses({ ...groups }, state.operator, 'items');
    if (process.env.NODE_ENV === 'development') Vue.set(state, 'devSQL', formattedSQL);

    Vue.set(state, 'sql_query', sql);

    Vue.set(state, 'sql_query_with_parent', sqlWithHeaders);
    Vue.set(state, 'currentlyEditing', '');
    Vue.set(state, 'currentGroup', '');
    Vue.set(state, 'groups', groups);
  },

  [setGroupEditMode]: (state, { groupId }) => {
    Vue.set(state, 'currentlyEditing', '');
    Vue.set(state, 'currentGroup', groupId == state.currentGroup ? null : groupId);
  },

  [setCurrentlyEditing]: (state, { fieldId, groupId, table }) => {
    Vue.set(state, 'currentGroup', groupId);
    Vue.set(state, 'currentTable', table);
    state.currentlyEditing = fieldId;
  },

  [populateFields]: (state, { response }) => {
    if (response.data) {
      const formatted = response.data.value.values.filter(item => {
        if (String(item.value).length > 0) {
          return item;
        }
      });

      Vue.set(state.selects.value, 'options', formatted);
    } else {
      Vue.set(state.selects.value, 'options', []);
    }
  },

  [populateOtherFields]: (state, { table, response }) => {
    let newList = [];
    if (response.data) {
      if (!response.data.value || response.data.value.length === 0) return;
      let list = response.data.value;
      if (list.length > 0) {
        list.forEach(value => {
          newList.push({
            label: value,
            value: value,
            type: 'defined'
          });
        });
      }

      Vue.set(state.selects.field.options, table, [...constants[table], ...newList]);
    }
  },

  [setGroupConditions]: (state, conditions) => {
    const newConditions = {
      table: conditions.table,
      field: conditions.field,
      operator: conditions.operator,
      value: conditions.value,
      frequencyOperator: conditions.frequency.operator,
      recencyOperator: conditions.frequency.operator,
      frequencyValue: conditions.recency.figure,
      recencyValue: conditions.recency.figure
    };
    Vue.set(state, 'conditions', newConditions);
  },

  [resetEditing]: state => {
    Vue.set(state, 'currentGroup', null);
    Vue.set(state, 'currentlyEditing', '');
  },

  [loadFilters]: (state, { response }) => {
    let filter = response.data.value;

    if (!filter) {
      window.location.pathname = '/filters';
    }

    const { description, name, properties, sql_query } = filter;

    Vue.set(state, 'name', name);
    Vue.set(state, 'description', description);
    Vue.set(state, 'sql_query', sql_query);
    Vue.set(state, 'operator', properties.operator);
    Vue.set(state, 'groups', properties.groups);
  }
};
