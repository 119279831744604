<template>
  <div slot="footer" class="mt-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 v-if="leftLink">
        <a class="link footer-link font-weight-bold" :href="origin + leftLink.url">
          {{ leftLink.text }}
        </a>
      </h6>
      <h6 v-if="rightLink">
        <a class="link footer-link font-weight-bold" :href="origin + rightLink.url">
          {{ rightLink.text }}
        </a>
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormFooter',
  data() {
    return {
      origin: '',
      links: [
        {
          text: 'Sign Up',
          url: '/sign-up'
        },
        {
          text: 'Sign In',
          url: '/login'
        },
        {
          text: 'Forgot Password',
          url: '/forgot-password'
        }
      ]
    };
  },
  props: {
    leftSide: {
      type: String,
      default: ''
    },
    rightSide: {
      type: String,
      default: ''
    }
  },
  computed: {
    leftLink() {
      return this.links.find(link => link.text === this.leftSide);
    },
    rightLink() {
      return this.links.find(link => link.text === this.rightSide);
    }
  },
  created() {
    this.origin = window.location.origin;
  }
};
</script>