import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class TemplateApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(pathName = '/', params = {}) {
    try {
      const { pathParams = {}, queryParams = {} } = params;

      let path = '';
      if (pathParams) {
        Object.keys(pathParams).forEach(key => {
          path += `/${pathParams[key]}`;
        });
      }

      await this.setAuthHeaders();
      return axios.get(this.getUrl() + pathName + path, {
        params: queryParams
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async post(body) {
    try {
      await this.setAuthHeaders();
      return axios.post(`${this.getUrl()}`, body);
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async delete(path) {
    try {
      await this.setAuthHeaders();
      return axios.delete(`${this.getUrl()}${path}`);
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async upload(path, body) {
    try {
      await this.setAuthHeaders();

      const formData = new FormData();
      formData.append('image', body.image);
      formData.append('templateId', body.templateId);

      return axios.post(`${this.getUrl()}/${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          console.log('Upload Progress: ' + Math.round((progressEvent.loaded / progressEvent.total) * 100) + '%');
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }

  async duplicate(path, body) {
    try {
      await this.setAuthHeaders();
      return axios.post(`${this.getUrl()}/${path}`, body);
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
