export const getDefaultState = () => {
  return {
    status: {},
    segments: [],
    customerDayVisits: [],
    recommendations: [],
    customer: {},
    // [
    //   {
    //     customerid: '',
    //     name: '',
    //     surname: '',
    //     address: '',
    //     ville: '',
    //     email: '',
    //     phone: '',
    //     birthday: '',
    //     gender: ''
    //   }
    // ]
    avgBasketPrice: [
      {
        customerid: '',
        average_basket_price: ''
      }
    ],
    avgBasketPriceMonth: [
      {
        average_basket_price_month: '',
        customerid: '',
        month: '',
        year: ''
      }
    ],
    visits: [
      {
        number_of_visits: '',
        year: ''
      }
    ],
    yearSpending: [
      {
        spending_this_year: '',
        customerid: ''
      }
    ],
    favShops: [
      {
        customerid: '',
        location: '',
        store_count: ''
      }
    ],
    csp: {
      customerid: '',
      average_basket_price: '',
      csp: ''
    },
    engagement: [
      {
        number_of_visits: '',
        year: '',
        engagement: ''
      }
    ],
    timeline: [
      {
        orderdate: '',
        orderid: '',
        location: '',
        products: '[]'
      }
    ]
  };
};
