import { ReadOnlyApiService } from './ReadOnlyApiService';
import axios from 'axios';

export class ProductsApiService extends ReadOnlyApiService {
  constructor(route) {
    super(route);
  }

  async get(data = {}) {
    try {
      await this.setAuthHeaders();
      return axios.get(`${this.getUrl()}`, {
        params: {
          ...data
        }
      });
    } catch (err) {
      this.handleErrors(err);
    }
  }
}
