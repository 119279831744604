import { AnalyticsApiService } from './AnalyticsApiService';
import { CampaignApiService } from './CampaignApiService';
import { DatasetApiService } from './DatasetApiService';
import { ProductsApiService } from './ProductsApiService';
import { RecommendationApiService } from './RecommendationApiService';
import { RecommendationAnalyticsAPIService } from './RecommendationAnalyticsAPIService';
import { SegmentApiService } from './SegmentApiService';
import { FilterApiService } from './FilterApiService';
import { StorageApiService } from './StorageApiService';
import { TenantApiService } from './TenantApiService';
import { TenantUserApiService } from './TenantUserApiService';
import { EmailApiService } from './EmailApiService';
import { SegmentAnalyticsApiService } from './SegmentAnalyticsApiService';
import { TemplateApiService } from './TemplateAPIService';
import { MarketingAutomationApiService } from './MarketingAutomationApiService';
import { SettingsApiService } from './settingsApiService';
import { OnboardingApiService } from './onboardingApiService';

export const $api = {
  analytics: {
    database: {
      ping: new AnalyticsApiService('database/ping')
    },
    dashboard: {
      business: new AnalyticsApiService('dashboardAnalytics/business'),
      outlook: new AnalyticsApiService('dashboardAnalytics/outlook'),
      // salesYear: new AnalyticsApiService('dashboardAnalytics/salesYear'),
      // salesMonth: new AnalyticsApiService('dashboardAnalytics/salesMonth'),
      // salesWeek: new AnalyticsApiService('dashboardAnalytics/salesWeek'),
      abp: new AnalyticsApiService('dashboardAnalytics/abp'),
      abpm: new AnalyticsApiService('dashboardAnalytics/abpm'),
      // profit: new AnalyticsApiService('dashboardAnalytics/profit'),
      bestStores: new AnalyticsApiService('dashboardAnalytics/bestStores'),
      returnings: new AnalyticsApiService('dashboardAnalytics/returnings'),
      avgVisits: new AnalyticsApiService('dashboardAnalytics/avgVisits'),
      dayVisits: new AnalyticsApiService('dashboardAnalytics/day-sales'),
      // channels: new AnalyticsApiService('dashboardAnalytics/channels'),
      // sales: new AnalyticsApiService('dashboardAnalytics/sales'),
      campaigns: new AnalyticsApiService('dashboardAnalytics/campaigns')
    },
    customers: {
      customer: new AnalyticsApiService('customers/one'),
      customers: new AnalyticsApiService('customers/')
    },
    products: {
      products: new AnalyticsApiService('products/'),
      product: new AnalyticsApiService('products/product')
    },
    customer: {
      info: new AnalyticsApiService('customerAnalytics/info'),
      abp: new AnalyticsApiService('customerAnalytics/abp'),
      abpm: new AnalyticsApiService('customerAnalytics/abpm'),
      visits: new AnalyticsApiService('customerAnalytics/visits'),
      yearSpending: new AnalyticsApiService('customerAnalytics/spending'),
      favShops: new AnalyticsApiService('customerAnalytics/favShops'),
      csp: new AnalyticsApiService('customerAnalytics/csp'),
      segments: new AnalyticsApiService('customerAnalytics/segments'),
      engagement: new AnalyticsApiService('customerAnalytics/engagement'),
      recommendations: new AnalyticsApiService('customerAnalytics/recommendations'),
      timeline: new AnalyticsApiService('customerAnalytics/purchaseHistory'),
      customerDayVisits: new AnalyticsApiService('customerAnalytics/buys-the-most'),
      updateInfo: new AnalyticsApiService('customerAnalytics/update')
    },
    product: {
      topSegments: new AnalyticsApiService('productAnalytics/topSegments'),
      info: new AnalyticsApiService('productAnalytics/info'),
      abpp: new AnalyticsApiService('productAnalytics/abpp'),
      tabulateSegmentUsers: new AnalyticsApiService('productAnalytics/tabulateSegmentUsers'),
      updateProduct: new AnalyticsApiService('productAnalytics/update')
    },
    segment: {
      overview: new SegmentAnalyticsApiService('segment-analytics/overview'),
      abp: new SegmentAnalyticsApiService('segment-analytics/abp'),
      asv: new SegmentAnalyticsApiService('segment-analytics/asv'),
      mostVisitedStores: new SegmentAnalyticsApiService('segment-analytics/most-visited-stores'),
      setOtherSegments: new SegmentAnalyticsApiService('segment-analytics/other-segments'),
      demography: new SegmentAnalyticsApiService('segment-analytics/demography'),
      customersDayVisits: new SegmentAnalyticsApiService('segment-analytics/customers-day-visits')
    },
    campaign: {
      defaultCampaignAnalytics: new AnalyticsApiService('campaign-analytics/'),
      liveCustomerCount: new AnalyticsApiService('campaign-analytics/live-customer-count'),
      liveItemCount: new AnalyticsApiService('campaign-analytics/live-item-count')
    }
  },
  segment: {
    populate: new SegmentApiService('segment/populate'),
    populateOtherFields: new SegmentApiService('segment/populate-other-fields'),
    document: new SegmentApiService('segment'),
    formattedSegments: new SegmentApiService('segment/format')
  },
  filter: {
    populate: new FilterApiService('filter/populate'),
    populateOtherFields: new FilterApiService('filter/populate-other-fields'),
    document: new FilterApiService('filter'),
    formattedSegments: new FilterApiService('filter/format')
  },
  campaign: {
    document: new CampaignApiService('campaign')
  },
  products: {
    setOtherFields: new ProductsApiService('products/set-other-fields')
  },
  tenant: {
    info: new TenantApiService('tenant'),
    meta: new TenantApiService('tenant/meta'),
    features: new TenantApiService('tenant/features')
  },
  tenantUser: {
    permissions: new TenantUserApiService('tenant-user/permissions')
  },
  customers: {
    setOtherFields: new ProductsApiService('customers/set-other-fields'),
    pickCustomer: new ProductsApiService('customers/pick-customer')
  },
  recommendation: {
    document: new RecommendationApiService('recommendation/preview'),
    rate: new RecommendationApiService('recommendation/rate'),
    comment: new RecommendationApiService('recommendation/comment'),
    fbt: new RecommendationApiService('recommendation/fbt')
  },
  recommendationAnalytics: {
    document: new RecommendationAnalyticsAPIService('recommendation-analytics'),
    batches: new RecommendationAnalyticsAPIService('recommendation-analytics/batches')
  },
  dataset: {
    document: new DatasetApiService('dataset')
  },
  storage: {
    document: new StorageApiService('storage')
  },
  email: {
    document: new EmailApiService('email')
  },
  template: new TemplateApiService('template'),
  marketingAutomation: new MarketingAutomationApiService('marketing-automation'),
  settings: {
    // TODO: rename as default-campaign-settings
    settings: new SettingsApiService('settings'),
    tenantSettings: new SettingsApiService('settings/tenant-settings')
  },
  onboarding: new OnboardingApiService('onboarding')
};
