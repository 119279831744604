<template>
  <div>
    <notifications />
    <div v-if="updateExists">
      <div class="text-center update-container" bottom right color="primary">
        An update is available
        <button class="btn btn-default" @click="refreshApp"><i class="zmdi zmdi-refresh" /> Click to update!</button>
      </div>
    </div>
    <div v-else>
      <div v-if="isAuthenticated">
        <router-view />
      </div>
      <div v-else>
        <SignUpFormComp v-if="this.routePathName === '/sign-up'" />
        <ForgotPasswordFormComp v-else-if="this.routePathName === '/forgot-password'" />
        <CallbackComp v-else-if="this.routePathName === '/callback'" />
        <LoginFormComp @login="submit" :isLoading="isLoading" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from '@aws-amplify/auth';
import { mapGetters } from 'vuex';
import { globalTypes } from './constants/types';
import axios from 'axios';
import aws_exports from './aws-exports';
import update from './mixins/update';
import Notifications from './components/NotificationPlugin/Notifications.vue';
import LoginFormComp from './pages/Login/LoginForm.vue';
import SignUpFormComp from './pages/SignUp/SignUpForm.vue';
import ForgotPasswordFormComp from './pages/ForgotPassword/ForgotPasswordForm.vue';
import CallbackComp from './pages/Callback/index.vue';

axios.defaults.baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : aws_exports['env'] === 'staging'
    ? 'https://192.168.100.62:4000'
    : 'https://api.dodobird.ai';

export default {
  data() {
    return {
      isAuthenticated: false,
      isLoading: false,
      routePathName: ''
    };
  },

  components: {
    Notifications,
    LoginFormComp,
    SignUpFormComp,
    ForgotPasswordFormComp,
    CallbackComp
  },
  mixins: [update],

  computed: {
    ...mapGetters({
      getError: globalTypes.getters.getError
    })
  },
  async mounted() {
    this.routePathName = window.location.pathname;
    this.initializeLayout();
    await this.checkAuthState();

    // Add this event listener
    this.$eventBus.$on('showNotification', this.showNotification);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    this.$eventBus.$off('showNotification', this.showNotification);
  },
  updated() {
    this.routePathName = window.location.pathname;
  },
  methods: {
    showNotification(options) {
      this.$notify(options);
    },
    initializeLayout() {
      let white = localStorage.getItem('white') ? JSON.parse(localStorage.getItem('white')) : true;
      if (white) {
        this.$root.$children[0].toggleBodyClass('addClass', 'white-content');
      } else {
        this.$root.$children[0].toggleBodyClass('remClass', 'white-content');
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    async checkAuthState() {
      try {
        await Auth.currentAuthenticatedUser();
        this.isAuthenticated = true;
        await this.setupAuthenticatedState();
      } catch (err) {
        this.isAuthenticated = false;
      }
    },
    async setupAuthenticatedState() {
      const session = await Auth.currentSession();
      axios.defaults.headers.common['Authorization'] = `Bearer ${session.getIdToken().getJwtToken()}`;
      axios.defaults.headers.common['X-Tenant-ID'] = sessionStorage.getItem('tenantId');
      await this.fetchTenant();
    },
    async fetchTenant() {
      const token = (await Auth.currentSession()).getIdToken();
      this.$store.commit(globalTypes.dispatchers.setTenantInfo, {
        event_id: token.payload.event_id,
        name: token.payload.name,
        family_name: token.payload.family_name,
        email: token.payload.email,
        phone_number: token.payload.phone_number
      });
    },
    async submit(credentials) {
      const { email, password } = credentials;
      this.isLoading = true;
      try {
        await Auth.signIn(email, password);
        this.isAuthenticated = true;
        await this.setupAuthenticatedState();
      } catch (error) {
        console.error('Error signing in:', error);
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'tim-icons icon-bell-55'
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
  watch: {
    getError: function (error) {
      if (error != null) {
        this.$notify({
          type: 'danger',
          message: error.message,
          icon: 'tim-icons icon-bell-55'
        });
      }
    },
    '$route': {
      handler: function () {
        this.routePathName = window.location.pathname;
      },
      immediate: true

    }
  }
};
</script>

<style lang="scss">
// Keeping the existing styles
:root {
  --amplify-primary-color: #ff8d72;
  --amplify-primary-tint: #fd5d93;
  --amplify-primary-shade: #ff8d72;
}

.update-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#notification {
  position: relative;
  width: 280px;
  font-size: 12px;
  padding: 0px 24px 24px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

#notification p {
  margin: 0;
}

#notification a {
  color: #42b983;
}

#notification button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0 5px 2px;
  background: #1da025;
  color: #fff;
  font-size: 13px;
}

#notification button:hover {
  cursor: pointer;
}
</style>
