import { customersTypes } from '../../constants/types/customers';
import Vue from 'vue';

const { setCustomer, setCustomers, setOtherFields } = customersTypes.actions;

export const mutations = {
  [setCustomer]: (state, { response }) => {
    const customer = response.data.value;

    if (customer) {
      Vue.set(state, 'customer', customer);
    }
  },
  [setCustomers]: (state, { response }) => {
    state.count = parseInt(`${response.data.value.count}0` / 12) || 0;
    Vue.set(state, 'customers', []);
    Vue.set(state, 'customers', response.data.value.customers);
  },
  [setOtherFields]: (state, { response }) => {
    const otherFields = response.data.value;
    const defaultFields = state.fields;

    const otherFieldsFormatted = otherFields.map(field => {
      return { label: field, value: field };
    });

    const fields = [...defaultFields, ...otherFieldsFormatted];

    Vue.set(state, 'fields', fields);
  }
};
