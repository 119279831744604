import { actions } from './actions';
import { mutations } from './mutations';
import { getDefaultState } from './state';

export const productAnalytics = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getProduct: state => state.product,

    getAbpp: state => {
      if (state.abpp.length === 0) {
        return parseFloat(0).toFixed(1);
      } else {
        return parseFloat(state.abpp[0].average_basket_price).toFixed(1);
      }
    },

    getTabulateSegmentUsers: state => state.tabulatedSegmentUsers,

    getSegments: state => {
      //Return proper icon for some segment
      //Need improvement
      let data = state.segments;
      for (let segment of data) {
        if (segment.segmentName === 'Woman') {
          segment['icon'] = 'icon-single-02';
        } else if (segment.segmentName === 'Household without children') {
          segment['icon'] = 'icon-satisfied';
        } else if (segment.segmentName === 'CSP++') {
          segment['icon'] = 'icon-coins';
        } else if (segment.segmentName === 'Vins de bordeaux') {
          segment['icon'] = 'icon-basket-simple';
        } else {
          segment['icon'] = 'icon-app';
        }
      }
      return data;
    }
  },
  actions,
  mutations
};
