export const getDefaultState = () => {
  return {
    tenant: null,
    metaParam: {
      type: null,
      value: null,
      role: null
    }
  };
};
