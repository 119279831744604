import { dashboardAnalyticsTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const {
  setBusiness,
  setOutlook,
  setAbp,
  setAbpm,
  setBestStores,
  setReturningCustomers,
  setAvgVisits,
  setDayVisits,
  setCampaigns
} = dashboardAnalyticsTypes.actions;

export const actions = {
  [setDayVisits]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.dayVisits.get(),
      options: {
        mutation: setDayVisits
      }
    });
  },

  [setBusiness]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.business.get(),
      options: {
        mutation: setBusiness
      }
    });
  },
  [setOutlook]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.outlook.get(),
      options: {
        mutation: setOutlook
      }
    });
  },
  [setAbp]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.abp.get(),
      options: {
        mutation: setAbp
      }
    });
  },

  [setAbpm]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.abpm.get(),
      options: {
        mutation: setAbpm
      }
    });
  },

  // [setProfit]: async ({ commit }) => {
  //   return query({
  //     commit,
  //     api: $api.analytics.dashboard.profit.get(),
  //     options: {
  //       mutation: setProfit
  //     }
  //   })
  // },
  [setBestStores]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.bestStores.get(),
      options: {
        mutation: setBestStores
      }
    });
  },
  [setAvgVisits]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.avgVisits.get(),
      options: {
        mutation: setAvgVisits
      }
    });
  },
  [setReturningCustomers]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.returnings.get(),
      options: {
        mutation: setReturningCustomers
      }
    });
  },
  [setCampaigns]: async ({ commit }) => {
    return query({
      commit,
      api: $api.analytics.dashboard.campaigns.get(),
      options: {
        mutation: setCampaigns
      }
    });
  }
  // [setChannels]: async ({ commit }) => {
  //   return query({
  //     commit,
  //     api: $api.analytics.dashboard.channels.get(),
  //     options: {
  //       mutation: setChannels
  //     }
  //   })
  // },
  // [setSales]: async ({ commit }, { period }) => {
  //   const body = {
  //     query: 'get_sales',
  //     period: period
  //   }
  //   return query({
  //     commit,
  //     api: $api.analytics.dashboard.sales.post(body),
  //     options: {
  //       mutation: setSales
  //     }
  //   })
  // }
};
