import Vue from 'vue';
import { dashboardAnalyticsTypes } from '../../constants/types';

const {
  setBusiness,
  setOutlook,
  setAbp,
  setAbpm,
  //setProfit,
  setBestStores,
  setAvgVisits,
  setReturningCustomers,
  setDayVisits,
  setCampaigns
  //setChannels,

  //setSales
} = dashboardAnalyticsTypes.actions;

export const mutations = {
  [setDayVisits]: (state, { response }) => {
    Vue.set(state, 'dayVisits', response.data.value);
  },

  [setBusiness]: (state, { response }) => {
    Vue.set(state, 'business', response.data.value);
  },

  [setOutlook]: (state, { response }) => {
    Vue.set(state, 'outlook', response.data.value);
  },

  [setAbp]: (state, { response }) => {
    Vue.set(state, 'abp', response.data.value);
  },
  [setAbpm]: (state, { response }) => {
    Vue.set(state, 'abpm', response.data.value);
  },
  // [setProfit]: (state, { response }) => {
  //   Vue.set(state, 'profit', response.data.value)
  // },
  [setBestStores]: (state, { response }) => {
    Vue.set(state, 'bestStores', response.data.value);
  },
  [setAvgVisits]: (state, { response }) => {
    Vue.set(state, 'avgVisits', response.data.value);
  },
  [setReturningCustomers]: (state, { response }) => {
    Vue.set(state, 'returnings', response.data.value);
  },
  [setCampaigns]: (state, { response }) => {
    Vue.set(state, 'campaigns', response.data.value);
  }
  // [setChannels]: (state, { response }) => {
  //   Vue.set(state, 'channels', response.data.value)
  // },

  // [setSales]: (state, { response }) => {
  //   Vue.set(state, 'sales', [{}])
  //   Vue.set(state, 'sales', response.data.value)
  // }
};
