import { segmentAnalyticsTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers/query';

const {
  setSegmentQuery,
  setSegmentOverview,
  setAbp,
  setAsv,
  setMostVisitedStores,
  setOtherSegments,
  setDemography,
  setCustomersDayVisits
} = segmentAnalyticsTypes.actions;

const getPayload = (segmentId, segmentQuery) => {
  if (segmentId) {
    return {
      segment_id: parseInt(segmentId)
    };
  } else {
    return {
      segment_query: segmentQuery
    };
  }
};

export const actions = {
  [setSegmentQuery]: ({ commit }, { segmentQuery }) => {
    console.log(segmentQuery);
    commit(setSegmentQuery, { segmentQuery });
  },

  [setCustomersDayVisits]: ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.analytics.segment.customersDayVisits.get(id),
      options: {
        mutation: setCustomersDayVisits
      }
    });
  },

  /**
   * Set the population and target size for the overview card
   * @param {string} segmentId
   * @returns {query}
   */
  [setSegmentOverview]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.overview.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setSegmentOverview
      }
    });
  },

  /**
   * Get the average basket price in a segment
   * @param {string} segmentId
   * @returns {query}
   */
  [setAbp]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.abp.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setAbp
      }
    });
  },

  /**
   * Get the average store visits for a segment
   * @param {string} segmentId
   * @returns {query}
   */
  [setAsv]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.asv.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setAsv
      }
    });
  },

  /**
   * Most visited stores for the past 12 months
   * @param {string} segmentId
   * @returns {query}
   */
  [setMostVisitedStores]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.mostVisitedStores.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setMostVisitedStores
      }
    });
  },

  /**
   * Other segments with customers in it
   * @param {string} segmentId
   * @returns {query}
   */
  [setOtherSegments]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.setOtherSegments.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setSegmentOverview
      }
    });
  },

  /**
   * Demographic data
   * @param {string} segmentId
   * @returns {query}
   */
  [setDemography]: ({ state, commit }, { segmentId = null }) => {
    return query({
      commit,
      api: $api.analytics.segment.demography.post(getPayload(segmentId, state.segmentQuery)),
      options: {
        promise: true,
        mutation: setSegmentOverview
      }
    });
  }
};
