<template>
  <div class="vh-100 vw-100 auth-purple-glow-background"></div>
</template>
<script>
export default {
  name: 'callback-comp',
  async created() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.log('No authenticated user');
    }
  },
  methods: {}
};
</script>
