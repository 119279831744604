export const recommendationTypes = {
  module: 'recommendation',
  getters: {
    // Get all customers
    get getRecommendations() {
      return `${recommendationTypes.module}/getRecommendations`;
    },
    get getBatchTimestamps() {
      return `${recommendationTypes.module}/getBatchTimestamps`;
    },
    get getFbt() {
      return `${recommendationTypes.module}/getFbt`;
    },
    get getTemplates() {
      return `${recommendationTypes.module}/getTemplates`;
    }
  },
  actions: {
    setRecommendations: 'SET_RECOMMENDATIONS',
    rateRecommendation: 'RATE_RECOMMENDATION',
    commentRecommendation: 'COMMENT_RECOMMENDATION',
    getBatchTimeStamps: 'GET_BATCH_TIMESTAMPS',
    setFbt: 'SET_FBT',
    addTemplate: 'ADD_TEMPLATE',
    flushTemplates: 'FLUSH_TEMPLATES'
  },
  dispatchers: {
    get getBatchTimeStamps() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.getBatchTimeStamps}`;
    },
    get setFbt() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.setFbt}`;
    },
    get setRecommendations() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.setRecommendations}`;
    },
    get rateRecommendation() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.rateRecommendation}`;
    },
    get commentRecommendation() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.commentRecommendation}`;
    },
    get addTemplate() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.addTemplate}`;
    },
    get flushTemplates() {
      return `${recommendationTypes.module}/${recommendationTypes.actions.flushTemplates}`;
    }
  }
};
