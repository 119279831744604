import { getDefaultState } from './state';
import { actions } from './actions';
import { mutations } from './mutations';

export const customers = {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    getCustomer: state => state.customer,
    getCustomers: state => state.customers,
    getCount: state => state.count,
    getFields: state => state.fields
  },
  actions,
  mutations
};
