import { marketingAutomationTypes } from '../../constants/types';
import { $api } from '../../services/api';
import { query } from '../../utils/wrappers';

const {
  getAPIMarketingAutomationsDetails,
  createMarketingAutomation,
  deleteMarketingAutomation,
  activateMarketingAutomation
} = marketingAutomationTypes.actions;

export const actions = {
  // get all marketing automations details
  [getAPIMarketingAutomationsDetails]: async ({ commit }) => {
    return query({
      commit,
      api: $api.marketingAutomation.get({ pathParams: {}, queryParams: {} }),
      options: {
        loader: getAPIMarketingAutomationsDetails,
        promise: false,
        mutation: getAPIMarketingAutomationsDetails
      }
    });
  },
  // create a new marketing automation
  [createMarketingAutomation]: async ({ commit }, { title, description }) => {
    return query({
      commit,
      api: $api.marketingAutomation.post({
        title,
        description
      }),
      options: {
        loader: createMarketingAutomation,
        promise: true
      }
    });
  },

  // delete a marketing automation
  [deleteMarketingAutomation]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.marketingAutomation.delete({ pathParams: { id } }),
      options: {
        loader: deleteMarketingAutomation,
        promise: true
      }
    });
  },

  // activate/deactivate a marketing automation
  [activateMarketingAutomation]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.marketingAutomation.put({ pathParams: { id } }),
      options: {
        loader: activateMarketingAutomation,
        promise: true
      }
    });
  }
};
