import Vue from 'vue';
import { productAnalyticsTypes } from '../../constants/types/productAnalytics';
import { getDefaultState } from './state';

const { setTenantProduct, setAbpp, setSegments, flush, setTabulateSegmentUsers } = productAnalyticsTypes.actions;

export const mutations = {
  [flush]: state => {
    Object.assign(state, getDefaultState());
  },
  [setTenantProduct]: (state, { response }) => {
    const productDetails = response.data.value;

    delete productDetails['parent_items'];
    delete productDetails['lineage'];
    delete productDetails['description_metadata'];

    Vue.set(state, 'product', productDetails);
  },
  [setAbpp]: (state, { response }) => {
    if (response.data.value.length === 0) {
      Vue.set(state, 'abpp', []);
    } else {
      Vue.set(state, 'abpp', [response.data.value]);
    }
  },
  [setTabulateSegmentUsers]: (state, { response }) => {
    const tabulatedSegments = response.data.value.map(v => ({
      ...v,
      affinity: parseFloat(v.affinity).toFixed(2)
    }));

    Vue.set(
      state,
      'tabulatedSegmentUsers',
      tabulatedSegments.sort((a, b) => b.affinity - a.affinity)
    );
  },
  [setSegments]: (state, { response }) => {
    Vue.set(state, 'segments', response.data.value);
  }
};
