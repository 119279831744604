import { datasetTypes } from '../../constants/types/dataset';
const {
  getDatasets,
  getDatasetMapping,
  createDataset,
  flushDataset,
  getDatasetGroup,
  createDatasetGroup,
  clearNewDatasetGroup
} = datasetTypes.actions;

import Vue from 'vue';
import { getDefaultState } from './state';

export const mutations = {
  [getDatasets]: (state, { response }) => {
    const datasets = response.data.value;

    if (datasets) {
      Vue.set(state, 'datasets', datasets);
    }
  },
  [getDatasetMapping]: (state, { response }) => {
    const mapping = response.data.value;

    if (mapping) {
      Vue.set(state, 'mapping', mapping);
    }
  },
  [createDataset]: (state, { response }) => {
    const newDataset = response.data.value;

    if (newDataset) {
      Vue.set(state, 'newDataset', newDataset);
    }
  },
  [clearNewDatasetGroup]: state => {
    Vue.set(state, 'newDatasetGroup', null);
  },
  [flushDataset]: state => {
    Object.assign(state, getDefaultState());
  },
  [getDatasetGroup]: (state, { response }) => {
    const datasetGroup = response.data.value;

    if (datasetGroup) {
      Vue.set(state, 'datasetGroup', datasetGroup);
    }
  },
  [createDatasetGroup]: (state, { response }) => {
    const newDatasetGroup = response.data.value;

    if (newDatasetGroup) {
      Vue.set(state, 'newDatasetGroup', newDatasetGroup);
    }
  }
};
