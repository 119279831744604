export const getDefaultState = () => {
  return {
    batches: [],
    recommendationId: null,
    coverage: null,
    bestSellers: [],
    topRecommended: [],
    recommendationsCategories: {
      categories: [],
      count: []
    }
  };
};
