import { $api } from '../../services/api';
import { templateTypes } from '../../constants/types';
import { query } from '../../utils/wrappers/query';

const { saveTemplate, setTemplates, setTemplate, setImage, deleteTemplate, setMergeTags, duplicateTemplate } =
  templateTypes.actions;

export const actions = {
  [setMergeTags]: async ({ commit }) => {
    return query({
      commit,
      api: $api.template.get('/attributes'),
      options: {
        promise: true,
        mutation: setImage
      }
    });
  },

  [deleteTemplate]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.template.delete(`/${id}`),
      options: {
        promise: true,
        mutation: setImage
      }
    });
  },

  [setImage]: async ({ commit }, { image, templateId }) => {
    return query({
      commit,
      api: $api.template.upload('upload', {
        image: image,
        templateId
      }),
      options: {
        promise: true,
        mutation: setImage
      }
    });
  },

  [setTemplate]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.template.get('', {
        pathParams: {
          id
        }
      }),
      options: {
        promise: true,
        mutation: setTemplates
      }
    });
  },

  [setTemplates]: async ({ commit }) => {
    return query({
      commit,
      api: $api.template.get(),
      options: {
        promise: true,
        mutation: setTemplates
      }
    });
  },

  [saveTemplate]: async (
    { commit },
    { jsonBody, htmlBody, channel, templateName, templateId, markAsDefault, edit = false }
  ) => {
    return query({
      commit,
      api: $api.template.post({
        jsonBody,
        htmlBody,
        channel,
        templateName,
        templateId,
        markAsDefault,
        edit
      }),
      options: {
        promise: true,
        mutation: saveTemplate
      }
    });
  },

  [duplicateTemplate]: async ({ commit }, { id }) => {
    return query({
      commit,
      api: $api.template.duplicate('duplicate', {
        id
      }),
      options: {
        promise: true,
        mutation: duplicateTemplate
      }
    });
  }
};
