<template>
  <div class="loading-text-center">
    <scale-loader :loading="true" :color="'#ff8678'" :height="'70px'" :width="'5px'"></scale-loader>
    <h3>{{ loadingMessage }}</h3>
  </div>
</template>

<script>
import { databaseChoresTypes } from '../../constants/types';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import swal from 'sweetalert2';
import { Auth } from 'aws-amplify';

export default {
  name: 'LoadingScreen',
  components: {
    ScaleLoader
  },
  data() {
    return {
      loadingMessage: 'Checking authentication...'
    };
  },
  methods: {
    async checkAuthAndTenant() {
      try {
        const authenticated = await Auth.currentAuthenticatedUser();
        if (!authenticated) {
          this.$router.push('/login');
          return;
        }

        const tenantId = authenticated.attributes['custom:tenant_ids'];
        const onboardingStatus = authenticated.attributes['custom:onboarding_status'];

        this.loadingMessage = 'Checking tenant...';
        if (!tenantId) {
          this.$router.push('/login');
          return;
        }

        if (!onboardingStatus || onboardingStatus < 6) {
          this.$router.push('/onboarding');
          return;
        }

        if (!sessionStorage.getItem('tenantId')) {
          this.$router.push('/tenant-selection');
          return;
        }

        await this.pingServerDatabase();
        return;
      } catch (error) {
        console.error('Error in authentication check:', error);
        this.$router.push('/login');
      }
    },
    async pingServerDatabase() {
      this.loadingMessage = 'Pinging database...';
      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: { value: 0 } });
        }, 10000);
      });

      try {
        const response = await Promise.race([
          this.$store.dispatch(databaseChoresTypes.dispatchers.ping, {}),
          timeoutPromise
        ]);

        if (response.data.value === 1) {
          console.log('Received a response from Customers API');
          this.$router.push('/dashboard');
        } else {
          throw new Error('No response from Customers API.');
        }
      } catch (error) {
        await swal.fire({
          title: 'Error!',
          text: 'Database is not available, please contact support',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        });

        sessionStorage.removeItem('tenantId');
        await Auth.signOut();
        await this.$router.push('/login');
        window.location.reload();
      }
    }
  },
  async created() {
    await this.checkAuthAndTenant();
  }
};
</script>

<style>
.loading-text-center {
  margin-top: 35vh;
  text-align: center;
}

.loading-text-center h3 {
  margin-bottom: 0;
}
</style>
